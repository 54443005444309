import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const IntlRoomPair = ({ record, onClose, onUpdate }) => {
  const [idValue, setIdValue] = useState('')
  const [selectedSaint, setSelectedSaint] = useState('')
  const [saints, setSaints] = useState([])
  const [addInInput, setAddInInput] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (record && record.completeRoom !== 1) {
      fetchSaints()
    }
  }, [record])

  if (!record) return null
  const isPaired = record.completeRoom === 1

  const fetchSaints = async () => {
    try {
      const response = await axios.post(
        '/api/v1/intl/pairing-info',
        { userId: record._id },
        { withCredentials: true }
      )
      setSaints(response.data.data)
    } catch (error) {
      setSaints([])
    }
  }

  const handleInputChange = (e) => {
    setIdValue(e.target.value)
    if (e.target.value) {
      setSelectedSaint('')
    }
  }

  const handleSaintSelection = (e) => {
    setSelectedSaint(e.target.value)
    if (e.target.value) {
      setIdValue('')
    }
  }

  const clearSelection = () => {
    setSelectedSaint('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isPaired) {
      toast.warn('User is already paired!')
      return
    }

    if (!idValue && !selectedSaint) {
      toast.error('Please fill in the roommate info.')
      return
    }
    setLoading(true)
    try {
      const response = await axios.post(
        '/api/v1/intl/pairing-update',
        {
          recordId: record._id,
          id: idValue || selectedSaint,
          addInInput: addInInput,
        },
        { withCredentials: true }
      )

      if (response.data.success) {
        toast.success('Pairing updated successfully')
        setIdValue('')
        setSelectedSaint('')
        setAddInInput(1)
        setSaints([])

        onClose()
        const updatedRecord = response.data.updatedRecord || {}
        const updatedPairedRecord = response.data.updatedPairedRecord || null

        onUpdate(updatedRecord, updatedPairedRecord)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Failed to update pairing')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setIdValue('')
    setSelectedSaint('')
    setAddInInput(1)
    setSaints([])
    onClose()
  }

  return (
    <div className='modal-content p-1'>
      <h5 className='text-center mb-2'>Room Pairing</h5>
      <div className='mb-2' style={{ fontWeight: 600, fontSize: '16px' }}>
        Name: {record.name}
      </div>

      <form onSubmit={handleSubmit}>
        <div className='mb-3 position-relative'>
          <label className='form-label'>
            Select Roommate (from Same Locality)
          </label>
          <div className='position-relative'>
            <select
              className='form-control'
              value={selectedSaint}
              onChange={handleSaintSelection}
              disabled={isPaired || !!idValue}
              onFocus={() => setAddInInput(1)}
            >
              <option value=''>Choose</option>
              {saints.length > 0 ? (
                saints.map((saint) => (
                  <option key={saint._id} value={saint._id}>
                    {saint.name.toUpperCase()}
                  </option>
                ))
              ) : (
                <option disabled>No Records</option>
              )}
            </select>
            {selectedSaint && (
              <button
                type='button'
                className='clear-btn'
                onClick={clearSelection}
              >
                ✕
              </button>
            )}
          </div>
        </div>

        <div className='mb-3 position-relative'>
          <label className='form-label'>
            Or, Pairing Code for Roommate (from Different Locality)
          </label>
          <input
            type='text'
            className='form-control'
            value={idValue}
            onChange={handleInputChange}
            disabled={isPaired || !!selectedSaint}
            onFocus={() => setAddInInput(2)}
          />
        </div>

        <div className='d-flex justify-content-end mt-3'>
          <button
            type='submit'
            className='btn btn-primary btn-sm me-4'
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Submit'}
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </form>

      <style>{`
        .clear-btn {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          color: #999;
          font-size: 16px;
          cursor: pointer;
        }
        .clear-btn:hover {
          color: #333;
        }
      `}</style>
    </div>
  )
}

export default IntlRoomPair
