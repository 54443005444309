import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import ChurchDashboard from '../../pages/Church/Dashboard'
import UserDashboard from '../../pages/User/Dashboard'
import IntlRegistrationList from '../../pages/Intl/List'
import IntlIndividualRegister from './../../pages/Intl/Individual/Register'

export default function PrivateRoute() {
  const [auth] = useAuth()

  if (!auth.user) return <Navigate to='/login' replace />

  switch (auth.access) {
    case 'user':
    case 'admin':
      return <UserDashboard />
    case 'church':
      return <ChurchDashboard />
    case 'intl':
      if (auth.user.command === 'individual') {
        return <IntlIndividualRegister />
      } else {
        return <IntlRegistrationList />
      }
    default:
      return <Navigate to='/login' replace />
  }
}
