import React from 'react'
import { useAuth } from '../../context/auth'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'

import AdminHeader from './Admin/Header'
import AdminMenuBar from './Admin/MenuBar'
import UserHeader from './User/Header'
import UserMenuBar from './User/MenuBar'
import ChurchHeader from './Church/Header'
import ChurchMenuBar from './Church/MenuBar'
import IntlHeader from './Intl/Header'
import IntlMenuBar from './Intl/MenuBar'
import Footer from './Footer'

import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

const Layout = ({ children, title, description, author }) => {
  const [auth] = useAuth()
  const location = useLocation()

  if (!auth.isRehydrated) {
    return null
  }

  const renderHeader = () => {
    if (!auth?.user || !auth?.access) {
      return null
    }
    const path = location.pathname
    switch (auth.access) {
      case 'user':
        return (
          <>
            <UserHeader />
            <UserMenuBar />
          </>
        )
      case 'church':
        return (
          <>
            <ChurchHeader />
            <ChurchMenuBar />
          </>
        )
      case 'intl':
        return (
          <>
            <IntlHeader />
            <IntlMenuBar />
          </>
        )
      case 'admin':
        if (path.startsWith('/admin')) {
          return (
            <>
              <AdminHeader />
              <AdminMenuBar />
            </>
          )
        } else {
          return (
            <>
              <UserHeader />
              <UserMenuBar />
            </>
          )
        }
      default:
        return null
    }
  }

  return (
    <div className='d-flex flex-column min-vh-100'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='author' content={author} />
        <title>{title}</title>
      </Helmet>

      {renderHeader()}

      {/* Main Content - Pushes Footer Down */}
      <div className='flex-grow-1'>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme='light'
        />
        {children}
      </div>

      {auth.access !== 'intl' && <Footer />}
    </div>
  )
}

export default Layout
