import React, { useEffect, useState, useRef, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'

const AdminUserList = () => {
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')

  const observer = useRef()

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true)
        const response = await axios.get('/api/v1/admin/user/list', {
          params: { page, limit: 30 },
        })
        setUsers((prevUsers) => [...prevUsers, ...response.data.users])
        setHasMore(response.data.hasMore)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching users:', error)
        setLoading(false)
      }
    }

    fetchUsers()
  }, [page])

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    setUsers([])
    setHasMore(true)
  }

  return (
    <Layout title={'Personal Account'}>
      <div className='py-3 px-2'>
        <div className='search-bar' style={{ display: 'flex', width: '50%' }}>
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Name / Email / Phone'
            onChange={(e) => setSearchData(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Name</th>
                <th scope='col'>Email/Phone</th>
                <th scope='col'>Locality</th>
                <th scope='col'>Last Login</th>
                <th scope='col'>Register Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user._id}
                  ref={users.length === index + 1 ? lastElementRef : null}
                >
                  <td>
                    <span
                      className='fw-bold'
                      style={{
                        color: user.gender === 1 ? '#2f4483' : '#78003c',
                      }}
                    >
                      {user.name.toUpperCase()}
                    </span>
                    {user.chiName && <div>{user.chiName}</div>}
                  </td>
                  <td>
                    <div>{user.email}</div> <div>{user.phoneNo}</div>
                  </td>
                  <td>{user.locality.name}</td>
                  <td>
                    {user.todayLogin
                      ? new Date(user.todayLogin)
                          .toISOString()
                          .slice(0, 19)
                          .replace('T', ' ')
                      : 'N/A'}
                  </td>
                  <td>
                    {user.registerDate
                      ? new Date(user.registerDate)
                          .toISOString()
                          .slice(0, 19)
                          .replace('T', ' ')
                      : 'N/A'}
                  </td>
                  <td className='text-end'>
                    <i className='bi bi-pencil-square'></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>{loading && <p>Loading...</p>}</div>
      </div>
    </Layout>
  )
}

export default AdminUserList
