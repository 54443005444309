import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import ContactUs from '../../../pages/General/ContactUs'
import useIsMobile from '../../../hooks/useIsMobile'

const UserMenuBar = ({ t }) => {
  const isMobile = useIsMobile()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <>
      {isMobile ? (
        <nav
          className='navbar navbar-light shadow px-3 py-1'
          style={{ backgroundColor: '#7a6844' }}
        >
          <div className='d-flex justify-content-center align-items-center w-100'>
            <Link className='nav-link text-white mx-3' to='/'>
              <i className='bi bi-house-fill' style={{ fontSize: '20px' }}></i>
            </Link>
            <Link className='nav-link text-white mx-3' to='/personal/account'>
              My Account
            </Link>
            <span
              className='nav-link text-white mx-3'
              style={{ cursor: 'pointer' }}
              onClick={showModal}
            >
              <i
                className='bi bi-envelope-fill'
                style={{ fontSize: '20px' }}
              ></i>
            </span>
          </div>
        </nav>
      ) : (
        <nav
          className='navbar navbar-expand-lg navbar-light shadow px-3 py-0'
          style={{ backgroundColor: '#7a6844' }}
        >
          <ul
            className='navbar-nav mx-auto'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <li className='nav-item me-3'>
              <Link className='nav-link text-white' to='/'>
                Homepage
              </Link>
            </li>

            <li className='nav-item me-3'>
              <Link className='nav-link text-white' to='/personal/account'>
                My Account
              </Link>
            </li>

            <li className='nav-item me-3'>
              <span
                className='nav-link'
                style={{ cursor: 'pointer', color: '#fff' }}
                onClick={showModal}
              >
                Contact Us
              </span>
            </li>
          </ul>
        </nav>
      )}
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <ContactUs onCancel={handleCancel} />
      </Modal>
    </>
  )
}

export default UserMenuBar
