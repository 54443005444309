import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { Tooltip } from 'antd'

import axios from 'axios'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const AdminPaymentEventRecords = () => {
  const { id } = useParams()
  const [eventName, setEventName] = useState('')
  const [records, setRecords] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchEventName = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/admin/registration/event/${id}`
        )
        setEventName(data.eventName)
      } catch (error) {
        console.error('Error fetching event name:', error)
      }
    }

    const fetchPayments = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `/api/v1/admin/payment/event/${id}?page=${currentPage}`
        )
        setRecords(data.payments)
        setTotalPages(data.totalPages || 1)
      } catch (error) {
        console.error('Error fetching payment records:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      fetchEventName()
      fetchPayments()
    }
  }, [id, currentPage])

  const handleConfirm = async (paymentId) => {
    const confirmAction = window.confirm('Confirm this payment?')
    if (!confirmAction) return

    try {
      await axios.put(`/api/v1/admin/payment/confirm/${paymentId}`)
      alert('Payment confirmed successfully.')
    } catch (err) {
      console.error('Error confirming payment:', err)
      alert('Failed to confirm payment.')
    }
  }

  return (
    <Layout title={`Event Transport Records - ${eventName || 'Loading...'}`}>
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <Link to={`/admin/registration/data/${id}`}>
            <button type='button' className='btn btn-secondary btn-sm'>
              <i className='bi bi-arrow-left-circle'></i> Return
            </button>
          </Link>

          <h5 className='fw-bold text-center flex-grow-1 m-0'>
            {eventName ? eventName : <LoadingSpinner />}
          </h5>
        </div>
        <hr />
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Locality</th>
                <th>Payment Method</th>
                <th className='text-end'>Amount</th>
                <th className='text-center'>Paid For</th>
                <th>Status</th>
                <th className='text-end'>Upload Date</th>
              </tr>
            </thead>
            <tbody>
              {records.length === 0 ? (
                <tr>
                  <td colSpan='6' className='text-center'>
                    {loading
                      ? 'Loading records...'
                      : 'No payment records found.'}
                  </td>
                </tr>
              ) : (
                records.map((record, index) => (
                  <tr key={record._id || index}>
                    <td>{record.locality?.name}</td>
                    <td>
                      {record.paymentMethod === 1
                        ? 'Bank Transfer'
                        : record.paymentMethod === 2
                        ? 'Credit/Debit Card'
                        : record.paymentMethod === 3
                        ? 'QR Code'
                        : ''}

                      {record.paySlipSystem && (
                        <>
                          <a
                            href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/${record.eventId}/${record.paySlipSystem}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none', color: '#0d6efd' }}
                            className='ms-2'
                          >
                            <i className='bi bi-file-earmark-pdf'></i>
                          </a>
                        </>
                      )}
                    </td>
                    <td className='text-end'>
                      {record.amount != null
                        ? parseFloat(record.amount).toFixed(2)
                        : ''}
                    </td>
                    <td></td>
                    <td>
                      <Tooltip
                        title={
                          record.paymentStatus === 1
                            ? 'Paid & Confirmed'
                            : record.paymentStatus === 3
                            ? 'Processing'
                            : ''
                        }
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            backgroundColor:
                              record.paymentStatus === 1
                                ? '#1e7e34'
                                : record.paymentStatus === 3
                                ? '#ba8b00'
                                : 'transparent',
                            marginRight: '8px',
                            cursor: 'pointer',
                          }}
                        ></span>
                      </Tooltip>
                      {record.paymentStatus === 3 && (
                        <button
                          className='btn btn-sm btn-success ms-2'
                          onClick={() => handleConfirm(record._id)}
                        >
                          Confirm
                        </button>
                      )}
                    </td>
                    <td className='text-end'>
                      {(() => {
                        const d = new Date(record.createdAt)
                        const pad = (n) => n.toString().padStart(2, '0')
                        return `${pad(d.getUTCDate())}/${pad(
                          d.getUTCMonth() + 1
                        )}/${d.getUTCFullYear()} ${pad(d.getUTCHours())}:${pad(
                          d.getUTCMinutes()
                        )}:${pad(d.getUTCSeconds())}`
                      })()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </Layout>
  )
}

export default AdminPaymentEventRecords
