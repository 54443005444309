import React from 'react'

const PaginationButtons = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null

  const maxButtons = 10
  let startPage = Math.max(1, currentPage - 4)
  let endPage = Math.min(totalPages, startPage + maxButtons - 1)

  if (endPage - startPage < maxButtons - 1) {
    startPage = Math.max(1, endPage - maxButtons + 1)
  }

  const pageNumbers = []
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  return (
    <div className='d-flex justify-content-center mt-3 mb-5'>
      <button
        className='btn btn-outline-primary btn-sm me-2'
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <i className='bi bi-chevron-left'></i> Prev
      </button>

      {startPage > 1 && (
        <>
          <button
            className='btn btn-sm btn-outline-primary mx-1'
            onClick={() => onPageChange(1)}
          >
            1
          </button>
          {startPage > 2 && <span className='mx-1'>...</span>}
        </>
      )}

      {pageNumbers.map((pageNum) => (
        <button
          key={pageNum}
          className={`btn btn-sm mx-1 ${
            currentPage === pageNum ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => onPageChange(pageNum)}
        >
          {pageNum}
        </button>
      ))}

      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span className='mx-1'>...</span>}
          <button
            className='btn btn-sm btn-outline-primary mx-1'
            onClick={() => onPageChange(totalPages)}
          >
            {totalPages}
          </button>
        </>
      )}

      <button
        className='btn btn-outline-primary btn-sm ms-2'
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next <i className='bi bi-chevron-right'></i>
      </button>
    </div>
  )
}

export default PaginationButtons
