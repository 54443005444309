import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import IntlRegistrationStayForm from './StayForm'
import { Modal, Input } from 'antd'

const IntlRegistrationFormIndex = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [numParticipants, setNumParticipants] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [error, setError] = useState('')

  const validateInput = (value) => {
    const numValue = parseInt(value, 10)

    if (!value || isNaN(numValue) || numValue < 1) {
      setError('Required')
    } else if (numValue > 8) {
      setError('Maximum number of participants to add is 8')
    } else {
      setError('')
    }

    setNumParticipants(value)
  }

  const handleConfirm = () => {
    if (!error) {
      setIsModalOpen(false)
      setShowForm(true)
    }
  }

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-2'>
        <div
          className='fw-bold mb-3 text-uppercase'
          style={{
            color: '#5d3104',
            textAlign: 'center',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Lodging & Meal Form
        </div>

        <div className='mb-1' style={{ fontWeight: 600 }}>
          <span className='require'>*</span> are required to be filled in.
        </div>
        {showForm && (
          <IntlRegistrationStayForm
            numParticipants={parseInt(numParticipants, 10)}
          />
        )}

        <Modal
          title='Enter Number of Participants'
          open={isModalOpen}
          closable={false}
          footer={[
            <button
              className='btn btn-primary btn-sm me-3'
              onClick={handleConfirm}
              key='confirm'
              disabled={!!error || !numParticipants}
            >
              Confirm
            </button>,
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => {
                setIsModalOpen(false)
                navigate('/')
              }}
              key='cancel'
            >
              Cancel
            </button>,
          ]}
        >
          <Input
            type='number'
            min='1'
            className={`form-control ${error ? 'is-invalid' : ''}`}
            value={numParticipants}
            onChange={(e) => validateInput(e.target.value)}
          />
          {error && <div className='text-danger mt-2'>{error}</div>}
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlRegistrationFormIndex
