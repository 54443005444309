import React from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'

const AdminMigrate = () => {
  const handleMigrateData = async () => {
    console.log('test')
    try {
      const response = await axios.post('/api/v1/admin/db/migrate')
      console.log(response.data.message)
      alert('Data migrated successfully!')
    } catch (error) {
      console.error('Error migrating data:', error)
      alert('Error migrating data')
    }
  }

  const handleUpdateLocality = async () => {
    try {
      const response = await axios.post('/api/v1/admin/db/update-locality')
      if (response.status === 200) {
        alert('User locality successfully migrated!')
      } else {
        alert(`Migration failed: ${response.data.message}`)
      }
    } catch (error) {
      console.error('Error migrating data:', error)
      alert('Error occurred during migration.')
    }
  }

  const handleEventPackage = async () => {
    try {
      const response = await axios.post('/api/v1/admin/db/update-packages')
      console.log(response.data.message)
      alert('Data migrated successfully!')
    } catch (error) {
      console.error('Error migrating data:', error)
      alert('Error migrating data')
    }
  }

  return (
    <Layout title={'Admin Dashboard'}>
      {/* Temporary to import db */}
      <ol>
        <li className='mb-3'>
          <button onClick={handleMigrateData}>Migrate data</button>
        </li>
        <li className='mb-3'>
          <button onClick={handleUpdateLocality}>Update user locality</button>
        </li>
        <li className='mb-3'>
          <button onClick={handleEventPackage}>
            Update event package type
          </button>
        </li>
      </ol>
    </Layout>
  )
}

export default AdminMigrate
