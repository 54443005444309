import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spin } from 'antd'

const AdminOnlineLocalityStat = ({ eventId, onClose }) => {
  const [localityStats, setLocalityStats] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchLocalityStats = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/online/registration/locality/${eventId}`
        )
        setLocalityStats(response.data)
      } catch (error) {
        console.error('Error fetching locality stats:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchLocalityStats()
  }, [eventId])

  return (
    <div>
      <h5 className='fw-bold mb-3 text-center'>Locality Stats</h5>

      {loading ? (
        <div className='text-center'>
          <Spin size='large' />
          <p>Loading...</p>
        </div>
      ) : localityStats.length > 0 ? (
        <div className='table-responsive'>
          <table className='table'>
            <thead className='table-light'>
              <tr>
                <th>Locality Name</th>
                <th className='text-center'>Total Participants</th>
              </tr>
            </thead>
            <tbody>
              {localityStats.map((locality, index) => (
                <tr key={index}>
                  <td>{locality._id || '-'}</td>
                  <td className='text-center'>{locality.totalRegistrations}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='text-center'>No data.</div>
      )}

      <div className='text-end mt-1'>
        <button className='btn btn-secondary btn-sm' onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default AdminOnlineLocalityStat
