import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='bg-light text-center text-lg-start py-2 border-top'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 text-md-start'>
            <ul className='list-unstyled d-flex justify-content-center justify-content-md-start gap-3 mb-0'>
              <li>
                <Link to='/terms' className='text-decoration-none text-dark'>
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          <div className='col-md-6 text-md-end text-center'>
            <p className='mb-0'>
              &copy; {new Date().getFullYear()} Event Registration System. All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
