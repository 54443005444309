import React, { useEffect, useState } from 'react'
import axios from 'axios'
import TitleModal from '../../components/shared/TitleModal'
import LoadingSpinner from './../../components/shared/LoadingSpinner'

const AnnouncementList = ({ handleClose }) => {
  const [announcements, setAnnouncements] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get('/api/v1/general/announce/list')
        setAnnouncements(response.data)
      } catch (error) {
        console.error('Error fetching announcements:', error)
        alert('Failed to load announcements. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchAnnouncements()
  }, [])

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  return (
    <div>
      <TitleModal title='Announcement' />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ol
          style={{
            textTransform: 'capitalize',
            paddingLeft: '10px',
          }}
        >
          {announcements.map((announce) => (
            <li
              key={announce._id}
              style={{
                marginBottom: '10px',
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: '17px',
                    fontWeight: 500,
                    color: '#340d0d',
                  }}
                >
                  {capitalizeFirstLetter(announce.announceTitleEn)}
                </span>

                {announce.files && announce.files.length > 0 && (
                  <div className='mt-1' style={{ fontSize: '16px' }}>
                    {announce.files
                      .map((file, fileIndex) => (
                        <a
                          key={fileIndex}
                          href={`${process.env.REACT_APP_BACKEND_URL}/files/announcement/${announce._id}/${file.fileName}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: '#005fc4' }}
                        >
                          <i className='bi bi-file-arrow-down'></i> Download
                        </a>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ol>
      )}

      <div
        style={{
          textAlign: 'right',
        }}
      >
        <button className='close-btn' onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default AnnouncementList
