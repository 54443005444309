import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminEditAnnounce = ({
  announcement,
  closeLogModal,
  onUpdateAnnouncement,
}) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [files, setFiles] = useState([])

  const initializeForm = () => {
    form.resetFields()
    form.setFieldsValue({
      announceTitleEn: announcement?.announceTitleEn || '',
      announceTitleZh: announcement?.announceTitleZh || '',
      announceTitleBm: announcement?.announceTitleBm || '',
      announceDate: announcement?.announceDate
        ? new Date(announcement.announceDate).toISOString().split('T')[0]
        : '',
      files: announcement?.files || [],
    })
    setFiles(announcement?.files || [])
  }

  useEffect(() => {
    if (announcement) {
      initializeForm()
    }
  }, [announcement])

  const handleDeleteFile = async (fileName) => {
    try {
      const response = await axios.post('/api/v1/admin/announce/delete-file', {
        announcementId: announcement._id,
        fileName,
      })

      if (response.data.success) {
        toast.success('File deleted successfully!')
        const updatedFiles = files.filter((file) => file.fileName !== fileName)
        setFiles(updatedFiles)
        form.setFieldsValue({
          files: updatedFiles,
        })

        onUpdateAnnouncement({
          ...announcement,
          files: updatedFiles,
        })
      }
    } catch (error) {
      console.error('Error deleting file:', error)
      toast.error('Failed to delete the file.')
    }
  }

  const handleSubmit = async (values) => {
    const formPayload = new FormData()
    formPayload.append('announceTitleEn', values.announceTitleEn)
    formPayload.append('announceTitleZh', values.announceTitleZh)
    formPayload.append('announceTitleBm', values.announceTitleBm)
    formPayload.append('announceDate', values.announceDate)

    const updatedFiles = form.getFieldValue('files') || []
    updatedFiles.forEach((file) => {
      if (file.originFileObj) {
        formPayload.append('files', file.originFileObj)
      }
    })

    try {
      const response = await axios.put(
        `/api/v1/admin/announce/${announcement._id}`,
        formPayload,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      if (response.data.success) {
        toast.success('Updated successfully!')
        onUpdateAnnouncement(response.data.updatedAnnouncement)
        closeLogModal()
      }
    } catch (error) {
      console.error('Error updating announcement:', error)
      toast.error('Failed to update the announcement.')
    }
  }

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      <Form.Item
        label='Title (English)'
        name='announceTitleEn'
        rules={[{ required: true, message: 'English title Required!!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Title (Chinese)'
        name='announceTitleZh'
        rules={[{ required: true, message: 'Chinese title Required!!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Title (BM)'
        name='announceTitleBm'
        rules={[{ required: true, message: 'BM title Required!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Date'
        name='announceDate'
        rules={[{ required: true, message: 'Please select the date!' }]}
      >
        <Input type='date' />
      </Form.Item>

      <Form.Item name='files' label='Files'>
        <div>
          {files.map((file) => (
            <div key={file.fileName} className='d-flex align-items-center'>
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}/announcement/${announcement._id}/${file.fileName}`}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'none' }}
              >
                {file.fileName}
              </a>
              <Button
                type='link'
                danger
                onClick={() => handleDeleteFile(file.fileName)}
              >
                <i
                  className='bi bi-trash3'
                  style={{ color: 'red', cursor: 'pointer' }}
                ></i>
              </Button>
            </div>
          ))}
          <Upload
            name='files'
            multiple
            beforeUpload={() => false}
            fileList={
              form.getFieldValue('files')?.filter((file) => file.name) || []
            }
            onChange={(info) => {
              const updatedFiles = info.fileList.filter((file) => file.name)
              setFiles(updatedFiles)
              form.setFieldsValue({ files: updatedFiles })
            }}
          >
            <Button className='mt-2' icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </div>
      </Form.Item>

      <Form.Item className='mb-0'>
        <div className='d-flex justify-content-end'>
          <Button
            type='primary'
            className='me-3'
            htmlType='submit'
            loading={isSubmitting}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              form.resetFields()
              closeLogModal()
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default AdminEditAnnounce
