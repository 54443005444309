import React, { useState, useEffect } from 'react'
import * as Sentry from "@sentry/browser";
import { useAuth } from '../../context/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { Modal } from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ContactUs from '../General/ContactUs'
import IteroLoginGuide from '../General/IteroLoginGuide'
import { securePost } from '../../utils/securityUtils'

const IntlLogin = () => {
  const [data, setData] = useState('')
  const [locality, setLocality] = useState('')
  const [auth, setAuth] = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isGuideVisible, setIsGuideVisible] = useState(false)
  const [activeTab, setActiveTab] = useState('individual')
  const [pin, setPin] = useState('')
  const [showPinField, setShowPinField] = useState(false)
  const [password, setPassword] = useState('')
  const [showPasswordField, setShowPasswordField] = useState(false)

  const [infoMsg, setInfoMsg] = useState('')
  const [errors, setErrors] = useState({})
  const [errorMsg, setErrorMsg] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (auth?.user) {
      navigate('/')
    }
    if (location.state?.loggedOut) {
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [auth, location, navigate])

  useEffect(() => {
    setData('')
    setLocality('')
    setErrors({})
    setErrorMsg('')
    setShowPinField(false)
    setShowPasswordField(false)
    setInfoMsg('')
    setPin('')
    setPassword('')
  }, [activeTab])

  useEffect(() => {
    setShowPinField(false)
    setPin('')
    setInfoMsg('')
    setErrorMsg('')
    setErrors((prevErrors) => {
      const { pin, ...rest } = prevErrors
      return rest
    })
  }, [data])

  const validateGroupForm = () => {
    let newErrors = {}
    if (!locality.trim()) {
      newErrors.locality = 'Locality is required'
    }
    if (showPasswordField) {
      if (!password.trim()) {
        newErrors.password = 'Password is required'
      } else if (password.length < 6) {
        newErrors.password = 'Password must be at least 6 characters'
      }
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleIndividualSubmit = async (e) => {
    e.preventDefault()
    if (!data.trim()) {
      setErrors({ data: 'LSM Attendee ID is required' })
      return
    }
    if (showPinField && (!pin.trim() || pin.length !== 6)) {
      setErrors({ pin: 'Last 6 digits of phone number are required' })
      return
    }
    setIsLoading(true)
    setErrorMsg('')
    setErrors({})
    try {
      const res = await securePost(
        '/api/v1/auth/intl-login',
        {
          data,
          command: 'individual',
          ...(showPinField ? { pin } : {}),
        },
        { withCredentials: true }
      )
      const responseData = res.data
      if (responseData.success) {
        setAuth({
          user: responseData.user,
          access: responseData.access,
          isRehydrated: true,
        })
        navigate('/')
      } else if (responseData.requirePin) {
        setShowPinField(true)
        setInfoMsg('Please enter the last 6 digits of your phone number.')
      } else {
        Sentry.captureEvent({
          message: responseData.message,
          level: 'info',
          tags: {
            page: 'IntlLogin',
            command: 'individual',
            pin: showPinField ? pin : undefined,
          },
        })
        setErrorMsg(responseData.message || 'Failed to login')
      }
    } catch (error) {
      Sentry.captureEvent({
        message: error.message,
        level: 'info',
        tags: {
          page: 'IntlLogin',
          command: 'individual',
          pin: showPinField ? pin : undefined,
        },
      })
      setErrorMsg(error.response?.data.message || 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  const handleGroupSubmit = async (e) => {
    e.preventDefault()
    if (!validateGroupForm()) {
      return
    }
    setIsLoading(true)
    setErrorMsg('')
    try {
      const res = await securePost(
        '/api/v1/auth/intl-login',
        {
          data: locality,
          command: 'point',
          ...(showPasswordField ? { password } : {}),
        },
        { withCredentials: true }
      )
      const responseData = res.data
      if (responseData.success) {
        setAuth({
          user: responseData.user,
          access: responseData.access,
          isRehydrated: true,
        })
        navigate('/')
      } else if (responseData.requirePassword) {
        setShowPasswordField(true)
        if (responseData.firstTime) {
          setInfoMsg('Please set a password (minimum 6 characters).')
        } else {
          setInfoMsg('')
        }
      }
    } catch (error) {
      Sentry.captureEvent({
        message: error.message,
        level: 'info',
        tags: {
          page: 'IntlLogin',
          command: 'point',
          password: showPasswordField ? password : undefined,
        },
      })
      setErrorMsg(error.response?.data.message || 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const TabHeader = ({ label, value }) => (
    <div
      onClick={() => setActiveTab(value)}
      style={{
        flex: 1,
        textAlign: 'center',
        padding: '12px 0',
        fontWeight: 600,
        cursor: 'pointer',
        backgroundColor: activeTab === value ? '#f5f3ee' : 'transparent',
        borderBottom: activeTab === value ? '3px solid #937d52' : 'none',
        color: '#625337',
      }}
    >
      {label}
    </div>
  )

  return (
    <div>
      <ToastContainer position='top-center' autoClose={1000} hideProgressBar />
      <Navbar collapseOnSelect expand='lg' variant='dark' className='px-3 mt-2'>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='ms-auto d-flex align-items-center gap-3'>
            <span
              style={{ cursor: 'pointer', color: '#003b7b', fontWeight: '600' }}
              onClick={() => setIsGuideVisible(true)}
            >
              <i className='bi bi-info-circle-fill'></i> Login Guidelines
            </span>
            <span
              style={{ cursor: 'pointer', color: '#003b7b', fontWeight: '600' }}
              onClick={showModal}
            >
              <i className='bi bi-envelope-fill'></i> Contact Us
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className='text-center mt-3'>
        <div
          className='fs-5'
          style={{
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#003855',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>
        <div
          className='mt-2 fs-4'
          style={{ color: '#785938', fontWeight: 400 }}
        >
          Registration for Lodging & Meal Package
        </div>
      </div>

      <div
        className='d-flex align-items-center justify-content-center mt-4'
        style={{ marginBottom: '20px' }}
      >
        <div className='w-100' style={{ maxWidth: '500px' }}>
          <div className='card shadow-lg'>
            <div
              style={{
                display: 'flex',
                width: '100%',
                borderRadius: '8px 8px 0 0',
                borderBottom: '1px solid #625337',
                overflow: 'hidden',
              }}
            >
              <TabHeader label='Individual Login' value='individual' />
              <TabHeader label='Group Login' value='group' />
            </div>

            {activeTab === 'individual' && (
              <div className='px-4 py-3'>
                <form onSubmit={handleIndividualSubmit}>
                  <div className='mb-3'>
                    <label
                      className='mb-2 text-muted fw-semibold'
                      htmlFor='data'
                    >
                      LSM Attendee ID
                    </label>
                    <input
                      id='data'
                      value={data}
                      onChange={(e) => setData(e.target.value)}
                      type='text'
                      className={`form-control ${
                        errors.data ? 'border-danger' : ''
                      }`}
                    />
                    {errors.data && (
                      <small className='text-danger'>{errors.data}</small>
                    )}
                  </div>

                  {showPinField && (
                    <div className='mb-3'>
                      <label
                        className='mb-2 text-muted fw-semibold'
                        htmlFor='pin'
                      >
                        Last 6 digits of your registered phone number
                      </label>
                      <input
                        id='pin'
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        type='text'
                        maxLength='6'
                        className={`form-control ${
                          errors.pin ? 'border-danger' : ''
                        }`}
                      />
                      {errors.pin && (
                        <small className='text-danger'>{errors.pin}</small>
                      )}
                    </div>
                  )}

                  {!errorMsg && infoMsg && (
                    <div className='mb-3 text-center text-primary fw-semibold'>
                      {infoMsg}
                    </div>
                  )}

                  {errorMsg && (
                    <div className='mb-3 text-center text-danger fw-bold'>
                      {errorMsg}
                    </div>
                  )}

                  <div className='d-flex align-items-center'>
                    <button
                      type='submit'
                      className='btn w-100 text-white'
                      style={{
                        backgroundColor: '#937d52',
                        borderColor: '#937d52',
                      }}
                      disabled={isLoading || !data.trim()}
                    >
                      {isLoading ? (
                        <span className='spinner-border spinner-border-sm'></span>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}

            {activeTab === 'group' && (
              <div className='px-4 py-3'>
                <form onSubmit={handleGroupSubmit}>
                  <div className='mb-3'>
                    <label
                      className='mb-2 text-muted fw-semibold'
                      htmlFor='locality'
                    >
                      Locality
                    </label>
                    <input
                      id='locality'
                      value={locality}
                      onChange={(e) => setLocality(e.target.value)}
                      type='text'
                      className={`form-control ${
                        errors.locality ? 'border-danger' : ''
                      }`}
                    />
                    {errors.locality && (
                      <small className='text-danger'>{errors.locality}</small>
                    )}
                  </div>

                  {showPasswordField && (
                    <div className='mb-3'>
                      <label
                        className='mb-2 text-muted fw-semibold'
                        htmlFor='password'
                      >
                        Password (minimum 6 characters)
                      </label>
                      <input
                        id='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type='text'
                        className={`form-control ${
                          errors.password ? 'border-danger' : ''
                        }`}
                      />
                      {errors.password && (
                        <small className='text-danger'>{errors.password}</small>
                      )}
                    </div>
                  )}

                  {!errorMsg && infoMsg && (
                    <div className='mb-3 text-center text-primary fw-semibold'>
                      {infoMsg}
                    </div>
                  )}

                  {errorMsg && (
                    <div className='mb-3 text-center text-danger fw-bold'>
                      {errorMsg}
                    </div>
                  )}

                  <div className='d-flex align-items-center'>
                    <button
                      type='submit'
                      className='btn w-100 text-white'
                      style={{
                        backgroundColor: '#937d52',
                        borderColor: '#937d52',
                      }}
                      disabled={isLoading || !locality.trim()}
                    >
                      {isLoading ? (
                        <span className='spinner-border spinner-border-sm'></span>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <ContactUs onCancel={handleCancel} />
      </Modal>
      <Modal
        open={isGuideVisible}
        onCancel={() => setIsGuideVisible(false)}
        footer={null}
      >
        <IteroLoginGuide onCancel={() => setIsGuideVisible(false)} />
      </Modal>
    </div>
  )
}

export default IntlLogin
