import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TitleModal from '../../../components/shared/TitleModal'

const IntlIndividualRoomPair = ({ record, onClose, onUpdate }) => {
  const [idValue, setIdValue] = useState('')
  const [loading, setLoading] = useState(false)

  if (!record) return null

  const isPaired = record.completeRoom === 1

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isPaired) {
      toast.warn('User is already paired!')
      return
    }

    if (!idValue.trim()) {
      toast.error('Please fill in the roommate info.')
      return
    }

    setLoading(true)

    try {
      const response = await axios.post(
        '/api/v1/intl/individual/pairing-update',
        { confirmCode: idValue },
        { withCredentials: true }
      )

      if (response.data.success) {
        toast.success('Pairing updated successfully')
        setIdValue('')
        onClose()

        const updatedRecord = response.data.updatedRecord || {}
        const updatedPairedRecord = response.data.updatedPairedRecord || null

        onUpdate(updatedRecord, updatedPairedRecord)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      const msg = error?.response?.data?.message || 'Failed to update pairing'
      toast.error(msg)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setIdValue('')
    onClose()
  }

  return (
    <div className='modal-content p-1'>
      <TitleModal title='Room Pairing' />

      <form onSubmit={handleSubmit}>
        <div className='mb-3 position-relative'>
          <label className='form-label'>
            Add your roommate accommodation code
          </label>
          <input
            type='text'
            className='form-control'
            value={idValue}
            onChange={(e) => setIdValue(e.target.value)}
          />
        </div>

        <div className='d-flex justify-content-end mt-3'>
          <button
            type='submit'
            className='btn btn-primary btn-sm me-4'
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Submit'}
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </form>

      <style>{`
        .clear-btn {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          color: #999;
          font-size: 16px;
          cursor: pointer;
        }
        .clear-btn:hover {
          color: #333;
        }
      `}</style>
    </div>
  )
}

export default IntlIndividualRoomPair
