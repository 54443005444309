import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getLanguageName } from './../../../utils/language'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import TitleModal from './../../../components/shared/TitleModal'

const OnlineMeetingInfo = ({ eventId, onClose }) => {
  const [meetingInfo, setMeetingInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (eventId) {
      fetchMeetingInfo(eventId)
    }
  }, [eventId])

  const fetchMeetingInfo = async (eventId) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/user/dashboard/meeting/info/${eventId}`,
        {
          withCredentials: true,
        }
      )
      setMeetingInfo(response.data)
    } catch (error) {
      console.error('No meeting info:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCopy = () => {
    if (meetingInfo?.userEventCode) {
      navigator.clipboard.writeText(meetingInfo.userEventCode)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    }
  }

  return (
    <div>
      <TitleModal title='Zoom Meeting Info' />
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : meetingInfo ? (
        <div style={{ fontSize: '17px' }}>
          <div style={{ fontWeight: '500' }}>
            Language (for Grouping): {getLanguageName(meetingInfo.language)}
          </div>
          <div className='mt-1'>
            <ol style={{ paddingLeft: '20px' }}>
              <li>
                Your personal ID code is{' '}
                <span style={{ fontWeight: '600' }}>
                  {meetingInfo.userEventCode}
                </span>{' '}
                (Click{' '}
                <button onClick={handleCopy} className='btn btn-warning btn-sm'>
                  {copied ? 'Copied!' : 'here'}
                </button>{' '}
                to copy your personal ID)
                <div style={{ color: 'red' }}>
                  This code is required at all times for you to enter into the
                  meeting room.
                </div>
              </li>
              <li>
                On the actual day of the meeting, the “Start” button will be
                available for access 45 minutes before the meeting starts.
              </li>
              <li>
                Rename yourself to the prescribed personal ID code (
                <span style={{ fontWeight: '600' }}>
                  {meetingInfo.userEventCode}
                </span>
                ). Names other than this format will{' '}
                <span style={{ fontWeight: '600' }}>
                  <u>NOT</u>
                </span>{' '}
                be allowed to enter into the meeting room.
              </li>
              <li>
                You are requested to dress solemnly and decently, and your
                background needs to be simple and proper.
              </li>
              <li>You are required to remain MUTED during the message.</li>
            </ol>
          </div>
        </div>
      ) : (
        <h6>No meeting info available.</h6>
      )}

      <div className='text-end mt-2'>
        <button className='close-btn' onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default OnlineMeetingInfo
