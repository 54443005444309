import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios'

const AdminIteroList = () => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const observer = useRef()

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    if (searchData.length < 3) {
      fetchLocalities()
    }
  }, [page])

  const fetchLocalities = async () => {
    try {
      setLoading(true)
      const response = await axios.post('/api/v1/admin/locality/itero-list', {
        page,
        search: '',
      })
      const { data, hasMore: moreData } = response.data

      setItems((prevItems) => (page === 1 ? data : [...prevItems, ...data]))
      setHasMore(moreData)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchData.length > 3) {
      searchLocalities()
    }
  }, [searchData])

  const searchLocalities = async () => {
    try {
      setIsSearching(true)
      const response = await axios.post('/api/v1/admin/locality/itero-list', {
        page: 1,
        search: searchData,
      })
      const { data } = response.data

      setItems(data)
      setHasMore(false)
    } catch (error) {
      console.error('Error searching localities:', error)
    } finally {
      setIsSearching(false)
    }
  }

  const handleSearch = (e) => {
    const value = e.target.value
    setSearchData(value)
    setPage(1)

    if (value === '') {
      fetchLocalities()
    } else if (value.length > 3) {
      searchLocalities()
    }
  }

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    fetchLocalities()
  }

  return (
    <>
      <div
        className='d-flex align-items-center justify-content-between mb-3'
        style={{ width: '100%' }}
      >
        <div className='d-flex' style={{ width: '50%' }}>
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Locality'
            onChange={handleSearch}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Country</th>
              <th scope='col'>City</th>
              <th scope='col'>Code</th>
              <th scope='col'>Coordinator Name</th>
              <th scope='col'>Email</th>
              <th scope='col'>Phone</th>
              <th scope='col'>Verify</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr
                key={item._id}
                ref={index === items.length - 1 ? lastElementRef : null}
              >
                <td>{item.country}</td>
                <td>{item.name}</td>
                <td>{item.code}</td>
                <td>{item.coordinator}</td>
                <td>{item.email}</td>
                <td>{item.phoneNo}</td>
                <td style={{ color: item.verify === 1 ? 'green' : 'red' }}>
                  {item.verify === 1 ? 'Yes' : 'No'}
                </td>
                <td className='text-end'></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='text-center'>{loading && <p>Loading...</p>}</div>
    </>
  )
}

export default AdminIteroList
