import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useAuth } from '../../../context/auth'
import { Modal, Tooltip } from 'antd'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../components/shared/LoadingSpinner'
import IntlIndividualRoomPair from './RoomPair'
import IntlRegisterView from './RegisterView'
import IntlRegisterForm from './RegisterForm'

const IntlIndividualRegister = () => {
  const navigate = useNavigate()
  const [stayPackages, setStayPackages] = useState([])
  const [loading, setLoading] = useState(true)
  const [auth] = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState([])

  const [existingData, setExistingData] = useState(null)
  const [checkDone, setCheckDone] = useState(false)
  const [pairingLoading, setPairingLoading] = useState(false)

  const [formData, setFormData] = useState({
    icNo: '',
    age: '',
    phoneCode: null,
    phoneNo: '',
    package: '',
    feeUsd: 0,
    remark: '',
    mealPackage: 1,
    meeting: false,
    spouse: null,
  })

  useEffect(() => {
    if (auth?.user?.name || auth?.user?.email) {
      setFormData((prev) => ({
        ...prev,
        name: auth.user.name || '',
        email: auth.user.email || '',
      }))
    }
  }, [auth])

  useEffect(() => {
    const fetchStayPackages = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/v1/intl/form', {
          withCredentials: true,
        })
        setStayPackages(response.data)
      } catch (error) {
        console.error('Error fetching stay-in packages:', error)
      } finally {
        setLoading(false)
      }
    }

    if (checkDone && !existingData) {
      fetchStayPackages()
    }
  }, [checkDone, existingData])

  useEffect(() => {
    const checkExisting = async () => {
      try {
        const res = await axios.get('/api/v1/intl/individual/form/check', {
          withCredentials: true,
        })
        if (res.data.success && res.data.data) {
          setExistingData(res.data.data)
        }
      } catch (err) {
        console.error('Check existing error:', err)
      } finally {
        setCheckDone(true)
      }
    }

    if (auth?.user?._id) {
      checkExisting()
    }
  }, [auth])

  const handleFieldChange = (field, value) => {
    setFormData((prev) => {
      let updated = { ...prev }

      if (field === 'age') {
        value = value.replace(/\D/g, '').slice(0, 2)
        updated.age = value
      } else if (field === 'package') {
        updated.package = value
        const selectedPkg = stayPackages.find((pkg) => pkg.pkgId === value)
        updated.feeUsd = selectedPkg ? parseFloat(selectedPkg.feeUsd) : 0

        if (selectedPkg?.spouseInfo === 1) {
          updated.spouse = updated.spouse || {
            name: '',
            email: '',
            phoneNo: '',
            age: '',
            icNo: '',
            remark: '',
            mealPackage: 1,
            meeting: false,
          }
        } else {
          updated.spouse = null
        }
      } else if (field.startsWith('spouse.')) {
        if (!prev.spouse) return prev
        const spouseField = field.split('.')[1]
        updated.spouse = {
          ...prev.spouse,
          [spouseField]: value,
        }
      } else {
        updated[field] = value
      }

      return updated
    })
    if (value?.toString().trim() !== '') {
      setErrors((prev) => {
        const updatedErrors = { ...prev }

        if (field.startsWith('spouse.')) {
          const spouseField = field.split('.')[1]
          if (updatedErrors.spouse) {
            updatedErrors.spouse = {
              ...updatedErrors.spouse,
              [spouseField]: '',
            }

            if (
              Object.values(updatedErrors.spouse).every(
                (val) => val === '' || val == null
              )
            ) {
              delete updatedErrors.spouse
            }
          }
        } else {
          updatedErrors[field] = ''
        }

        return updatedErrors
      })
    }
  }

  const validateForm = () => {
    const newErrors = {}

    if (!formData.icNo?.trim()) newErrors.icNo = 'Required!'
    if (!formData.age?.trim()) newErrors.age = 'Required!'
    else if (!/^\d{2}$/.test(formData.age)) newErrors.age = 'Must be 2 digits!'

    if (!formData.phoneCode) newErrors.phoneCode = 'Required!'
    if (!formData.phoneNo?.trim()) newErrors.phoneNo = 'Required!'
    if (!formData.package) newErrors.package = 'Required!'

    if (formData.spouse) {
      const spouseErrors = {}
      if (!formData.spouse.name?.trim()) spouseErrors.name = 'Required!'
      if (!formData.spouse.email?.trim()) spouseErrors.email = 'Required!'
      if (!formData.spouse.icNo?.trim()) spouseErrors.icNo = 'Required!'
      if (!formData.spouse.age?.trim()) spouseErrors.age = 'Required!'
      else if (!/^\d{2}$/.test(formData.spouse.age))
        spouseErrors.age = 'Must be 2 digits!'
      if (!formData.spouse.phoneCode) spouseErrors.phoneCode = 'Required!'
      if (!formData.spouse.phoneNo?.trim()) spouseErrors.phoneNo = 'Required!'

      if (Object.keys(spouseErrors).length > 0) {
        newErrors.spouse = spouseErrors
      }
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async () => {
    if (!validateForm()) return
    setIsSubmitting(true)
    try {
      const selectedPkg = stayPackages.find(
        (pkg) => pkg.pkgId === formData.package
      )

      const payload = {
        ...formData,
        packageNmEn: selectedPkg?.packageNmEn || '',
        packageCapacity: selectedPkg?.capacity || '',
        phoneCode: formData.phoneCode?.value || '',
      }

      const res = await axios.post('/api/v1/intl/individual/form', payload, {
        withCredentials: true,
      })

      setExistingData(res.data.data)
    } catch (error) {
      console.error('Error submitting:', error)
      toast.error('Submission failed!')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleOpenModal = (record) => {
    setSelectedRecord(record)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedRecord(null)
  }

  const handlePairingUpdate = (updatedRecord, updatedPairedRecord) => {
    setExistingData((prev) => ({
      ...prev,
      ...updatedRecord,
    }))
  }

  const handlePairingCheck = async (record) => {
    setPairingLoading(true)
    try {
      const response = await axios.get('/api/v1/intl/individual/pair/check', {
        withCredentials: true,
      })
      const latestRecord = response.data.data
      if (latestRecord.pairingName) {
        setExistingData((prev) => ({
          ...prev,
          ...latestRecord,
        }))
        return
      }
      handleOpenModal(latestRecord)
    } catch (error) {
      console.error('Error checking pairing status:', error)
      toast.error('Failed to verify pairing status.')
    } finally {
      setPairingLoading(false)
    }
  }

  return (
    <Layout title={'2025 September ITERO'}>
      <div className='p-3'>
        <div
          className='mb-3 text-uppercase'
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
            disabled
          >
            Lodging & Meal
          </button>

          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Lodging & Meal
        </div>

        <div
          className='mb-2'
          style={{ fontWeight: 600, color: '#951a26', fontSize: '17px' }}
        >
          <i className='bi bi-exclamation-circle-fill'></i> Please make payment
          as soon as possible to confirm your room reservation. Once the event
          venue is full, your booking will be directed to the overflowing hotel.
        </div>
        {!checkDone ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : existingData ? (
          <IntlRegisterView
            existingData={existingData}
            onPairingCheck={handlePairingCheck}
            pairingLoading={pairingLoading}
          />
        ) : (
          <>
            <IntlRegisterForm
              formData={formData}
              setFormData={setFormData}
              stayPackages={stayPackages}
              handleFieldChange={handleFieldChange}
              handleSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </>
        )}
        <Modal open={isModalOpen} onCancel={handleCloseModal} footer={null}>
          <IntlIndividualRoomPair
            record={selectedRecord}
            onClose={handleCloseModal}
            onUpdate={handlePairingUpdate}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlIndividualRegister
