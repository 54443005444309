import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import TitleModal from '../../../components/shared/TitleModal'

const MeetingGroupLanguage = ({ onClose, eventId, refreshDashboard }) => {
  const [selectedLang, setSelectedLang] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleLangChange = (e) => {
    setSelectedLang(e.target.value)
  }

  const handleSubmit = async () => {
    if (!selectedLang) {
      toast.error('Please select a language!')
      return
    }

    setLoading(true)

    try {
      await axios.post('/api/v1/user/dashboard/online/register', {
        eventId,
        language: selectedLang,
      })

      toast.success('Submitted Successfully')
      setSelectedLang(null)
      onClose()
      refreshDashboard()
    } catch (error) {
      toast.error('Failed to submit')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <TitleModal title='Choose Language (for Grouping)' />
      <div className='mt-3' style={{ fontSize: '17px' }}>
        <div className='d-flex align-items-center'>
          <div className='form-check me-4'>
            <input
              type='radio'
              className='form-check-input'
              id='langEnglish'
              value='1'
              checked={selectedLang === '1'}
              onChange={handleLangChange}
            />
            <label className='form-check-label' htmlFor='langEnglish'>
              English
            </label>
          </div>

          <div className='form-check me-4'>
            <input
              type='radio'
              className='form-check-input'
              id='langChinese'
              value='2'
              checked={selectedLang === '2'}
              onChange={handleLangChange}
            />
            <label className='form-check-label' htmlFor='langChinese'>
              中文
            </label>
          </div>

          <div className='form-check'>
            <input
              type='radio'
              className='form-check-input'
              id='langBM'
              value='3'
              checked={selectedLang === '3'}
              onChange={handleLangChange}
            />
            <label className='form-check-label' htmlFor='langBM'>
              BM
            </label>
          </div>
        </div>
      </div>

      <div className='mt-3 text-end'>
        <button
          type='button'
          className='btn btn-primary btn-sm'
          onClick={handleSubmit}
          disabled={!selectedLang || loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>

        <button
          type='button'
          className='btn btn-secondary btn-sm ms-3'
          onClick={() => {
            if (!loading) {
              // ✅ Prevent closing while submitting
              setSelectedLang(null)
              onClose()
            }
          }}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default MeetingGroupLanguage
