import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TitleModal from './../../components/shared/TitleModal'

const SpecificPayment = ({ eventId, onClose, refreshDashboard }) => {
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const fileInputRef = useRef()
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      try {
        const response = await axios.get(
          `/api/v1/specific/payment/${eventId}`,
          { withCredentials: true }
        )
        setPaymentInfo(response.data)
      } catch (error) {
        toast.error(
          error.response?.data?.message || 'Failed to load payment details'
        )
      } finally {
        setLoading(false)
      }
    }

    if (eventId) {
      fetchPaymentInfo()
    }
  }, [eventId])

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleUploadSlip = async () => {
    if (!selectedFile) {
      toast.error('Please select a file.')
      return
    }

    try {
      setUploading(true)
      const formData = new FormData()
      formData.append('paySlip', selectedFile)
      formData.append('eventId', eventId)

      await axios.post('/api/v1/specific/payment/upload-slip', formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      toast.success('Pay slip uploaded successfully!')
      setSelectedFile(null)
      onClose()
      fileInputRef.current.value = null
      if (refreshDashboard) refreshDashboard()
    } catch (error) {
      console.error('Upload failed:', error)
      toast.error(
        'Upload failed: ' + (error.response?.data?.message || 'Unknown error')
      )
    } finally {
      setUploading(false)
    }
  }

  return (
    <div>
      <TitleModal title='Bank Account Information' />
      {loading ? (
        <div className='text-center'>
          <Spin size='large' />
        </div>
      ) : paymentInfo ? (
        <div style={{ fontWeight: '600', fontSize: '16px' }}>
          <div className='mb-1'>Account name: {paymentInfo.accName}</div>
          <div className='mb-1'>Name of Bank: {paymentInfo.bankName}</div>
          <div className='mb-1'>Account number: {paymentInfo.accNo}</div>
          <div className='mb-2'>Payment: RM {paymentInfo.fee}</div>
          <div className='d-flex flex-column mb-2'>
            <div>Upload Pay slip:</div>
            <div className='d-flex'>
              <input
                type='file'
                className='form-control form-control-sm'
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
          </div>
        </div>
      ) : (
        <p className='text-center'>No payment details available.</p>
      )}

      <div className='text-end mt-3'>
        <button
          className='btn btn-primary btn-sm me-3'
          onClick={handleUploadSlip}
          disabled={!selectedFile || uploading}
        >
          {uploading ? 'Uploading...' : 'Submit'}
        </button>
        <button onClick={onClose} className='btn btn-secondary btn-sm'>
          Close
        </button>
      </div>
    </div>
  )
}

export default SpecificPayment
