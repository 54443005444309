import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ChurchIndex from '../Index'
import { Modal } from 'antd'
import ChurchPaymentRecords from './Records'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useEvent } from '../../../context/event'

const ChurchPaymentIndex = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { event, loading } = useEvent()

  const [payments, setPayments] = useState([])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const [bankInfo, setBankInfo] = useState(null)
  const fileInputRef = useRef()
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()

  const handleCheckboxChange = (paymentId, isChecked) => {
    setSelectedPayments((prevSelected) => {
      let updatedSelectedPayments
      if (isChecked) {
        updatedSelectedPayments = [...prevSelected, paymentId]
      } else {
        updatedSelectedPayments = prevSelected.filter((id) => id !== paymentId)
      }

      return updatedSelectedPayments
    })
  }

  const fetchPayments = async (pageNumber = 1, search = '') => {
    if (pageNumber === 1) {
      setInitialLoading(true)
    } else {
      setLoadingMore(true)
    }

    try {
      const response = await fetch(
        `/api/v1/church/payment/unpaid-list/${id}?page=${pageNumber}&search=${encodeURIComponent(
          search
        )}`,
        {
          withCredentials: true,
        }
      )

      if (response.ok) {
        const data = await response.json()
        setPayments((prev) => (pageNumber === 1 ? data : [...prev, ...data]))
        if (data.length < 20) setHasMore(false)
      } else {
        const errorResponse = await response.json()
        console.error('Error fetching payments:', errorResponse.message)
      }
    } catch (error) {
      console.error('Error fetching payment data:', error)
    } finally {
      if (pageNumber === 1) {
        setInitialLoading(false)
      } else {
        setLoadingMore(false)
      }
    }
  }

  useEffect(() => {
    fetchPayments(page)
  }, [page])

  useEffect(() => {
    if (event?.paymentAcc) {
      axios
        .get(`/api/v1/church/payment/bank/${event.paymentAcc}`)
        .then((res) => setBankInfo(res.data))
        .catch((err) => console.error(err))
    }
  }, [event])

  const lastPaymentRef = useCallback(
    (node) => {
      if (loadingMore) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loadingMore, hasMore]
  )

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleUploadSlip = async () => {
    if (!selectedFile) {
      toast.error('Please select a file.')
      return
    }

    try {
      setUploading(true)
      const formData = new FormData()
      formData.append('paySlip', selectedFile)
      formData.append('eventId', id)

      await axios.post('/api/v1/church/payment/upload-slip', formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      toast.success('Pay slip uploaded successfully!')
      setSelectedFile(null)
      fileInputRef.current.value = null
    } catch (error) {
      console.error('Upload failed:', error)
      toast.error(
        'Upload failed: ' + (error.response?.data?.message || 'Unknown error')
      )
    } finally {
      setUploading(false)
    }
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchQuery(value)

    if (value.length > 3 || value.length === 0) {
      setPage(1)
      setHasMore(true)
      fetchPayments(1, value)
    }
  }

  const handleClearSearch = () => {
    setSearchQuery('')
    setPage(1)
    setHasMore(true)
    fetchPayments(1, '')
  }

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const handlePayForSelected = async () => {
    if (selectedPayments.length === 0) {
      setErrorMessage('Please select at least one to pay.')
      return
    }

    const confirmPay = window.confirm('Confirm Pay?')
    if (confirmPay) {
      try {
        await axios.patch(
          `/api/v1/church/payment/pay-multiple`,
          { paymentIds: selectedPayments },
          { withCredentials: true }
        )

        toast.success('Payments updated successfully!')
        setSelectedPayments([])
        setPage(1)
        setHasMore(true)
        fetchPayments(1, searchQuery)
      } catch (error) {
        console.error('Error:', error)
        toast.error(
          'Failed: ' + (error.response?.data?.message || 'Unknown error')
        )
      }
    }
  }

  const handlePay = async (paymentId) => {
    const confirmPay = window.confirm('Confirm Pay?')

    if (confirmPay) {
      try {
        await axios.patch(`/api/v1/church/payment/${paymentId}/pay`, {
          withCredentials: true,
        })
        toast.success('Payment updated successfully!')
        setPage(1)
        setHasMore(true)
        fetchPayments(1, searchQuery)
      } catch (error) {
        console.error('Error:', error)
        toast.error(
          'Failed: ' + (error.response?.data?.message || 'Unknown error')
        )
      }
    }
  }

  return (
    <ChurchIndex>
      {loading || !event ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex justify-content-end gap-4 mb-3'>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}`)}
            >
              Registration List
            </button>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}/registration`)}
              disabled={event.regTypeStatus !== 1}
            >
              Registration Form
            </button>
            <button
              className='btn btn-success btn-sm'
              style={{ textTransform: 'uppercase' }}
              onClick={() => navigate(`/event/${id}/transportation`)}
            >
              Transportation
            </button>
          </div>

          <div
            className='fw-bold text-center mb-2'
            style={{
              fontSize: '17px',
              color: '#219119',
              textTransform: 'uppercase',
            }}
          >
            Payment
          </div>

          <div className='mb-1' style={{ fontWeight: 600, color: '#951a26' }}>
            <i className='bi bi-exclamation-circle-fill me-2'></i>
            All payments are non-refundable. After complete the payment, please
            match the payer by clicking “Pay” on the pending payment list.
          </div>
          <div className='mb-3'>
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <button className='btn btn-info btn-sm' onClick={showModal}>
                Payment Records
              </button>
            </div>

            <div
              className='mt-1 p-2'
              style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#fafafa',
              }}
            >
              <div className='d-flex justify-content-between align-items-start'>
                <div style={{ fontWeight: 'bold' }}>
                  Bank Account Information:
                  {bankInfo ? (
                    <div style={{ marginTop: '5px', fontWeight: '600' }}>
                      Account name: {bankInfo.accName}
                      <span className='mx-2' style={{ fontWeight: 'normal' }}>
                        |
                      </span>
                      Name of Bank: {bankInfo.bankName}
                      <span className='mx-2' style={{ fontWeight: 'normal' }}>
                        |
                      </span>
                      Account number: {bankInfo.accNo}
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: '5px',
                        fontWeight: 'normal',
                        color: 'gray',
                      }}
                    >
                      Loading bank info...
                    </div>
                  )}
                </div>
              </div>

              {bankInfo && (
                <div className='d-flex flex-column mt-3 mb-2'>
                  <div
                    style={{
                      marginBottom: '5px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Pay slip:
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='file'
                      className='form-control form-control-sm'
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={handleUploadSlip}
                      disabled={!selectedFile || uploading}
                    >
                      {uploading ? 'Uploading...' : 'Submit'}
                    </button>
                  </div>
                </div>
              )}
            </div>

            {errorMessage && (
              <div
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '5px',
                  textAlign: 'right',
                }}
              >
                {errorMessage}
              </div>
            )}
          </div>

          <div className='text-center fw-bold mb-1'>Pending Payment List </div>
          <div className='d-flex justify-content-end align-items-center'>
            <div
              className='d-flex align-items-center'
              style={{ textAlign: 'right' }}
            >
              <div style={{ width: '300px' }}>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search English Name'
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <button
                className='btn btn-outline-secondary'
                onClick={handleClearSearch}
                disabled={searchQuery.length === 0}
              >
                Clear
              </button>
            </div>

            <button
              className='btn btn-warning btn-sm ms-4'
              onClick={handlePayForSelected}
              disabled={selectedPayments.length === 0}
            >
              {`Pay for ${selectedPayments.length} Selected`}
            </button>
          </div>

          <div
            className='table-responsive'
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <table className='table'>
              <thead>
                <tr>
                  <th
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    Name
                  </th>
                  <th
                    className='text-end'
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    Fee (RM)
                  </th>
                  <th
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  ></th>
                  <th
                    className='text-end'
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#fff',
                      zIndex: 1,
                    }}
                  >
                    <input
                      type='checkbox'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPayments(
                            payments.map((payment) => payment._id)
                          )
                        } else {
                          setSelectedPayments([])
                        }
                      }}
                      checked={
                        selectedPayments.length === payments.length &&
                        payments.length > 0
                      }
                      className='checkbox-large me-2'
                      style={{
                        transform: 'scale(1.5)',
                        cursor: 'pointer',
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {initialLoading ? (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : payments.length === 0 ? (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      {searchQuery.length > 3
                        ? 'No records found.'
                        : 'No Pending Payment'}
                    </td>
                  </tr>
                ) : (
                  payments.map((payment, index) => (
                    <tr
                      key={payment._id}
                      ref={
                        payments.length === index + 1 ? lastPaymentRef : null
                      }
                    >
                      <td>
                        <div>
                          <span
                            style={{
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color:
                                payment.gender === 1 ? '#2f4483' : '#78003c',
                            }}
                          >
                            {payment.name}
                          </span>
                          {payment.chiName && (
                            <span className='ms-3'>{payment.chiName}</span>
                          )}
                        </div>
                      </td>

                      <td className='text-end'>
                        {payment.fee ? payment.fee.toFixed(2) : '0.00'}
                      </td>
                      <td className='text-center'>
                        <button
                          className='btn btn-warning btn-sm'
                          onClick={() => handlePay(payment._id)}
                        >
                          Pay
                        </button>
                      </td>

                      <td className='text-end'>
                        <input
                          className='me-2'
                          type='checkbox'
                          checked={selectedPayments.includes(payment._id)}
                          onChange={(e) =>
                            handleCheckboxChange(payment._id, e.target.checked)
                          }
                          style={{
                            transform: 'scale(1.5)',
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                    </tr>
                  ))
                )}
                {loadingMore && (
                  <tr>
                    <td colSpan='4' className='text-center'>
                      Loading more...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Modal
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={800}
            destroyOnClose
          >
            <ChurchPaymentRecords
              key={isModalVisible}
              id={id}
              onCancel={handleCancel}
            />
          </Modal>
        </>
      )}
    </ChurchIndex>
  )
}

export default ChurchPaymentIndex
