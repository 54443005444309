import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import { Modal } from 'antd'
import { toast } from 'react-toastify'
import AdminAddUserRegister from './AddUserRegister'

const AdminAddEventRegister = () => {
  const { id } = useParams()
  const [eventName, setEventName] = useState('')
  const [search, setSearch] = useState('')
  const [results, setResults] = useState([])
  const [noResults, setNoResults] = useState(false)
  const [eventPackages, setEventPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const [userStates, setUserStates] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [registeringUserId, setRegisteringUserId] = useState(null)

  // Fetch event name
  useEffect(() => {
    const fetchEventName = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/admin/registration/event/${id}`
        )
        setEventName(data.eventName)
      } catch (error) {
        console.error('Error fetching event name:', error)
      }
    }

    if (id) fetchEventName()
  }, [id])

  // Search users
  useEffect(() => {
    if (search.length < 4) {
      setResults([])
      setNoResults(false)
      return
    }

    const fetchSearchResults = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `/api/v1/admin/registration/users/search`,
          {
            params: { keyword: search, eventId: id },
          }
        )

        setResults(data.users)
        setEventPackages(data.packages || [])
        setNoResults(data.users.length === 0)
      } catch (err) {
        console.error('Search error:', err)
      } finally {
        setLoading(false)
      }
    }

    const delayDebounce = setTimeout(fetchSearchResults, 400)
    return () => clearTimeout(delayDebounce)
  }, [search, id])

  useEffect(() => {
    setUserStates((prevStates) => {
      const newStates = { ...prevStates }
      results.forEach((user) => {
        if (!user.packageNmEn && !newStates[user._id]) {
          const ic = user.icNo || ''
          const calculatedAge = calculateAgeFromIC(ic)
          newStates[user._id] = {
            ic: ic,
            age: calculatedAge !== null ? calculatedAge : user.age || '',
          }
        }
      })
      return newStates
    })
  }, [results])

  const handleClear = () => setSearch('')

  const calculateAgeFromIC = (ic) => {
    const cleanedIC = ic.replace(/-/g, '')
    if (!/^\d{12}$/.test(cleanedIC)) return null
    const year = parseInt(cleanedIC.slice(0, 2), 10)
    const fullYear = year >= 50 ? 1900 + year : 2000 + year
    const month = parseInt(cleanedIC.slice(2, 4), 10) - 1
    const day = parseInt(cleanedIC.slice(4, 6), 10)

    const birthDate = new Date(fullYear, month, day)
    const today = new Date()

    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }

    return age
  }

  const handleICChange = (e, userId) => {
    const ic = e.target.value
    setUserStates((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        ic,
      },
    }))
  }

  const handleICBlur = (userId) => {
    const ic = userStates[userId]?.ic || ''
    const calculatedAge = calculateAgeFromIC(ic)

    setUserStates((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        age: calculatedAge !== null ? calculatedAge : '',
      },
    }))
  }

  const handleAgeChange = (e, userId) => {
    const age = e.target.value
    setUserStates((prev) => ({
      ...prev,
      [userId]: {
        ...prev[userId],
        age,
      },
    }))
  }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleRegisterUser = async (userId) => {
    setRegisteringUserId(userId)
    try {
      const packageId = document.querySelector(
        `select[name="package-${userId}"]`
      )?.value
      const remark = userStates[userId]?.remark || ''
      const age = userStates[userId]?.age
      const ic = userStates[userId]?.ic
      const language = userStates[userId]?.language
      await axios.post('/api/v1/admin/registration/add-existing-user', {
        eventId: id,
        userId,
        packageId,
        remark,
        age,
        ic,
        language,
      })

      setSearch('')
      setResults([])
      setNoResults(false)

      toast.success('Registered successfully!')
    } catch (error) {
      console.error(error)
      toast.error(error.response?.data?.message || 'Failed to register user')
    } finally {
      setRegisteringUserId(null)
    }
  }

  return (
    <Layout title={`Event Transport Records - ${eventName || 'Loading...'}`}>
      <div className='py-3 px-2'>
        {/* Header */}
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <Link to={`/admin/registration/data/${id}`}>
            <button type='button' className='btn btn-secondary btn-sm'>
              <i className='bi bi-arrow-left-circle'></i> Return
            </button>
          </Link>
          <h5 className='fw-bold text-center flex-grow-1 m-0'>
            {eventName || 'Loading event...'}
          </h5>
        </div>
        <hr />

        {/* Search */}
        <div className='d-flex justify-content-center mb-3'>
          <div
            className='d-flex align-items-center'
            style={{ maxWidth: '50%', width: '100%' }}
          >
            <input
              type='text'
              className='form-control'
              placeholder='Search by name, email, or phone number'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              type='button'
              className='btn btn-outline-secondary'
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>

        {/* Results Table */}
        <h6 className='fw-bold text-center mb-2'>Search Results</h6>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email/Phone</th>
                <th>Age (I.C./Passport)</th>
                <th>Locality</th>
                <th>Language</th>
                <th>Registration</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='7' className='text-center'>
                    <LoadingSpinner />
                  </td>
                </tr>
              ) : results.length > 0 ? (
                results.map((user) => {
                  const currentUserState = userStates[user._id] || {}
                  return (
                    <React.Fragment key={user._id}>
                      <tr>
                        <td>
                          <div
                            className='fw-bold'
                            style={{
                              textTransform: 'uppercase',
                              color: user.gender === 1 ? '#2f4483' : '#78003c',
                            }}
                          >
                            {user.name}
                          </div>
                          {user.chiName && <div>{user.chiName}</div>}
                        </td>
                        <td>
                          {user.email && !user.email.includes('@empty') && (
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <i
                                className='bi bi-envelope-fill me-2'
                                style={{ color: '#7a6844' }}
                              ></i>
                              {user.email}
                            </div>
                          )}
                          {user.phoneNo && (
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <i
                                className='bi bi-telephone-fill me-2'
                                style={{ color: '#7a6844' }}
                              ></i>
                              {user.phoneNo}
                            </div>
                          )}
                        </td>
                        <td>
                          {user.packageNmEn ||
                          (user.icNo &&
                            /^\d{6}-\d{2}-\d{4}$/.test(user.icNo)) ? (
                            <>
                              {user.age ||
                                (user.icNo &&
                                /^\d{6}-\d{2}-\d{4}$/.test(user.icNo)
                                  ? calculateAgeFromIC(user.icNo)
                                  : '')}
                              {user.icNo ? ` (${user.icNo})` : ''}
                            </>
                          ) : (
                            <div className='d-flex flex-column'>
                              <input
                                type='number'
                                className='form-control form-control-sm mb-1'
                                placeholder='Age'
                                value={currentUserState.age ?? ''}
                                onChange={(e) => handleAgeChange(e, user._id)}
                              />
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                placeholder='I.C./Passport'
                                value={currentUserState.ic ?? ''}
                                onChange={(e) => handleICChange(e, user._id)}
                                onBlur={() => handleICBlur(user._id)}
                              />
                            </div>
                          )}
                        </td>
                        <td>
                          {user.localityName || user.locality?.name || ''}
                        </td>
                        <td>
                          {!user.packageNmEn &&
                          (user.language === null ||
                            user.language === undefined) ? (
                            <select
                              className='form-select form-select-sm'
                              name={`language-${user._id}`}
                              value={currentUserState.language || ''}
                              onChange={(e) =>
                                setUserStates((prev) => ({
                                  ...prev,
                                  [user._id]: {
                                    ...prev[user._id],
                                    language: e.target.value,
                                  },
                                }))
                              }
                            >
                              <option value='' disabled>
                                Choose
                              </option>
                              <option value='1'>English</option>
                              <option value='2'>中文</option>
                              <option value='3'>BM</option>
                            </select>
                          ) : (
                            // If the user already has a language set, display it.
                            ['', 'English', '中文', 'BM'][user.language] ||
                            currentUserState.language ||
                            ''
                          )}
                        </td>

                        <td>
                          {user.packageNmEn ? (
                            user.packageNmEn
                          ) : (
                            <select
                              className='form-select form-select-sm'
                              defaultValue=''
                              name={`package-${user._id}`}
                            >
                              <option value='' disabled>
                                Select
                              </option>
                              {eventPackages.map((pkg) => (
                                <option key={pkg._id} value={pkg._id}>
                                  {pkg.packageNmEn}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        <td className='text-end' style={{ fontWeight: '600' }}>
                          {user.packageNmEn ? (
                            <span style={{ color: '#218838' }}>Registered</span>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-sm btn-outline-primary'
                              onClick={() => handleRegisterUser(user._id)}
                            >
                              {registeringUserId === user._id ? (
                                'Submit...'
                              ) : (
                                <i className='bi bi-send-fill'></i>
                              )}
                            </button>
                          )}
                        </td>
                      </tr>

                      {/* Remark Row (Second row for non-registered users) */}
                      {!user.packageNmEn && (
                        <tr>
                          <td colSpan='7'>
                            <div className='d-flex align-items-center'>
                              <label className='me-2 fw-bold'>Remark </label>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                value={userStates[user._id]?.remark || ''}
                                onChange={(e) => {
                                  const remark = e.target.value
                                  setUserStates((prev) => ({
                                    ...prev,
                                    [user._id]: {
                                      ...prev[user._id],
                                      remark,
                                    },
                                  }))
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })
              ) : noResults ? (
                <tr>
                  <td colSpan='7' className='text-center'>
                    <div className='text-muted mb-2'>No results found.</div>

                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={openModal}
                    >
                      <i className='bi bi-plus-circle me-1'></i> Add New
                      Registrant
                    </button>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>

        <Modal
          open={isModalOpen}
          onCancel={closeModal}
          footer={null}
          destroyOnClose
          width={800}
        >
          <AdminAddUserRegister
            eventPackages={eventPackages}
            eventId={id}
            closeModal={closeModal}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default AdminAddEventRegister
