import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    access: null,
    isRehydrated: false,
    isLoading: true,
  })
  axios.defaults.withCredentials = true

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('/api/v1/auth/access')
        setAuth({
          user: data.user,
          access: data.access,
          isRehydrated: true,
          isLoading: false,
        })
        Sentry.setUser({
          id: data.user,
          access: data.access,
        })
      } catch (error) {
        setAuth({
          user: null,
          access: null,
          isRehydrated: true,
          isLoading: false,
        })
        Sentry.setUser(null)
      }
    }

    checkAuth()
  }, [])

  if (auth.isLoading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.spinner}></div>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { useAuth, AuthProvider }

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#f5f5f5',
  },
  spinner: {
    width: '50px',
    height: '50px',
    border: '5px solid #ccc',
    borderTop: '5px solid #007bff',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
}
