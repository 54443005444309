import { useEffect } from 'react'

const TawkMessenger = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://embed.tawk.to/67d64917002322190c99b7bf/1inr76sph'
    script.async = true
    script.charset = 'UTF-8'
    script.setAttribute('crossorigin', '*')
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default TawkMessenger
