import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Spin } from 'antd'
import ChurchRegistrationList from './List'
import ChurchCoordinator from './Coordinator'
import { useEvent } from '../../context/event'

const ChurchIndex = ({ children }) => {
  const { event, hasCoordinator, setHasCoordinator, loading } = useEvent()

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-2'>
        {event ? (
          <div
            className='mb-3 text-uppercase text-center'
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#5d3104',
              borderBottom: '1px solid #d3c4a7',
              paddingBottom: '10px',
            }}
          >
            {event.nameEn}
          </div>
        ) : (
          <div className='mb-3 text-center' style={{ fontWeight: 'bold' }}>
            Loading...
          </div>
        )}

        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Spin size='large' />
          </div>
        ) : hasCoordinator === false ? (
          <ChurchCoordinator setHasCoordinator={setHasCoordinator} />
        ) : (
          children || <ChurchRegistrationList event={event} />
        )}
      </div>
    </Layout>
  )
}

export default ChurchIndex
