import React, { useState } from 'react'
import { Form, Input, Button, Radio } from 'antd'
import axios from 'axios'

const AdminNewIntlLocality = ({ onClose, onLocalityAdded }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await axios.post(
        '/api/v1/admin/locality/intl-add',
        values
      )
      if (response.status === 201) {
        onLocalityAdded(response.data.newLocality)
        form.resetFields()
        onClose()
      }
    } catch (error) {
      console.error('Error adding locality:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    onClose()
  }

  return (
    <>
      <h6 className='mb-3'>Add New International Locality</h6>

      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <Form.Item
          label='Locality Name'
          name='name'
          rules={[{ required: true, message: 'Please enter locality name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Locality Code'
          name='code'
          rules={[{ required: true, message: 'Please enter locality code' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Username' name='username'>
          <Input />
        </Form.Item>

        <Form.Item
          label='Local Access (Can View & Register Malaysia Conference/Training)'
          name='localAccess'
          rules={[{ required: true, message: 'Please select a Local Access' }]}
        >
          <Radio.Group>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label='Password' name='password'>
          <Input.Password />
        </Form.Item>

        <Form.Item className='text-end'>
          <Button
            type='primary'
            htmlType='submit'
            className='me-3'
            loading={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
          <Button type='default' onClick={handleCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default AdminNewIntlLocality
