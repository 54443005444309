import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

const IntlIndividualTransportForm = ({
  name,
  isSpouse = false,
  hasSpouse = false,
  onSuccess,
}) => {
  const [arrivalDate, setArrivalDate] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [arrivalFlightNoBus, setArrivalFlightNoBus] = useState('')
  const [departureDate, setDepartureDate] = useState('')
  const [departureTime, setDepartureTime] = useState('')
  const [departureFlightNoBus, setDepartureFlightNoBus] = useState('')
  const [remark, setRemark] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const isFormEmpty = () =>
    !(
      arrivalDate.trim() ||
      arrivalTime.trim() ||
      arrivalFlightNoBus.trim() ||
      departureDate.trim() ||
      departureTime.trim() ||
      departureFlightNoBus.trim() ||
      remark.trim()
    )

  const validateDates = () => {
    if (arrivalDate) {
      const year = new Date(arrivalDate).getFullYear()
      if (year !== 2025) {
        toast.error('Arrival year must be 2025!')
        return false
      }
    }
    if (departureDate) {
      const year = new Date(departureDate).getFullYear()
      if (year !== 2025) {
        toast.error('Departure year must be 2025!')
        return false
      }
    }
    return true
  }

  const handleSubmit = async () => {
    if (!validateDates()) return
    setSubmitting(true)

    try {
      const payload = {
        arrivalDate,
        arrivalTime,
        arrivalFlightNoBus,
        departureDate,
        departureTime,
        departureFlightNoBus,
        remark,
        isSpouse,
      }

      const res = await axios.post(
        '/api/v1/intl/transport/individual/create',
        payload,
        {
          withCredentials: true,
        }
      )

      if (res.data.success) {
        toast.success('Transport details submitted!')
        onSuccess?.()
      } else {
        toast.error(res.data.message || 'Submission failed.')
      }
    } catch (error) {
      console.error('Transport submission error:', error)
      toast.error('An error occurred while submitting.')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div
      className='p-3 rounded bg-white shadow-sm border mb-4'
      style={{ maxWidth: '70%', margin: '0 auto' }}
    >
      <h6 className='text-primary fw-bold mb-2'>Arrival</h6>
      <div className='d-flex justify-content-evenly mb-4'>
        <input
          type='date'
          className='form-control me-2'
          value={arrivalDate}
          onChange={(e) => setArrivalDate(e.target.value)}
        />
        <input
          type='time'
          className='form-control me-2'
          value={arrivalTime}
          onChange={(e) => setArrivalTime(e.target.value)}
        />
        <input
          type='text'
          className='form-control'
          placeholder='Flight No.'
          value={arrivalFlightNoBus}
          onChange={(e) => setArrivalFlightNoBus(e.target.value)}
        />
      </div>

      <h6 className='text-primary fw-bold mb-2'>Departure</h6>
      <div className='d-flex justify-content-evenly mb-4'>
        <input
          type='date'
          className='form-control me-2'
          value={departureDate}
          onChange={(e) => setDepartureDate(e.target.value)}
        />
        <input
          type='time'
          className='form-control me-2'
          value={departureTime}
          onChange={(e) => setDepartureTime(e.target.value)}
        />
        <input
          type='text'
          className='form-control'
          placeholder='Flight No.'
          value={departureFlightNoBus}
          onChange={(e) => setDepartureFlightNoBus(e.target.value)}
        />
      </div>

      <div className='mb-3'>
        <label className='form-label'>Remark</label>
        <textarea
          className='form-control'
          rows='2'
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </div>

      <div className='text-end'>
        <button
          className='btn btn-primary btn-s'
          onClick={handleSubmit}
          disabled={submitting || isFormEmpty()}
        >
          {submitting ? (
            <span className='spinner-border spinner-border-sm'></span>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </div>
  )
}

export default IntlIndividualTransportForm
