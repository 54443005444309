import React from 'react'
import { useAuth } from '../../../context/auth'
import { useNavigate } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import axios from 'axios'
import '../../../../src/styles/custom.css'

const IntlHeader = () => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await axios.post('/api/v1/auth/logout', {}, { withCredentials: true })
      setAuth({
        user: null,
        access: null,
        isRehydrated: true,
      })
      setTimeout(() => {
        navigate('/itero/hotel-reservation/login', {
          state: { loggedOut: true },
        })
      }, 100)
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return (
    <Navbar collapseOnSelect expand='lg' variant='dark' className='px-2 py-1'>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav
          className='ms-auto'
          style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
        >
          {auth?.user && (
            <span style={{ fontWeight: 'bold', color: '#000' }}>
              {auth?.user?.name}
            </span>
          )}
          {auth?.user ? (
            <Nav.Link onClick={handleLogout} style={{ color: '#337ab7' }}>
              <i className='bi bi-box-arrow-right'></i>
            </Nav.Link>
          ) : (
            <Nav.Link href='/itero/login'>Login</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default IntlHeader
