import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../../components/Layout/Layout'
import axios from 'axios'

const AdminNewEvent = () => {
  const navigate = useNavigate()

  const [eventData, setEventData] = useState({
    nameEn: '',
    nameZh: '',
    nameBm: '',
    startDt: new Date().toISOString().split('T')[0],
    endDt: new Date().toISOString().split('T')[0],
    time: '',
    regType1: '',
    regType2: '',
    venue: '',
    attendance: '',
    attendanceCode: '',
    attendanceDesc: '',
    intlEvent: '0',
  })

  const [packages, setPackages] = useState([
    {
      packageNmEn: '',
      packageNmZh: '',
      packageNmBm: '',
      fee: '',
      feeUsd: '',
      roomCode: '',
      packageType: '',
      stay: '0',
      meal: '0',
      children: '0',
      spouseInfo: '0',
      capacity: '',
      limit: '',
      bedOption: [],
    },
  ])

  const [banks, setBanks] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get('/api/v1/admin/event/banks')
        setBanks(response.data)
      } catch (error) {
        console.error('Error fetching banks:', error)
      }
    }
    fetchBanks()
  }, [])

  const handleEventDataChange = (field, value) => {
    setEventData({ ...eventData, [field]: value })
    if (errors[field]) {
      if (value.trim()) {
        const newErrors = { ...errors }
        delete newErrors[field]
        setErrors(newErrors)
      }
    }
  }

  const handleFileChange = (field, file) => {
    setEventData({ ...eventData, [field]: file })
  }

  const handleAddPackage = () => {
    setPackages([
      ...packages,
      {
        packageNmEn: '',
        packageNmZh: '',
        packageNmBm: '',
        fee: '',
        roomCode: '',
        packageType: '',
        stay: '0',
        meal: '0',
        children: '0',
        spouseInfo: '0',
        capacity: '',
        limit: '',
        bedOption: [],
      },
    ])
  }

  const handleRemovePackage = (index) => {
    const updatedPackages = packages.filter((_, i) => i !== index)
    setPackages(updatedPackages)
  }

  const handlePackageChange = (index, field, value) => {
    const updatedPackages = [...packages]

    if (field === 'packageType') {
      updatedPackages[index][field] = value
      updatedPackages[index].stay = value === '1' ? '1' : '0'
      updatedPackages[index].meal = value === '2' ? '1' : '0'
    } else if (field === 'bedOptionChecked') {
      updatedPackages[index].bedOption = value
        ? [{ bedCode: '', bedName: '' }]
        : []
      updatedPackages[index].roomCode = value
        ? ''
        : updatedPackages[index].roomCode
    } else if (field.startsWith('bedOption-')) {
      const [, key, idx] = field.split('-')
      const bedIndex = parseInt(idx, 10)

      if (updatedPackages[index].bedOption[bedIndex]) {
        updatedPackages[index].bedOption[bedIndex] = {
          ...updatedPackages[index].bedOption[bedIndex],
          [key]: value,
        }
      }
    } else {
      updatedPackages[index][field] = value
    }
    setPackages(updatedPackages)

    const errorKey = `${field}-${index}`
    if (errors[errorKey] && value.trim()) {
      const updatedErrors = { ...errors }
      delete updatedErrors[errorKey]
      setErrors(updatedErrors)
    }
  }

  const validateForm = () => {
    const newErrors = {}
    const requiredFields = [
      'nameEn',
      'nameZh',
      'nameBm',
      'regType1',
      'regType2',
      'venue',
      'attendance',
      'intlEvent',
    ]
    requiredFields.forEach((field) => {
      if (!eventData[field]?.trim()) {
        newErrors[field] = 'Required'
      }
    })

    if (eventData.attendance === '1') {
      if (!eventData.attendanceCode?.trim()) {
        newErrors.attendanceCode = 'Required'
      }
      if (!eventData.attendanceDesc?.trim()) {
        newErrors.attendanceDesc = 'Required'
      }
    }

    if (eventData.regType1 === '1') {
      packages.forEach((pkg, index) => {
        if (!pkg.packageType?.trim()) {
          newErrors[`packageType-${index}`] = 'Required'
        }
        if (!pkg.packageNmEn?.trim()) {
          newErrors[`packageNmEn-${index}`] = 'Required'
        }
        if (!pkg.packageNmZh?.trim()) {
          newErrors[`packageNmZh-${index}`] = 'Required'
        }
        if (!pkg.packageNmBm?.trim()) {
          newErrors[`packageNmBm-${index}`] = 'Required'
        }
        if (!pkg.fee?.trim()) {
          newErrors[`fee-${index}`] = 'Required'
        }
        if (eventData.intlEvent === '1' && !pkg.feeUsd?.trim()) {
          newErrors[`feeUsd-${index}`] = 'Required'
        }

        if (pkg.packageType === '1') {
          if (pkg.bedOption.length === 0 && !pkg.roomCode?.trim()) {
            newErrors[`roomCode-${index}`] = 'Required'
          }
          if (!pkg.capacity?.trim()) {
            newErrors[`capacity-${index}`] = 'Required'
          }
        }
      })
    }

    if (eventData.intlEvent === '1') {
      packages.forEach((pkg, index) => {
        if (!pkg.feeUsd?.trim()) {
          newErrors[`feeUsd-${index}`] = 'Required'
        }
      })
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (validateForm()) {
      const stayForm = packages.some((pkg) => pkg.packageType === '1')
        ? '1'
        : '0'
      const nonStayForm = packages.some((pkg) => pkg.packageType !== '1')
        ? '1'
        : '0'

      const formData = new FormData()
      formData.append('nameEn', eventData.nameEn)
      formData.append('nameZh', eventData.nameZh)
      formData.append('nameBm', eventData.nameBm)
      formData.append('startDt', eventData.startDt)
      formData.append('endDt', eventData.endDt)
      formData.append('time', eventData.time)
      formData.append('regType1', eventData.regType1)
      formData.append('regType2', eventData.regType2)
      formData.append('venue', eventData.venue)
      formData.append('attendance', eventData.attendance)
      formData.append('attendanceCode', eventData.attendanceCode)
      formData.append('attendanceDesc', eventData.attendanceDesc)
      formData.append('intlEvent', eventData.intlEvent)
      formData.append('paymentAcc', selectedBank)
      formData.append('stayForm', stayForm)
      formData.append('nonStayForm', nonStayForm)

      formData.append('packages', JSON.stringify(packages))
      if (eventData.letter) {
        formData.append('letter', eventData.letter)
      }
      if (eventData.form) {
        formData.append('form', eventData.form)
      }
      if (eventData.notice) {
        formData.append('notice', eventData.notice)
      }

      try {
        await axios.post('/api/v1/admin/event/create', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        navigate('/admin/events')
      } catch (error) {
        console.error(
          'Error submitting the form:',
          error.response?.data || error.message
        )
      }
    } else {
      console.error('Validation errors:', errors)
    }
  }

  const handleReturn = () => {
    navigate('/admin/events')
  }

  return (
    <Layout title={'Add New Event'}>
      <div className='p-3'>
        <div className='d-flex justify-content-end mb-2'>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={handleReturn}
          >
            Return
          </button>
        </div>

        <div className='mb-3'>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                Event Name
              </div>
              <div className='mx-3'>
                <div className='mb-2'>
                  <label className='form-label'>Eng.</label>
                  <input
                    type='text'
                    className={`form-control ${
                      errors.nameEn ? 'is-invalid' : ''
                    }`}
                    value={eventData.nameEn}
                    onChange={(e) =>
                      handleEventDataChange('nameEn', e.target.value)
                    }
                  />
                  {errors.nameEn && (
                    <div className='invalid-feedback'>{errors.nameEn}</div>
                  )}
                </div>

                <div className='mb-2'>
                  <label className='form-label'>Chi.</label>
                  <input
                    type='text'
                    className={`form-control ${
                      errors.nameZh ? 'is-invalid' : ''
                    }`}
                    value={eventData.nameZh}
                    onChange={(e) =>
                      handleEventDataChange('nameZh', e.target.value)
                    }
                  />
                  {errors.nameZh && (
                    <div className='invalid-feedback'>{errors.nameZh}</div>
                  )}
                </div>

                <div className='mb-2'>
                  <label className='form-label'>BM</label>
                  <input
                    type='text'
                    className={`form-control ${
                      errors.nameBm ? 'is-invalid' : ''
                    }`}
                    value={eventData.nameBm}
                    onChange={(e) =>
                      handleEventDataChange('nameBm', e.target.value)
                    }
                  />
                  {errors.nameBm && (
                    <div className='invalid-feedback'>{errors.nameBm}</div>
                  )}
                </div>
              </div>
            </div>

            {/* Date/Time Section */}
            <div className='mb-4'>
              <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                Date/Time
              </div>
              <div className='row mx-2'>
                <div className='col-md-4'>
                  <label className='form-label'>Date (From)</label>
                  <input
                    type='date'
                    className='form-control'
                    value={eventData.startDt}
                    onChange={(e) =>
                      handleEventDataChange('startDt', e.target.value)
                    }
                  />
                </div>
                <div className='col-md-4'>
                  <label className='form-label'>Date (To)</label>
                  <input
                    type='date'
                    className='form-control'
                    value={eventData.endDt}
                    onChange={(e) =>
                      handleEventDataChange('endDt', e.target.value)
                    }
                  />
                </div>
                <div className='col-md-4'>
                  <label className='form-label'>Time</label>
                  <input
                    type='text'
                    className='form-control'
                    name='time'
                    value={eventData.time || ''}
                    onChange={(e) =>
                      handleEventDataChange('time', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            {/* Files Section */}
            <div className='mb-4'>
              <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                Upload Files
              </div>
              <div className='row mx-2'>
                <div className='col-md-4'>
                  <label className='form-label'>Letter</label>
                  <input
                    type='file'
                    className='form-control'
                    name='letter'
                    onChange={(e) =>
                      handleFileChange('letter', e.target.files[0])
                    }
                  />
                </div>
                <div className='col-md-4'>
                  <label className='form-label'>Form</label>
                  <input
                    type='file'
                    className='form-control'
                    name='form'
                    onChange={(e) =>
                      handleFileChange('form', e.target.files[0])
                    }
                  />
                </div>
                <div className='col-md-4'>
                  <label className='form-label'>Notification</label>
                  <input
                    type='file'
                    className='form-control'
                    name='notice'
                    onChange={(e) =>
                      handleFileChange('notice', e.target.files[0])
                    }
                  />
                </div>
              </div>
            </div>

            {/* Registration Section */}
            <div className='mb-4'>
              <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                Registration
              </div>

              <div className='mx-3'>
                <div className='mb-2 d-flex align-items-center'>
                  <label className='me-3'>(1) Meeting: </label>
                  <div className='mt-1'>
                    <label className='me-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='regType1'
                        value='1'
                        onChange={(e) =>
                          handleEventDataChange('regType1', e.target.value)
                        }
                        checked={eventData.regType1 === '1'}
                      />
                      Physical
                    </label>
                    <label>
                      <input
                        className='me-1'
                        type='radio'
                        name='regType1'
                        value='2'
                        onChange={(e) =>
                          handleEventDataChange('regType1', e.target.value)
                        }
                        checked={eventData.regType1 === '2'}
                      />
                      Zoom
                    </label>
                    {errors.regType1 && (
                      <span className='text-danger ms-3'>
                        {errors.regType1}
                      </span>
                    )}
                  </div>
                </div>

                <div className='mb-2 d-flex align-items-center'>
                  <label className='me-3'>(2) Register: </label>
                  <div className='mt-1'>
                    <label>
                      <input
                        className='me-1'
                        type='radio'
                        name='regType2'
                        value='1'
                        onChange={(e) =>
                          handleEventDataChange('regType2', e.target.value)
                        }
                        checked={eventData.regType2 === '1'}
                      />
                      Personal
                    </label>
                    <label className='ms-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='regType2'
                        value='2'
                        onChange={(e) =>
                          handleEventDataChange('regType2', e.target.value)
                        }
                        checked={eventData.regType2 === '2'}
                      />
                      Church
                    </label>
                    <label className='ms-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='regType2'
                        value='3'
                        onChange={(e) =>
                          handleEventDataChange('regType2', e.target.value)
                        }
                        checked={eventData.regType2 === '3'}
                      />
                      Full-timers Blending & Perfecting
                    </label>
                    {errors.regType2 && (
                      <span className='text-danger ms-3'>
                        {errors.regType2}
                      </span>
                    )}
                  </div>
                </div>

                <div className='mb-2 d-flex align-items-center'>
                  <label className='me-3'>(3) Venue: </label>
                  <div className='mt-1'>
                    <label>
                      <input
                        className='me-1'
                        type='radio'
                        name='venue'
                        value='1'
                        onChange={(e) =>
                          handleEventDataChange('venue', e.target.value)
                        }
                        checked={eventData.venue === '1'}
                      />
                      Eden Residence (or other locality)
                    </label>
                    <label className='ms-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='venue'
                        value='2'
                        onChange={(e) =>
                          handleEventDataChange('venue', e.target.value)
                        }
                        checked={eventData.venue === '2'}
                      />
                      Hotel
                    </label>
                    {errors.venue && (
                      <span className='text-danger ms-3'>{errors.venue}</span>
                    )}
                  </div>
                </div>

                <div className='mb-2 d-flex align-items-center'>
                  <label className='me-3'>(4) Attendance via QR Code:</label>
                  <div className='mt-1'>
                    <label>
                      <input
                        className='me-1'
                        type='radio'
                        name='attendance'
                        value='1'
                        onChange={(e) =>
                          handleEventDataChange('attendance', e.target.value)
                        }
                        checked={eventData.attendance === '1'}
                      />
                      Yes
                    </label>
                    <label className='ms-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='attendance'
                        value='0'
                        onChange={(e) =>
                          handleEventDataChange('attendance', e.target.value)
                        }
                        checked={eventData.attendance === '0'}
                      />
                      No
                    </label>
                    {errors.attendance && (
                      <span className='text-danger ms-3'>
                        {errors.attendance}
                      </span>
                    )}
                  </div>
                </div>
                {eventData.attendance === '1' && (
                  <div className='ms-4'>
                    <div className='mb-1'>Please enter the details:</div>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.attendanceCode ? 'is-invalid' : ''
                      }`}
                      name='attendanceCode'
                      placeholder='Event Code (E.g. BCKVEDM24)'
                      value={eventData.attendanceCode}
                      onChange={(e) =>
                        handleEventDataChange('attendanceCode', e.target.value)
                      }
                    />
                    {errors.attendanceCode && (
                      <div className='invalid-feedback mt-0'>
                        {errors.attendanceCode}
                      </div>
                    )}
                    <input
                      type='text'
                      className={`form-control ${
                        errors.attendanceDesc ? 'is-invalid' : ''
                      }`}
                      name='attendanceDesc'
                      placeholder='Event Short Description (E.g. Blending Conference in Klang Valley)'
                      value={eventData.attendanceDesc}
                      onChange={(e) =>
                        handleEventDataChange('attendanceDesc', e.target.value)
                      }
                    />
                    {errors.attendanceDesc && (
                      <div className='invalid-feedback mt-0'>
                        {errors.attendanceDesc}
                      </div>
                    )}
                  </div>
                )}

                <div className='mb-1 d-flex align-items-center'>
                  <label className='me-3'>(5) International: </label>
                  <div className='mt-1'>
                    <label>
                      <input
                        className='me-1'
                        type='radio'
                        name='intlEvent'
                        value='1'
                        onChange={(e) =>
                          handleEventDataChange('intlEvent', e.target.value)
                        }
                        checked={eventData.intlEvent === '1'}
                      />
                      Yes
                    </label>
                    <label className='ms-3'>
                      <input
                        className='me-1'
                        type='radio'
                        name='intlEvent'
                        value='0'
                        onChange={(e) =>
                          handleEventDataChange('intlEvent', e.target.value)
                        }
                        checked={eventData.intlEvent === '0'}
                      />
                      No
                    </label>
                    {errors.intlEvent && (
                      <span className='text-danger ms-3'>
                        {errors.intlEvent}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Packages Section */}
            {eventData.regType1 === '1' && (
              <div className='mb-2'>
                <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                  Packages
                </div>
                {packages.map((pkg, index) => (
                  <div key={index} className='mb-3 mx-2 rounded'>
                    <div
                      className='mb-1'
                      style={{
                        ...(index > 0 && {
                          borderTop: '1px solid #3c57a8',
                          paddingTop: '1rem',
                        }),
                        fontStyle: 'italic',
                        color: '#00a684',
                      }}
                    >
                      Package {index + 1}
                    </div>

                    {/* Select One Section */}
                    <div className='d-flex align-items-center mx-3'>
                      <label className='me-3'>Select One: </label>
                      <label className='me-3'>
                        <input
                          type='radio'
                          name={`packageType-${index}`}
                          className={`me-1 ${
                            errors[`packageType-${index}`] ? 'is-invalid' : ''
                          }`}
                          value='1'
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageType',
                              e.target.value
                            )
                          }
                          checked={pkg.packageType === '1'}
                        />
                        Stay-In
                      </label>

                      <label className='me-3'>
                        <input
                          type='radio'
                          name={`packageType-${index}`}
                          className={`me-1 ${
                            errors[`packageType-${index}`] ? 'is-invalid' : ''
                          }`}
                          value='2'
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageType',
                              e.target.value
                            )
                          }
                          checked={pkg.packageType === '2'}
                        />
                        Non-Stay-In (With Meal)
                      </label>

                      <label className='me-3'>
                        <input
                          type='radio'
                          name={`packageType-${index}`}
                          className={`me-1 ${
                            errors[`packageType-${index}`] ? 'is-invalid' : ''
                          }`}
                          value='0'
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageType',
                              e.target.value
                            )
                          }
                          checked={pkg.packageType === '0'}
                        />
                        Register Only
                      </label>

                      {errors[`packageType-${index}`] && (
                        <div className='text-danger'>
                          {errors[`packageType-${index}`]}
                        </div>
                      )}

                      <div className='separator'></div>

                      <label className='me-3'>
                        <input
                          type='checkbox'
                          name={`children-${index}`}
                          className='me-1'
                          value='1'
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'children',
                              e.target.checked ? '1' : '0'
                            )
                          }
                          checked={pkg.children === '1'}
                        />
                        Children
                      </label>

                      {eventData.venue === '2' && pkg.packageType === '1' && (
                        <label className='me-3'>
                          <input
                            type='checkbox'
                            name={`bedOptionChecked-${index}`}
                            className='me-1'
                            checked={pkg.bedOption.length > 0}
                            onChange={(e) =>
                              handlePackageChange(
                                index,
                                'bedOptionChecked',
                                e.target.checked
                              )
                            }
                          />
                          Bed Option
                        </label>
                      )}

                      {eventData.intlEvent === '1' && (
                        <label className='me-3'>
                          <input
                            type='checkbox'
                            name={`spouseInfo-${index}`}
                            className='me-1'
                            value='1'
                            onChange={(e) =>
                              handlePackageChange(
                                index,
                                'spouseInfo',
                                e.target.checked ? '1' : '0'
                              )
                            }
                            checked={pkg.spouseInfo === '1'}
                          />
                          Spouse Info (For Itero Only)
                        </label>
                      )}
                    </div>

                    {/* Package Details */}
                    <div className='row mx-2 mt-2'>
                      <div className='col-md-3'>
                        <label className='form-label'>
                          Package Name (Eng.)
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors[`packageNmEn-${index}`] ? 'is-invalid' : ''
                          }`}
                          value={pkg.packageNmEn}
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageNmEn',
                              e.target.value
                            )
                          }
                        />
                        {errors[`packageNmEn-${index}`] && (
                          <div className='invalid-feedback'>
                            {errors[`packageNmEn-${index}`]}
                          </div>
                        )}
                      </div>
                      <div className='col-md-3'>
                        <label className='form-label'>
                          Package Name (Chi.)
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors[`packageNmZh-${index}`] ? 'is-invalid' : ''
                          }`}
                          value={pkg.packageNmZh}
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageNmZh',
                              e.target.value
                            )
                          }
                        />
                        {errors[`packageNmZh-${index}`] && (
                          <div className='invalid-feedback'>
                            {errors[`packageNmZh-${index}`]}
                          </div>
                        )}
                      </div>
                      <div className='col-md-3'>
                        <label className='form-label'>Package Name (BM)</label>
                        <input
                          type='text'
                          className={`form-control ${
                            errors[`packageNmBm-${index}`] ? 'is-invalid' : ''
                          }`}
                          value={pkg.packageNmBm}
                          onChange={(e) =>
                            handlePackageChange(
                              index,
                              'packageNmBm',
                              e.target.value
                            )
                          }
                        />
                        {errors[`packageNmBm-${index}`] && (
                          <div className='invalid-feedback'>
                            {errors[`packageNmBm-${index}`]}
                          </div>
                        )}
                      </div>
                      <div
                        className={`col-md-${
                          eventData.intlEvent === '1' ? '2' : '3'
                        }`}
                      >
                        <label className='form-label'>Fee (RM)</label>
                        <input
                          type='number'
                          className={`form-control ${
                            errors[`fee-${index}`] ? 'is-invalid' : ''
                          }`}
                          value={pkg.fee}
                          onChange={(e) =>
                            handlePackageChange(index, 'fee', e.target.value)
                          }
                        />
                        {errors[`fee-${index}`] && (
                          <div className='invalid-feedback'>
                            {errors[`fee-${index}`]}
                          </div>
                        )}
                      </div>

                      {eventData.intlEvent === '1' && (
                        <div className='col-md-1'>
                          <label className='form-label'>Fee (USD)</label>
                          <input
                            type='number'
                            className={`form-control ${
                              errors[`feeUsd-${index}`] ? 'is-invalid' : ''
                            }`}
                            value={pkg.feeUsd}
                            onChange={(e) =>
                              handlePackageChange(
                                index,
                                'feeUsd',
                                e.target.value
                              )
                            }
                          />
                          {errors[`feeUsd-${index}`] && (
                            <div className='invalid-feedback'>
                              {errors[`feeUsd-${index}`]}
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className='row mx-2 mt-2'>
                      {pkg.packageType === '1' && (
                        <>
                          {pkg.bedOption.length === 0 && (
                            <div className='col-md-3'>
                              <label className='form-label'>Room Code</label>
                              <input
                                type='text'
                                className={`form-control ${
                                  errors[`roomCode-${index}`]
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={pkg.roomCode}
                                onChange={(e) =>
                                  handlePackageChange(
                                    index,
                                    'roomCode',
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`roomCode-${index}`] && (
                                <div className='invalid-feedback'>
                                  {errors[`roomCode-${index}`]}
                                </div>
                              )}
                            </div>
                          )}

                          <div className='col-md-3'>
                            <label className='form-label'>Room Capacity</label>
                            <input
                              type='number'
                              className={`form-control ${
                                errors[`capacity-${index}`] ? 'is-invalid' : ''
                              }`}
                              value={pkg.capacity}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'capacity',
                                  e.target.value
                                )
                              }
                            />
                            {errors[`capacity-${index}`] && (
                              <div className='invalid-feedback'>
                                {errors[`capacity-${index}`]}
                              </div>
                            )}
                          </div>
                          <div className='col-md-3'>
                            <label className='form-label'>Room Limit</label>
                            <input
                              type='number'
                              className='form-control'
                              value={pkg.limit}
                              onChange={(e) =>
                                handlePackageChange(
                                  index,
                                  'limit',
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </>
                      )}
                      {index > 0 && (
                        <div
                          className={`d-flex align-items-center justify-content-end ${
                            eventData.venue === '2' &&
                            pkg.packageType === '1' &&
                            pkg.bedOption.length > 0
                              ? 'col-md-6'
                              : (eventData.venue === '2' &&
                                  pkg.packageType === '1') ||
                                (eventData.venue === '1' &&
                                  pkg.packageType === '1')
                              ? 'col-md-3'
                              : 'col-md-12'
                          } text-end`}
                        >
                          <button
                            type='button'
                            className='btn btn-danger btn-sm'
                            onClick={() => handleRemovePackage(index)}
                          >
                            <i className='bi bi-dash-lg'></i>
                          </button>
                        </div>
                      )}

                      {pkg.bedOption.length > 0 && pkg.packageType === '1' && (
                        <div className='mt-2'>
                          {pkg.bedOption.map((bed, idx) => (
                            <div className='row mb-2' key={idx}>
                              <div className='col-md-3'>
                                <label className='form-label'>Bed Code</label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={bed.bedCode}
                                  onChange={(e) =>
                                    handlePackageChange(
                                      index,
                                      `bedOption-bedCode-${idx}`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className='col-md-3'>
                                <label className='form-label'>Bed Name</label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={bed.bedName}
                                  onChange={(e) =>
                                    handlePackageChange(
                                      index,
                                      `bedOption-bedName-${idx}`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className='col-md-1 d-flex align-items-end'>
                                <button
                                  type='button'
                                  className='btn btn-warning btn-sm'
                                  onClick={() => {
                                    const updatedPackages = [...packages]
                                    updatedPackages[index].bedOption =
                                      updatedPackages[index].bedOption.filter(
                                        (_, i) => i !== idx
                                      )
                                    setPackages(updatedPackages)
                                  }}
                                >
                                  <i className='bi bi-dash-lg'></i>
                                </button>
                              </div>
                            </div>
                          ))}

                          <div className='row'>
                            <div className='col-md-3'>
                              <button
                                type='button'
                                className='btn btn-info btn-sm mt-2'
                                onClick={() => {
                                  const updatedPackages = [...packages]
                                  updatedPackages[index].bedOption.push({
                                    bedCode: '',
                                    bedName: '',
                                  })
                                  setPackages(updatedPackages)
                                }}
                              >
                                <i className='bi bi-plus-lg'></i> Add Bed Option
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='d-flex justify-content-end mt-3'>
                      {index === packages.length - 1 && (
                        <button
                          type='button'
                          className='btn btn-success btn-sm'
                          onClick={handleAddPackage}
                        >
                          <i className='bi bi-plus-lg'></i>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Payment Section */}
            <div className='mb-4'>
              <div className='fw-bold mb-2' style={{ color: '#3c57a8' }}>
                Bank Account
              </div>
              <div className='mx-3'>
                {banks.length > 0 ? (
                  <div className='d-flex flex-wrap'>
                    {banks.map((bank) => (
                      <div
                        key={bank._id}
                        className='form-check me-3 d-flex align-items-center'
                      >
                        <input
                          type='radio'
                          id={`bank-${bank._id}`}
                          name='paymentAcc'
                          value={bank._id}
                          className='form-check-input me-2'
                          onChange={() => setSelectedBank(bank._id)}
                          checked={selectedBank === bank._id}
                        />
                        <label
                          htmlFor={`bank-${bank._id}`}
                          className='form-check-label'
                        >
                          {bank.accName} ({bank.accNo})
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className='text-muted'>No banks available</p>
                )}
              </div>
            </div>

            <div className='text-end'>
              <button type='submit' className='btn btn-primary btn-sm'>
                Submit
              </button>
              <button
                type='button'
                className='btn btn-secondary ms-3 btn-sm'
                onClick={handleReturn}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default AdminNewEvent
