import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import LoadingSpinner from '../shared/LoadingSpinner'

export default function ChurchRoute() {
  const [auth] = useAuth()

  if (!auth.isRehydrated) {
    return <LoadingSpinner message='Verifying access...' />
  }
  if (!auth.user) {
    return <Navigate to='/login' replace />
  }
  if (auth.access !== 'church') {
    return <Navigate to='/' replace />
  }
  return <Outlet />
}
