import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../components/shared/LoadingSpinner'

const IntlNewTransport = ({ isModalOpen, onCancel, onAddSuccess }) => {
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [participants, setParticipants] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [arrivalDate, setArrivalDate] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [arrivalFlightNoBus, setArrivalFlightNoBus] = useState('')
  const [departureDate, setDepartureDate] = useState('')
  const [departureTime, setDepartureTime] = useState('')
  const [departureFlightNoBus, setDepartureFlightNoBus] = useState('')
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (isModalOpen) {
      fetchParticipants()
      resetForm()
    }
  }, [isModalOpen])

  const fetchParticipants = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/api/v1/intl/transport/users')
      const options = response.data.map((participant) => ({
        value: participant.regId,
        label: participant.name,
        name: participant.name,
        payStatus: participant.payStatus,
        gender: participant.gender,
        email: participant.email,
        phoneNo: participant.phoneNo,
        spouse: participant.spouse,
      }))
      setParticipants(options)
    } catch (error) {
      console.error('Error fetching participants:', error)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    setSelectedParticipants([])
    setArrivalDate('')
    setArrivalTime('')
    setArrivalFlightNoBus('')
    setDepartureDate('')
    setDepartureTime('')
    setDepartureFlightNoBus('')
    setRemark('')
  }

  const validateDates = () => {
    if (arrivalDate) {
      const arrivalYear = new Date(arrivalDate).getFullYear().toString()
      if (arrivalYear !== '2025') {
        toast.error('Arrival year must be 2025!')
        return false
      }
    }

    if (departureDate) {
      const departureYear = new Date(departureDate).getFullYear().toString()
      if (departureYear !== '2025') {
        toast.error('Departure year must be 2025!')
        return false
      }
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedParticipants.length === 0) {
      toast.error('Please select at least one participant.')
      return
    }
    if (!validateDates()) {
      return
    }
    setSubmitting(true)
    try {
      const response = await axios.post('/api/v1/intl/transport/add', {
        participants: selectedParticipants.map((p) => ({
          regId: p.value,
          name: p.name,
          email: p.email || '',
          phoneNo: p.phoneNo || '',
          gender: p.gender,
          spouse: p.spouse || '',
        })),
        arrivalDate,
        arrivalTime,
        arrivalFlightNoBus,
        departureDate,
        departureTime,
        departureFlightNoBus,
        remark,
      })

      if (response.data.success) {
        toast.success('Submit Successfully')
        resetForm()
        onAddSuccess(response.data.data)
        onCancel()
      } else {
        toast.error(response.data.message || 'Failed to add transport records')
      }
    } catch (error) {
      console.error('Error adding transport records:', error)
      toast.error(
        error.response?.data?.message || 'Failed to add transport records'
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className='container'>
      <h5 className='text-center mb-3'>Add Transportation</h5>
      <form onSubmit={handleSubmit}>
        <div className='mb-3'>
          <label className='form-label' style={{ fontSize: '16px' }}>
            Select the participant from the drop-down list{' '}
            <div style={{ color: '#3c57a8' }}>
              <i className='bi bi-info-circle-fill'></i> Can select multiple
              participants with same arrival and departure
            </div>
          </label>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Select
              isMulti
              isSearchable
              options={participants}
              value={selectedParticipants}
              onChange={setSelectedParticipants}
              getOptionLabel={(e) => (
                <div>
                  <span
                    style={{
                      textTransform: 'uppercase',
                      color: e.gender === 1 ? '#2f4483' : '#78003c',
                    }}
                  >
                    {e.label}{' '}
                  </span>
                  {e.payStatus === 2 ? '(Pending Payment)' : ''}
                </div>
              )}
              isOptionDisabled={(option) => option.payStatus === 2}
              styles={{
                option: (provided, { isDisabled }) => ({
                  ...provided,
                  color: isDisabled ? '#555' : '#000',
                  backgroundColor: isDisabled ? '#f8f9fa' : 'white',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }),
              }}
            />
          )}
        </div>

        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Arrival</h6>
          <div className='d-flex justify-content-evenly'>
            <div className='form-group'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                className='form-control'
                value={arrivalDate}
                onChange={(e) => setArrivalDate(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                className='form-control'
                value={arrivalTime}
                onChange={(e) => setArrivalTime(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Flight No.</label>
              <input
                type='text'
                className='form-control'
                value={arrivalFlightNoBus}
                onChange={(e) => setArrivalFlightNoBus(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Departure Section */}
        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Departure</h6>
          <div className='d-flex justify-content-evenly'>
            <div className='form-group'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                className='form-control'
                value={departureDate}
                onChange={(e) => setDepartureDate(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                className='form-control'
                value={departureTime}
                onChange={(e) => setDepartureTime(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Flight No.</label>
              <input
                type='text'
                className='form-control'
                value={departureFlightNoBus}
                onChange={(e) => setDepartureFlightNoBus(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <label className='form-label'>Remark</label>
          <textarea
            className='form-control'
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            rows='2'
          ></textarea>
        </div>

        <div className='text-end'>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
            disabled={submitting}
          >
            {submitting ? (
              <>
                <span className='spinner-border spinner-border-sm me-2'></span>
              </>
            ) : (
              'Submit'
            )}
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary ms-4 btn-sm'
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default IntlNewTransport
