import React, { useState } from 'react'
import { Button, Form, Input, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminNewAnnounce = ({ closeLogModal, onAddAnnouncement }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getTodayDate = () => {
    const now = new Date()
    now.setHours(now.getHours() + 8)
    return now.toISOString().split('T')[0]
  }

  const handleSubmit = async (values) => {
    setIsSubmitting(true)

    const formPayload = new FormData()
    formPayload.append('announceTitleEn', values.announceTitleEn)
    formPayload.append('announceTitleZh', values.announceTitleZh)
    formPayload.append('announceTitleBm', values.announceTitleBm)
    formPayload.append('announceDate', values.announceDate)
    if (values.files) {
      values.files.fileList.forEach((file) => {
        formPayload.append('files', file.originFileObj)
      })
    }
    formPayload.append('status', 0)

    try {
      const response = await axios.post(
        '/api/v1/admin/announce/add',
        formPayload,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      if (response.data?.newAnnouncement) {
        toast.success('Added successfully!')
        onAddAnnouncement(response.data.newAnnouncement)
        form.resetFields()
        closeLogModal()
      } else {
        toast.error('Failed to add the announcement')
      }
    } catch (error) {
      console.error('Error adding announcement:', error)
      toast.error('Failed to add the announcement')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
      initialValues={{
        announceDate: getTodayDate(),
      }}
    >
      <Form.Item
        label='Title (English)'
        name='announceTitleEn'
        rules={[{ required: true, message: 'English title Required!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Title (Chinese)'
        name='announceTitleZh'
        rules={[
          {
            required: true,
            message: 'Chinese title Required!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Title (BM)'
        name='announceTitleBm'
        rules={[{ required: true, message: 'BM title Required!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Date'
        name='announceDate'
        rules={[{ required: true, message: 'Please select the date!' }]}
      >
        <Input type='date' />
      </Form.Item>
      <Form.Item label='Files' name='files'>
        <Upload multiple beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item className='mb-0'>
        <div className='d-flex justify-content-end'>
          <Button
            className='me-3'
            type='primary'
            htmlType='submit'
            loading={isSubmitting}
          >
            Submit
          </Button>
          <Button onClick={closeLogModal} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default AdminNewAnnounce
