import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../context/auth'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import NavigationBar from '../../components/Layout/NavigationBar'

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [auth, setAuth] = useAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const [activeTab, setActiveTab] = useState('personal')
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (auth?.user) {
      const redirectPath = auth.access === 'admin' ? '/admin/dashboard' : '/'
      navigate(redirectPath)
    }

    if (location.state?.loggedOut) {
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [auth, location, navigate])

  const validateForm = () => {
    let newErrors = {}

    if (activeTab === 'personal' && !emailOrPhone.trim()) {
      newErrors.emailOrPhone = 'Email or Phone Number cannot be empty'
    }
    if (activeTab === 'church' && !username.trim()) {
      newErrors.username = 'Username cannot be empty'
    }
    if (!password.trim()) {
      newErrors.password = 'Password cannot be empty'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateForm()) return

    setIsLoading(true)
    try {
      const credentials =
        activeTab === 'personal'
          ? { activeTab, emailOrPhone, password }
          : { activeTab, username, password }

      const res = await axios.post('/api/v1/auth/login', credentials, {
        withCredentials: true,
      })
      if (res.data.success) {
        setAuth({
          user: res.data.user,
          access: res.data.access,
          isRehydrated: true,
        })
        let redirectPath = '/'
        if (res.data.access === 'admin') {
          redirectPath = '/admin/dashboard'
        }
        navigate(redirectPath)
      } else {
        setErrorMsg('Failed to login')
      }
    } catch (error) {
      setErrorMsg(error.response?.data.message || 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value)
    setErrors((prev) => ({ ...prev, [field]: '' }))
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    setEmailOrPhone('')
    setUsername('')
    setPassword('')
    setErrors({})
    setErrorMsg('')
  }

  return (
    <div>
      <NavigationBar />
      <div className='d-flex align-items-center justify-content-center mt-5'>
        <div className='w-100' style={{ maxWidth: '450px' }}>
          <div className='card shadow-lg'>
            <div
              className='text-white text-center py-2'
              style={{ backgroundColor: '#87734b' }}
            >
              <div className='fs-5'>Login</div>
            </div>
            <div className='pb-4'>
              <ul className='nav nav-tabs mb-4'>
                <li className='nav-item w-50'>
                  <button
                    className={`nav-link w-100 fw-bold ${
                      activeTab === 'personal'
                        ? 'active text-primary'
                        : 'text-secondary'
                    }`}
                    onClick={() => handleTabChange('personal')}
                  >
                    Personal Account
                  </button>
                </li>
                <li className='nav-item w-50'>
                  <button
                    className={`nav-link w-100 fw-bold ${
                      activeTab === 'church'
                        ? 'active text-primary'
                        : 'text-secondary'
                    }`}
                    onClick={() => handleTabChange('church')}
                  >
                    Church Account
                  </button>
                </li>
              </ul>

              {/* Login Form */}
              <div className='px-4'>
                <form onSubmit={handleSubmit}>
                  {activeTab === 'personal' && (
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='emailOrPhone'>
                        Email or Phone Number
                      </label>
                      <input
                        id='emailOrPhone'
                        autoFocus
                        value={emailOrPhone}
                        onChange={handleInputChange(
                          setEmailOrPhone,
                          'emailOrPhone'
                        )}
                        type='text'
                        className={`form-control ${
                          errors.emailOrPhone ? 'border-danger' : ''
                        }`}
                        name='emailOrPhone'
                      />
                      {errors.emailOrPhone && (
                        <small className='text-danger'>
                          {errors.emailOrPhone}
                        </small>
                      )}
                    </div>
                  )}

                  {activeTab === 'church' && (
                    <div className='mb-3'>
                      <label className='mb-2 text-muted' htmlFor='username'>
                        Username
                      </label>
                      <input
                        id='username'
                        value={username}
                        onChange={handleInputChange(setUsername, 'username')}
                        type='text'
                        className={`form-control ${
                          errors.username ? 'border-danger' : ''
                        }`}
                        name='username'
                      />
                      {errors.username && (
                        <small className='text-danger'>{errors.username}</small>
                      )}
                    </div>
                  )}

                  <div className='mb-3'>
                    <label className='mb-2 text-muted' htmlFor='password'>
                      Password
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleInputChange(setPassword, 'password')}
                        className={`form-control ${
                          errors.password ? 'border-danger' : ''
                        }`}
                        name='password'
                      />
                      <span
                        className='input-group-text'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`bi ${
                            showPassword ? 'bi-unlock' : 'bi-lock'
                          }`}
                        ></i>
                      </span>
                    </div>
                    {errors.password && (
                      <small className='text-danger'>{errors.password}</small>
                    )}
                  </div>

                  {errorMsg && (
                    <div className='mb-3 text-center text-danger fw-bold'>
                      {errorMsg}
                    </div>
                  )}

                  <div className='d-flex align-items-center'>
                    <button
                      type='submit'
                      className='btn w-100 text-white'
                      style={{
                        backgroundColor: '#937d52',
                        borderColor: '#937d52',
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span
                          className='spinner-border spinner-border-sm'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
              </div>

              <div className='text-center mt-3'>
                <Link
                  to='/reset-password-request'
                  style={{ textDecoration: 'none' }}
                >
                  Forgot Your Password?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
