import React, { useState } from 'react'
import { Modal, Input, Button } from 'antd'
import { toast } from 'react-toastify'
import axios from 'axios'

const LocalityChangeRequest = ({
  visible,
  onClose,
  userPhone,
  userEmail,
  resetForm,
}) => {
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.')
    } else {
      setEmailError('')
    }
  }

  const handleSubmit = async () => {
    if (!email) {
      toast.error('Please enter your email.')
      return
    }

    if (emailError) {
      toast.error(emailError)
      return
    }

    setIsSubmitting(true)
    try {
      const response = await axios.post(
        '/api/v1/church/event/register/request-locality-change',
        { email, userPhone, userEmail }
      )

      if (response.status === 200) {
        toast.success('Request submitted successfully!')
        setEmail('')
        resetForm()
        onClose()
      }
    } catch (error) {
      toast.error('Failed to submit request. Please try again.')
    }

    setIsSubmitting(false)
  }

  return (
    <Modal
      title={
        <div style={{ textAlign: 'center', width: '100%' }}>
          Request Locality Change
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type='primary'
            onClick={handleSubmit}
            loading={isSubmitting}
            className='me-3'
            disabled={emailError}
          >
            Submit
          </Button>
          <Button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      }
    >
      <label>
        <strong>Please enter your email to receive an update from us.</strong>
      </label>
      <Input
        type='email'
        placeholder='Enter your email'
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          validateEmail(e.target.value)
        }}
        style={{ marginTop: 8 }}
      />
      {emailError && (
        <div style={{ color: 'red', marginTop: 5 }}>{emailError}</div>
      )}
    </Modal>
  )
}

export default LocalityChangeRequest
