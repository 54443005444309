import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/Layout/Layout'
import AdminNewBank from './NewBank'
import AdminEditBank from './EditBank'
import axios from 'axios'

const AdminBankList = () => {
  const [showAddBankModal, setShowAddBankModal] = useState(false)
  const [showEditBankModal, setShowEditBankModal] = useState(false)
  const [banks, setBanks] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpenAddBankModal = () => setShowAddBankModal(true)
  const handleCloseAddBankModal = () => setShowAddBankModal(false)

  const handleOpenEditBankModal = (bank) => {
    setSelectedBank(bank)
    setShowEditBankModal(true)
  }

  const handleCloseEditBankModal = () => {
    setSelectedBank(null)
    setShowEditBankModal(false)
  }

  const handleAddBank = (newBank) => {
    setBanks((prevBanks) => [newBank, ...prevBanks])
  }

  const handleEditBank = (updatedBank) => {
    setBanks((prevBanks) =>
      prevBanks.map((bank) =>
        bank._id === updatedBank._id ? updatedBank : bank
      )
    )
  }

  useEffect(() => {
    const fetchBanks = async () => {
      setLoading(true)
      try {
        const response = await axios.get('/api/v1/admin/payment/bank/list')
        setBanks(response.data.banks)
      } catch (error) {
        console.error('Error fetching banks:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchBanks()
  }, [])

  return (
    <Layout title={'Bank Account'}>
      <div className='p-3'>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-success btn-sm'
            onClick={handleOpenAddBankModal}
          >
            <i className='bi bi-plus-circle'></i> Bank
          </button>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Acc. Name</th>
                <th scope='col'>Acc. Number</th>
                <th scope='col'>Bank</th>
                <th scope='col'>Swift Code</th>
                <th scope='col'>Branch</th>
                <th scope='col'>QR</th>
                <th scope='col'>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {banks.map((bank) => (
                <tr key={bank._id}>
                  <td>{bank.accName}</td>
                  <td>{bank.accNo}</td>
                  <td>{bank.bankName}</td>
                  <td>{bank.swiftCode}</td>
                  <td>{bank.branch}</td>
                  <td>
                    {bank.qrCode && (
                      <a
                        href={`${process.env.REACT_APP_BACKEND_URL}/files/bank/${bank.qrCode}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i
                          className='bi bi-qr-code'
                          style={{ color: '#4da3ff' }}
                        ></i>
                      </a>
                    )}
                  </td>

                  <td style={{ color: bank.status === 1 ? 'green' : 'grey' }}>
                    {bank.status === 1 ? 'Active' : 'Inactive'}
                  </td>
                  <td className='text-end'>
                    <i
                      className='bi bi-pencil-square'
                      style={{ cursor: 'pointer', color: '#4da3ff' }}
                      onClick={() => handleOpenEditBankModal(bank)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>{loading && <p>Loading...</p>}</div>

        <AdminNewBank
          show={showAddBankModal}
          onClose={handleCloseAddBankModal}
          onAddBank={handleAddBank}
        />

        {selectedBank && (
          <AdminEditBank
            show={showEditBankModal}
            onClose={handleCloseEditBankModal}
            bank={selectedBank}
            onEditBank={handleEditBank}
          />
        )}
      </div>
    </Layout>
  )
}

export default AdminBankList
