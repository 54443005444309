import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Navbar, Nav } from 'react-bootstrap'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import NavigationBar from '../../components/Layout/NavigationBar'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [isValidToken, setIsValidToken] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()

  // Verify token on page load
  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setMessage('No reset token provided')
        setIsValidToken(false)
        setIsSuccess(false)
        return
      }

      try {
        const response = await axios.get(
          `/api/v1/auth/verify-reset-token?token=${token}`
        )
        if (response.data.valid) {
          setIsValidToken(true)
        } else {
          setMessage('Invalid or expired token')
          setIsValidToken(false)
          setIsSuccess(false)
        }
      } catch (error) {
        setMessage(error.response?.data?.message || 'Error verifying token')
        setIsValidToken(false)
        setIsSuccess(false)
      }
    }

    verifyToken()
  }, [token])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (newPassword.length < 6) {
      setMessage('Password must be at least 6 characters long')
      setIsSuccess(false)
      return
    }

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match')
      setIsSuccess(false)
      return
    }

    setIsSubmitting(true)
    setMessage('')
    setIsSuccess(null)
    try {
      const response = await axios.post('/api/v1/auth/reset-password', {
        token,
        newPassword,
      })
      setMessage(response.data.message)
      setIsSuccess(true)

      setTimeout(() => {
        navigate('/login')
      }, 1000)
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred')
      setIsSuccess(false)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isValidToken === false) {
    return (
      <div className='container mt-5'>
        <div
          className={`mb-3 p-2 border rounded text-center border-danger text-danger bg-danger-subtle`}
        >
          {message}
        </div>
        <div className='text-center'>
          <Link to='/login' className='btn btn-secondary btn-sm'>
            Return to Login Page
          </Link>
        </div>
      </div>
    )
  }

  if (isValidToken === null) {
    return (
      <div className='container mt-5'>
        <div className='mb-3'>Verifying token...</div>
        <div className='text-center'>
          <Link to='/login' className='btn btn-secondary btn-sm'>
            Return to Login Page
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <NavigationBar />
      <div className='d-flex align-items-center justify-content-center mt-5'>
        <div className='w-100' style={{ maxWidth: '550px' }}>
          {message && (
            <div
              className={`mb-2 p-2 border rounded text-center ${
                isSuccess === true
                  ? 'border-success text-success bg-success-subtle'
                  : isSuccess === false
                  ? 'border-danger text-danger bg-danger-subtle'
                  : ''
              }`}
            >
              {message}
              {isSuccess === true && (
                <div className='mt-2'>Redirecting to Login Page...</div>
              )}
            </div>
          )}

          <div className='card shadow-lg'>
            <div
              className='text-white text-center py-2'
              style={{ backgroundColor: '#87734b' }}
            >
              <div className='fs-5'>Set New Password</div>
            </div>
            <div className='p-3'>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='newPassword' className='form-label'>
                    New Password
                  </label>
                  <div className='d-flex align-items-center'>
                    <input
                      id='newPassword'
                      type={showPassword ? 'text' : 'password'}
                      className='form-control'
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      minLength={6}
                    />
                    <span
                      className='input-group-text'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`bi ${
                          showPassword ? 'bi-unlock' : 'bi-lock'
                        }`}
                      ></i>
                    </span>
                  </div>
                </div>

                <div className='mb-3'>
                  <label htmlFor='confirmPassword' className='form-label'>
                    Confirm New Password
                  </label>
                  <div className='d-flex align-items-center'>
                    <input
                      id='confirmPassword'
                      type={showPassword ? 'text' : 'password'}
                      className='form-control'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      minLength={6}
                    />
                    <span
                      className='input-group-text'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`bi ${
                          showPassword ? 'bi-unlock' : 'bi-lock'
                        }`}
                      ></i>
                    </span>
                  </div>
                </div>

                <div className='text-end'>
                  <Link to='/login' className='btn btn-secondary btn-sm me-4'>
                    Return to Login Page
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Resetting...' : 'Reset Password'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
