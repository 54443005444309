import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useAuth } from '../../../context/auth'
import { toast } from 'react-toastify'
import IntlRegisterForm from './RegisterForm'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
// import { isValidPhoneNumber } from 'libphonenumber-js'

const IntlRegisterEdit = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const [formData, setFormData] = useState(null)
  const [stayPackages, setStayPackages] = useState([])
  const [originalFee, setOriginalFee] = useState(null)
  const [registrationStatus, setRegistrationStatus] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})

  const [originalPackageId, setOriginalPackageId] = useState(null)
  const [originalPackageCapacity, setOriginalPackageCapacity] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const res = await axios.get('/api/v1/intl/form', {
          withCredentials: true,
        })
        setStayPackages(res.data)
      } catch (err) {
        toast.error('Failed to load stay packages')
      }
    }
    fetchPackages()
  }, [])

  useEffect(() => {
    const fetchExisting = async () => {
      try {
        const res = await axios.get('/api/v1/intl/individual/form/check', {
          withCredentials: true,
        })

        const data = res.data.data
        setOriginalPackageId(data.packageId)
        setOriginalPackageCapacity(data.packageCapacity)

        if (!data) {
          navigate('/')
          return
        }
        setFormData({
          name: data.name || '',
          email: data.email || '',
          icNo: data.icNo || '',
          age: data.age || '',
          phoneCode: {
            value: data.phoneCode,
            label: data.phoneCode,
          },
          phoneNo: data.phoneNo || '',
          package: data.packageId || '',
          feeUsd: data.feeUsd ? parseFloat(data.feeUsd).toFixed(2) : '',
          remark: data.remark || '',
          mealPackage: data.mealPackage || '',
          meeting: data.meeting || false,
          spouse: data.spouseInfo || null,
          upgradeFee: data.upgradeFee || 0,
          oriPackageFee: data.oriPackageFee,
          status: data.status,
        })

        setOriginalFee(parseFloat(data.feeUsd))
        setRegistrationStatus(data.status)
      } catch (err) {
        toast.error('Failed to load registration data')
        navigate('/')
      } finally {
        setLoading(false)
      }
    }

    if (auth?.user?._id) {
      fetchExisting()
    }
  }, [auth])

  const validateForm = () => {
    const newErrors = {}

    if (!formData.icNo?.trim()) newErrors.icNo = 'Required!'
    if (!formData.age?.toString().trim()) newErrors.age = 'Required!'
    else if (!/^\d{2}$/.test(formData.age.toString().trim()))
      newErrors.age = 'Must be 2 digits!'

    if (!formData.phoneCode) newErrors.phoneCode = 'Required!'
    if (!formData.phoneNo?.trim()) newErrors.phoneNo = 'Required!'
    if (!formData.package) newErrors.package = 'Required!'

    if (formData.spouse) {
      const spouseErrors = {}
      if (!formData.spouse.name?.trim()) spouseErrors.name = 'Required!'
      if (!formData.spouse.email?.trim()) spouseErrors.email = 'Required!'
      if (!formData.spouse.icNo?.trim()) spouseErrors.icNo = 'Required!'
      if (!formData.spouse.age?.toString().trim())
        spouseErrors.age = 'Required!'
      else if (!/^\d{2}$/.test(formData.spouse.age.toString().trim()))
        spouseErrors.age = 'Must be 2 digits!'

      if (!formData.spouse.phoneCode) spouseErrors.phoneCode = 'Required!'
      if (!formData.spouse.phoneNo?.trim()) spouseErrors.phoneNo = 'Required!'

      if (Object.keys(spouseErrors).length > 0) {
        newErrors.spouse = spouseErrors
      }
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleFieldChange = (field, value) => {
    setFormData((prev) => {
      let updated = { ...prev }

      if (field === 'age') {
        value = value.replace(/\D/g, '').slice(0, 2)
        updated.age = value
      } else if (field === 'package') {
        updated.package = value

        const selectedPkg = stayPackages.find((pkg) => pkg.pkgId === value)
        const selectedFee = selectedPkg ? parseFloat(selectedPkg.feeUsd) : 0

        updated.feeUsd = selectedFee.toFixed(2)

        // Capture original fee if user is registered (status 1) and hasn't upgraded
        if (
          registrationStatus === 1 &&
          !formData.oriPackageFee &&
          selectedFee > originalFee
        ) {
          updated.oriPackageFee = originalFee.toFixed(2)
        }

        if (selectedPkg?.spouseInfo === 1) {
          updated.spouse = updated.spouse || {
            name: '',
            email: '',
            phoneNo: '',
            age: '',
            icNo: '',
            remark: '',
            mealPackage: 1,
            meeting: false,
          }
        } else {
          updated.spouse = null
        }
      } else if (field.startsWith('spouse.')) {
        if (!prev.spouse) return prev
        const spouseField = field.split('.')[1]
        updated.spouse = {
          ...prev.spouse,
          [spouseField]: value,
        }
      } else {
        updated[field] = value
      }

      return updated
    })

    if (value?.toString().trim() !== '') {
      setErrors((prev) => {
        const updatedErrors = { ...prev }

        if (field.startsWith('spouse.')) {
          const spouseField = field.split('.')[1]
          if (updatedErrors.spouse) {
            updatedErrors.spouse = {
              ...updatedErrors.spouse,
              [spouseField]: '',
            }

            if (
              Object.values(updatedErrors.spouse).every(
                (val) => val === '' || val == null
              )
            ) {
              delete updatedErrors.spouse
            }
          }
          // } else if (field === 'phoneNo') {
          //   if (!isValidPhoneNumber(formData.phoneCode.value + value)) {
          //     updatedErrors.phoneNo = 'Possible invalid phone number. Please check'
          //   } else {
          //     updatedErrors.phoneNo = ''
          //   }
        } else {
          updatedErrors[field] = ''
        }

        return updatedErrors
      })
    }
  }

  const handleSubmit = async () => {
    const twinRoomUpgrade =
      registrationStatus === 1 &&
      originalPackageCapacity === 2 &&
      formData.package !== originalPackageId &&
      !formData.spouseInfo

    if (twinRoomUpgrade && !showModal) {
      setShowModal(true)
      return
    }
    if (!twinRoomUpgrade) {
      const confirm = window.confirm(
        'Please confirm: Do you want to update your details?'
      )
      if (!confirm) return
    }

    if (!validateForm()) return

    setIsSubmitting(true)
    try {
      const selectedPkg = stayPackages.find(
        (pkg) => pkg.pkgId === formData.package
      )

      const newFee = parseFloat(selectedPkg?.feeUsd || 0)
      const payload = {
        ...formData,
        packageId: selectedPkg?.pkgId || '',
        fee: newFee,
        packageNmEn: selectedPkg?.packageNmEn || '',
        packageCapacity: selectedPkg?.capacity || '',
        phoneCode: formData.phoneCode?.value || '',
        // phoneNo: formData.phoneNo.replaceAll(' ', '') || '',
      }

      // For paid users upgrading to a higher-priced package,
      // send the upgradeFee and original package details
      if (registrationStatus === 1 && newFee > originalFee) {
        payload.upgradeFee = parseFloat((newFee - originalFee).toFixed(2))
        payload.status = 2
        // Use the stored original package info
        payload.oriPackageFee = formData.oriPackageFee
      }

      await axios.put('/api/v1/intl/individual/form/update', payload, {
        withCredentials: true,
      })

      navigate('/')
    } catch (error) {
      console.error('Update error:', error)
      toast.error('Update failed!')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Layout title={'2025 September ITERO'}>
      <div className='p-3'>
        <div
          className='mb-3 text-uppercase'
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>

          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Edit Lodging & Meal
        </div>

        <div
          className='mb-2'
          style={{ fontWeight: 600, color: '#951a26', fontSize: '17px' }}
        >
          <i className='bi bi-exclamation-circle-fill'></i> Please make payment
          as soon as possible to confirm your room reservation. Once the event
          venue is full, your booking will be directed to the overflowing hotel.
        </div>

        {loading || !formData ? (
          <LoadingSpinner />
        ) : (
          <>
            <IntlRegisterForm
              formData={formData}
              setFormData={setFormData}
              stayPackages={stayPackages}
              handleFieldChange={handleFieldChange}
              originalFee={originalFee}
              registrationStatus={registrationStatus}
              handleSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
              mode='edit'
            />

            {showModal && (
              <div
                className='modal fade show'
                tabIndex='-1'
                role='dialog'
                style={{
                  display: 'block',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                }}
              >
                <div
                  className='modal-dialog modal-dialog-centered'
                  style={{ maxWidth: '600px' }}
                >
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <div
                        className='modal-title text-danger'
                        style={{ fontWeight: '600' }}
                      >
                        Request to upgrade from Twin Sharing to a Single Room,
                        with or without a spouse
                      </div>
                    </div>
                    <div className='modal-body'>
                      <ul
                        style={{
                          listStyleType: 'decimal',
                          paddingLeft: '1.2rem',
                          fontSize: '17px',
                        }}
                      >
                        <li>
                          Your initial room and hotel booking will now be
                          cancelled.
                        </li>
                        <li>
                          Your request for this upgrade is pending your existing
                          roommate securing a new pair.
                        </li>
                        <li>
                          Your new request will be processed when there is the
                          availability of room.
                        </li>
                      </ul>
                    </div>

                    <div className='modal-footer'>
                      <button
                        className='btn btn-warning me-4'
                        onClick={() => {
                          setShowModal(false)
                          handleSubmit()
                        }}
                      >
                        Proceed Anyway
                      </button>
                      <button
                        className='btn btn-secondary'
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default IntlRegisterEdit
