import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { Modal } from 'antd'
import AdminRegistrationTable from './List'
import AdminCoordinatorInfo from './Coordinator'
import AdminLocalityStat from './Locality'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const AdminRegistrationData = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [eventData, setEventData] = useState({
    eventName: '',
    attendance: 0,
    totalParticipants: 0,
    childrenCount: 0,
    adultCount: 0,
    broCount: 0,
    sisCount: 0,
    childBroCount: 0,
    childSisCount: 0,
    languageStats: {
      english: 0,
      chinese: 0,
      bm: 0,
      cEnglish: 0,
      cChinese: 0,
      cBM: 0,
    },
    stayInPackages: [],
    nonStayInPackages: [],
  })
  const [isCoordinatorModalVisible, setIsCoordinatorModalVisible] =
    useState(false)
  const [isLocalityModalVisible, setIsLocalityModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/registration/data/${id}`
        )
        setEventData((prevData) => ({
          ...prevData,
          ...response.data,
          languageStats: {
            ...prevData.languageStats,
            ...response.data.languageStats,
          },
        }))
      } catch (error) {
        console.error('Error fetching event data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      fetchEventData()
    }
  }, [id])

  return (
    <Layout title='Event Registration System'>
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <button
            style={{ whiteSpace: 'nowrap' }}
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={() => navigate('/admin/dashboard')}
          >
            <i className='bi bi-arrow-left-circle'></i> Return
          </button>

          <div className='flex-grow-1 text-center'>
            <h5 className='fw-bold mb-0'>
              {eventData.eventName ? eventData.eventName : <LoadingSpinner />}
            </h5>
          </div>

          <div className='d-flex'>
            <button
              type='button'
              className='btn btn-success btn-sm me-3'
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => navigate(`/admin/registration/new/${id}`)}
            >
              <i className='bi bi-plus-circle'></i> Add Registrant
            </button>
            <button
              type='button'
              className='btn btn-info btn-sm'
              style={{ whiteSpace: 'nowrap' }}
            >
              <i className='bi bi-file-earmark-arrow-down'></i> Export
            </button>
          </div>
        </div>
        <hr />

        <div className='row mt-2 col-md-12'>
          <div className='col-md-3 border-end'>
            <span className='fw-bold'>
              Total Participants: {eventData.totalParticipants}
            </span>
            {eventData.childrenCount > 0 ? (
              <div className='mb-2'>
                <div style={{ fontWeight: '500' }}>
                  <span className='me-2'>Adults:</span>
                  <span className='me-2'>{eventData.adultCount}</span>(
                  <span className='me-2' style={{ color: '#2f4483' }}>
                    Bro: {eventData.broCount}
                  </span>
                  <span style={{ color: '#78003c' }}>
                    Sis: {eventData.sisCount})
                  </span>
                </div>
                <div style={{ fontWeight: '500' }}>
                  <span className='me-2'>Children:</span>
                  <span className='me-2'>{eventData.childrenCount}</span>(
                  <span className='me-2' style={{ color: '#2f4483' }}>
                    Bro: {eventData.childBroCount}
                  </span>
                  <span style={{ color: '#78003c' }}>
                    Sis: {eventData.childSisCount})
                  </span>
                </div>
              </div>
            ) : (
              <div className='mb-2'>
                <div style={{ fontWeight: '500' }}>
                  <span className='me-3' style={{ color: '#2f4483' }}>
                    Bro: {eventData.broCount}
                  </span>
                  <span style={{ color: '#78003c' }}>
                    Sis: {eventData.sisCount}
                  </span>
                </div>
              </div>
            )}
            <div className='fw-bold'>Language</div>
            {eventData.childrenCount > 0 ? (
              <div style={{ fontWeight: '500' }}>
                <span className='me-1'>Adults:</span> Eng:{' '}
                {eventData.languageStats.english}
                <span className='mx-3'>
                  中文: {eventData.languageStats.chinese}
                </span>
                BM: {eventData.languageStats.bm} <br />
                <span className='me-1'>Children:</span> Eng:{' '}
                {eventData.languageStats.cEnglish}
                <span className='mx-3'>
                  中文: {eventData.languageStats.cChinese}
                </span>
                BM: {eventData.languageStats.cBM}
              </div>
            ) : (
              <div style={{ fontWeight: '500' }}>
                Eng: {eventData.languageStats.english}
                <span className='mx-3'>
                  中文: {eventData.languageStats.chinese}
                </span>
                BM: {eventData.languageStats.bm}
              </div>
            )}
          </div>

          <div className='col-md-5 border-end'>
            <div className='fw-bold mb-2'>Registration</div>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {/* Stay-In Packages */}
                {eventData.stayInPackages.length > 0 && (
                  <>
                    <div style={{ fontWeight: '500' }}>Stay-In:</div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {eventData.stayInPackages.map((pkg, index) => (
                        <span
                          key={index}
                          style={{
                            whiteSpace: 'nowrap',
                            marginRight:
                              index !== eventData.stayInPackages.length - 1
                                ? '15px'
                                : '0',
                          }}
                        >
                          {pkg.packageName}: {pkg.count}
                        </span>
                      ))}
                    </div>
                  </>
                )}

                {/* Non-Stay-In Packages */}
                {eventData.nonStayInPackages.length > 0 && (
                  <>
                    <div style={{ fontWeight: '500' }} className='mt-1'>
                      Non-Stay-In:
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {eventData.nonStayInPackages.map((pkg, index) => (
                        <span
                          key={index}
                          style={{
                            whiteSpace: 'nowrap',
                            marginRight:
                              index !== eventData.nonStayInPackages.length - 1
                                ? '15px'
                                : '0',
                          }}
                        >
                          {pkg.packageName}: {pkg.count}
                        </span>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className='col-md-4'>
            <div className='mb-1'>
              <span className='fw-bold me-2'>Payment</span>
              <Link
                to={`/admin/registration/payment/${id}`}
                className='text-decoration-none fw-bold text-primary'
              >
                <i className='bi bi-box-arrow-up-right'></i>
              </Link>
            </div>

            <div className='mb-1 fw-bold'>
              <span className='me-2'>Transportation</span>{' '}
              <Link
                to={`/admin/registration/transport/${id}`}
                className='text-decoration-none fw-bold text-primary'
              >
                <i className='bi bi-box-arrow-up-right ms-2'></i>
              </Link>
            </div>

            <div className='mb-1'>
              <span className='fw-bold me-2'>Locality Stats</span>
              <i
                className='bi bi-box-arrow-up-right ms-2'
                style={{ cursor: 'pointer', color: '#007bff' }}
                onClick={() => setIsLocalityModalVisible(true)}
              ></i>
            </div>

            {eventData.regType2 === 2 && (
              <div className='mb-1'>
                <span className='fw-bold me-2'>Coordinator's Info</span>
                <i
                  className='bi bi-box-arrow-up-right ms-2'
                  style={{ cursor: 'pointer', color: '#007bff' }}
                  onClick={() => setIsCoordinatorModalVisible(true)}
                ></i>
              </div>
            )}
            {/* {eventData.attendance === 1 && (
              <div className='mb-1'>
                <span className='fw-bold me-2'>Locality QR Code</span>
                <i className='bi bi-box-arrow-up-right ms-2'></i>
              </div>
            )} */}
          </div>
        </div>

        <hr />
        <AdminRegistrationTable />

        <Modal
          open={isLocalityModalVisible}
          onCancel={() => setIsLocalityModalVisible(false)}
          footer={null}
          centered
          width={700}
          destroyOnClose
        >
          <AdminLocalityStat
            eventId={id}
            onClose={() => setIsLocalityModalVisible(false)}
          />
        </Modal>

        {/* Coordinator Modal */}
        <Modal
          open={isCoordinatorModalVisible}
          onCancel={() => setIsCoordinatorModalVisible(false)}
          footer={null}
          centered
          width={800}
          destroyOnClose
        >
          <AdminCoordinatorInfo
            eventId={id}
            onClose={() => setIsCoordinatorModalVisible(false)}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default AdminRegistrationData
