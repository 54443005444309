import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Radio } from 'antd'
import axios from 'axios'

const AdminEditLocality = ({ onClose, locality, onLocalityUpdated }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (locality) {
      form.setFieldsValue({
        name: locality.name,
        code: locality.code,
        username: locality.username,
        zone: locality.zone,
        status: locality.status,
        churchPw: locality.churchPw,
        password: '',
      })
    }
  }, [locality, form])

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const updateData = { ...values }
      if (!updateData.password) {
        delete updateData.password
      }

      const response = await axios.put(
        `/api/v1/admin/locality/${locality._id}`,
        updateData
      )
      if (response.status === 200) {
        onLocalityUpdated(response.data)
      }

      setLoading(false)
      onClose()
    } catch (error) {
      console.error('Error updating locality:', error)
      setLoading(false)
    }
  }

  return (
    <>
      <h6 className='mb-3'>Edit Locality</h6>

      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={locality}
      >
        <Form.Item
          label='Locality Name'
          name='name'
          rules={[{ required: true, message: 'Please enter locality name' }]}
        >
          <Input placeholder='Enter locality name' />
        </Form.Item>

        <Form.Item
          label='Locality Code'
          name='code'
          rules={[{ required: true, message: 'Please enter locality code' }]}
        >
          <Input placeholder='Enter locality code' />
        </Form.Item>

        <Form.Item
          label='Username'
          name='username'
          rules={[{ required: true, message: 'Please enter username' }]}
        >
          <Input placeholder='Enter username' />
        </Form.Item>

        <Form.Item
          label='Zone'
          name='zone'
          rules={[{ required: true, message: 'Please select a zone' }]}
        >
          <Radio.Group>
            <Radio value={1}>Peninsular</Radio>
            <Radio value={2}>Sarawak</Radio>
            <Radio value={3}>Sabah</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label='Status' name='status' rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label='Plain Password' name='churchPw'>
          <Input readOnly disabled />
        </Form.Item>

        <Form.Item label='Password' name='password'>
          <Input.Password placeholder='Enter new password (if changing)' />
        </Form.Item>

        <Form.Item className='text-end'>
          <div className='d-flex justify-content-end'>
            <Button type='primary' htmlType='submit' className='me-3'>
              {loading ? 'Updating...' : 'Update'}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default AdminEditLocality
