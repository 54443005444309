import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { Modal } from 'antd'
import AdminOnlineLocalityStat from './Locality'
import AdminOnlineRegistrationTable from './List'

const AdminOnlineRegistrationData = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [eventData, setEventData] = useState({
    eventName: '',
    attendance: 0,
    totalParticipants: 0,
    broCount: 0,
    sisCount: 0,
    languageStats: {
      english: 0,
      chinese: 0,
      bm: 0,
    },
  })
  const [isLocalityModalVisible, setIsLocalityModalVisible] = useState(false)

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/online/registration/data/${id}`
        )
        setEventData((prevData) => ({
          ...prevData,
          ...response.data,
          languageStats: {
            ...prevData.languageStats,
            ...response.data.languageStats,
          },
        }))
      } catch (error) {
        console.error('Error fetching event data:', error)
      }
    }

    if (id) {
      fetchEventData()
    }
  }, [id])

  return (
    <Layout
      title={`Event Registration Records - ${
        eventData.eventName || 'Loading...'
      }`}
    >
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={() => navigate('/admin/dashboard')}
          >
            <i className='bi bi-arrow-left-circle'></i> Return
          </button>

          <div className='flex-grow-1 text-center'>
            <h5 className='fw-bold mb-0'>
              {eventData.eventName || 'Loading event...'}
            </h5>
          </div>

          <div className='d-flex'>
            {/* <button
            type='button'
            className='btn btn-success btn-sm me-3'
            onClick={() => navigate(`/admin/online/registrations/new/${id}`)}
          >
            <i className='bi bi-plus-circle'></i> Add Registrant
          </button> */}
            <button type='button' className='btn btn-info btn-sm'>
              <i className='bi bi-file-earmark-arrow-down'></i> Export
            </button>
          </div>
        </div>
        <hr />

        <div className='row col-md-12'>
          <div className='d-flex align-items-center mt-1'>
            {/* Total Participants & Gender Stats */}
            <div className='border-end pe-3'>
              <span className='fw-bold me-2'>
                Total Participants: {eventData.totalParticipants}
              </span>
              (
              <span
                className='me-2'
                style={{ fontWeight: '600', color: '#2f4483' }}
              >
                Bro: {eventData.broCount}
              </span>
              <span style={{ fontWeight: '600', color: '#78003c' }}>
                Sis: {eventData.sisCount}
              </span>
              )
            </div>

            {/* Language Stats */}
            <div className='border-end px-3'>
              <span className='fw-bold me-2'>Language:</span>
              <span>Eng: {eventData.languageStats.english}</span>
              <span className='mx-2'>
                中文: {eventData.languageStats.chinese}
              </span>
              BM: {eventData.languageStats.bm}
            </div>

            {/* Locality Stats */}
            <div className='border-end px-3'>
              <span className='fw-bold me-2'>Locality Stats</span>
              <i
                className='bi bi-box-arrow-up-right ms-2'
                style={{ cursor: 'pointer', color: '#007bff' }}
                onClick={() => setIsLocalityModalVisible(true)}
              ></i>
            </div>
          </div>
        </div>
        <hr />
        <AdminOnlineRegistrationTable />

        <Modal
          open={isLocalityModalVisible}
          onCancel={() => setIsLocalityModalVisible(false)}
          footer={null}
          centered
          width={700}
          destroyOnClose
        >
          <AdminOnlineLocalityStat
            eventId={id}
            onClose={() => setIsLocalityModalVisible(false)}
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default AdminOnlineRegistrationData
