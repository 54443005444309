import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const AdminOnlineRegistrationTable = () => {
  const { id } = useParams()
  const [registrations, setRegistrations] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const limit = 30

  useEffect(() => {
    fetchRegistrations()
  }, [id, currentPage])

  const fetchRegistrations = async (searchValue = searchTerm) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/admin/online/registration/list/${id}?page=${currentPage}&limit=${limit}&search=${searchValue}`
      )
      setRegistrations(response.data.registrations)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error('Error fetching registration list:', error)
    }
    setLoading(false)
  }

  // Handle input change and trigger search when length >= 4
  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)

    if (value.length >= 4) {
      setCurrentPage(1)
      fetchRegistrations(value)
    }
  }

  // Clear search
  const handleClearSearch = () => {
    setSearchTerm('')
    setCurrentPage(1)
    fetchRegistrations('')
  }

  return (
    <div className='mt-3'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='d-flex align-items-center ms-auto'>
          <div style={{ width: '300px' }}>
            <input
              type='text'
              className='form-control'
              placeholder='Search Name / Email / Phone'
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <button
            className='btn btn-secondary'
            onClick={handleClearSearch}
            disabled={!searchTerm}
          >
            Clear
          </button>
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email/Phone</th>
              <th>Language</th>
              <th>Locality</th>
              <th>Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan='6' className='text-center'>
                  <LoadingSpinner />
                </td>
              </tr>
            ) : registrations.length > 0 ? (
              registrations.map((reg) => (
                <tr key={reg._id}>
                  <td>
                    <div
                      className='fw-bold'
                      style={{
                        textTransform: 'uppercase',
                        color: reg.gender === 1 ? '#2f4483' : '#78003c',
                      }}
                    >
                      {reg.name}
                    </div>
                    {reg.chiName && <div>{reg.chiName}</div>}
                  </td>
                  <td>
                    {reg.email && !reg.email.includes('@empty') && (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <i
                          className='bi bi-envelope-fill me-2'
                          style={{ color: '#7a6844' }}
                        ></i>
                        {reg.email}
                      </div>
                    )}
                    {reg.phoneNo && (
                      <div style={{ whiteSpace: 'nowrap' }}>
                        <i
                          className='bi bi-telephone-fill me-2'
                          style={{ color: '#7a6844' }}
                        ></i>
                        {reg.phoneNo}
                      </div>
                    )}
                  </td>
                  <td>{['', 'English', '中文', 'BM'][reg.language]}</td>
                  <td>{reg.localityName}</td>
                  <td>{reg.userEventCode}</td>
                  <td className='text-end'>
                    <i className='bi bi-pencil-square text-primary'></i>
                    <div>
                      <i
                        className='bi bi-trash3 text-danger ms-3'
                        style={{
                          cursor: reg.status !== 2 ? 'not-allowed' : 'pointer',
                          pointerEvents: reg.status !== 2 ? 'none' : 'auto',
                          opacity: reg.status !== 2 ? 0.5 : 1,
                        }}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='6' className='text-center'>
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <PaginationButtons
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default AdminOnlineRegistrationTable
