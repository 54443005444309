import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminEditBank = ({ show, onClose, bank, onEditBank }) => {
  const [formData, setFormData] = useState({
    accName: bank.accName,
    accNo: bank.accNo,
    bankName: bank.bankName,
    swiftCode: bank.swiftCode,
    branch: bank.branch,
    status: bank.status,
    qrCode: null,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, files } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === 'file' ? files[0] : name === 'status' ? Number(value) : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const data = new FormData()
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) {
          data.append(key, value)
        }
      })

      const response = await axios.put(
        `/api/v1/admin/payment/bank/edit/${bank._id}`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.data?.updatedBank) {
        onEditBank(response.data.updatedBank)
        toast.success('Bank updated successfully!')
        onClose()
      } else {
        toast.error('Failed to update the bank.')
      }
    } catch (error) {
      console.error('Error updating bank:', error)
      toast.error('Failed to update the bank. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>Edit Bank</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='accName'>
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type='text'
              name='accName'
              value={formData.accName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId='accNo' className='mt-3'>
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type='number'
              name='accNo'
              value={formData.accNo}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId='bankName' className='mt-3'>
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type='text'
              name='bankName'
              value={formData.bankName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='swiftCode' className='mt-3'>
            <Form.Label>Swift Code</Form.Label>
            <Form.Control
              type='text'
              name='swiftCode'
              value={formData.swiftCode}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='branch' className='mt-3'>
            <Form.Label>Branch</Form.Label>
            <Form.Control
              type='text'
              name='branch'
              value={formData.branch}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId='qrCode' className='mt-3'>
            <Form.Label>QR Code (optional)</Form.Label>
            <Form.Control
              type='file'
              name='qrCode'
              accept='image/*'
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId='status' className='mt-3'>
            <Form.Label>Status</Form.Label>
            <div className='d-flex align-items-center'>
              <Form.Check
                type='radio'
                id='status-active'
                name='status'
                value={1}
                label='Active'
                checked={formData.status === 1}
                onChange={handleChange}
                inline
              />
              <Form.Check
                type='radio'
                id='status-inactive'
                name='status'
                value={0}
                label='Inactive'
                checked={formData.status === 0}
                onChange={handleChange}
                inline
              />
            </div>
          </Form.Group>

          <div className='mt-4 text-end'>
            <Button
              variant='warning'
              type='submit'
              disabled={isSubmitting}
              className='me-4 btn-sm'
            >
              {isSubmitting ? 'Saving...' : 'Update'}
            </Button>
            <Button variant='secondary' onClick={onClose} className='btn-sm'>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminEditBank
