// import { getCountries, getCountryCallingCode } from "libphonenumber-js"
// import * as isoCountries from 'i18n-iso-countries';
// import en from 'i18n-iso-countries/langs/en';

// isoCountries.registerLocale(en)

// const countryPhoneCodes = getCountries()
//   .filter(country => {
//     return !!isoCountries.getName(country, 'en')
//   })
//   .map(
//   (country) => {
//     let countryName = isoCountries.getName(country, 'en', {select: 'alias'})
//     if (country === 'KR') countryName = 'South Korea'
//     else if (country === 'GB') countryName = 'United Kingdom'
//     else if (country === 'NL') countryName = 'Netherlands'
//     return {
//       label: `+${getCountryCallingCode(country)} (${countryName})`,
//       value: `+${getCountryCallingCode(country)}`,
//     }
//   }
// )

// export default countryPhoneCodes

const countryPhoneCodes = [
  { label: '+1 (USA)', value: '+1' },
  { label: '+7 (Russia)', value: '+7' },
  { label: '+20 (Egypt)', value: '+20' },
  { label: '+27 (South Africa)', value: '+27' },
  { label: '+31 (Netherlands)', value: '+31' },
  { label: '+32 (Belgium)', value: '+32' },
  { label: '+33 (France)', value: '+33' },
  { label: '+34 (Spain)', value: '+34' },
  { label: '+39 (Italy)', value: '+39' },
  { label: '+44 (UK)', value: '+44' },
  { label: '+48 (Poland)', value: '+48' },
  { label: '+49 (Germany)', value: '+49' },
  { label: '+55 (Brazil)', value: '+55' },
  { label: '+61 (Australia)', value: '+61' },
  { label: '+62 (Indonesia)', value: '+62' },
  { label: '+63 (Philippines)', value: '+63' },
  { label: '+64 (New Zealand)', value: '+64' },
  { label: '+65 (Singapore)', value: '+65' },
  { label: '+66 (Thailand)', value: '+66' },
  { label: '+81 (Japan)', value: '+81' },
  { label: '+82 (South Korea)', value: '+82' },
  { label: '+86 (China)', value: '+86' },
  { label: '+90 (Turkey)', value: '+90' },
  { label: '+91 (India)', value: '+91' },
  { label: '+92 (Pakistan)', value: '+92' },
  { label: '+93 (Afghanistan)', value: '+93' },
  { label: '+94 (Sri Lanka)', value: '+94' },
  { label: '+98 (Iran)', value: '+98' },
  { label: '+358 (Finland)', value: '+358' },
  { label: '+880 (Bangladesh)', value: '+880' },
  { label: '+965 (Kuwait)', value: '+965' },
  { label: '+966 (Saudi Arabia)', value: '+966' },
  { label: '+971 (UAE)', value: '+971' },
  { label: '+973 (Bahrain)', value: '+973' },
  { label: '+974 (Qatar)', value: '+974' },
  { label: '+995 (Georgia)', value: '+995' },
]
export default countryPhoneCodes
