import React from 'react'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
// import { formatIncompletePhoneNumber } from 'libphonenumber-js'

const IntlRegisterView = ({ existingData, onPairingCheck, pairingLoading }) => {
  const navigate = useNavigate()

  return (
    <div
      className='bg-white py-3 px-4 rounded shadow-sm border mb-5'
      style={{ maxWidth: '70%', margin: '0 auto' }}
    >
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='text-uppercase fw-bold text-success'>Details</div>
        <div
          className={`badge ${
            existingData.status === 1
              ? 'bg-success'
              : existingData.status === 2
              ? 'bg-danger'
              : existingData.status === 3
              ? 'bg-warning text-dark'
              : 'bg-secondary'
          }`}
          style={{ fontSize: '14px', padding: '6px 12px' }}
        >
          {existingData.status === 1
            ? 'Paid & Confirmed'
            : existingData.status === 2
            ? 'Pending Payment'
            : existingData.status === 3
            ? 'Processing'
            : 'Unknown'}
        </div>
      </div>
      <div className='row g-3'>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Name</label>
          <div>{existingData.name}</div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Email</label>
          <div>{existingData.email}</div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Passport</label>
          <div>{existingData.icNo}</div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Age</label>
          <div>{existingData.age}</div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Phone</label>
          <div>
            ({existingData.phoneCode}) {existingData.phoneNo}
            {/* {formatIncompletePhoneNumber(
              `${existingData.phoneCode}${existingData.phoneNo}`
            )} */}
          </div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Package</label>
          <div>
            {existingData.packageNmEn}{' '}
            {existingData.packageCapacity === 2 &&
              !existingData.spouseInfo &&
              (existingData.completeRoom === 1 && existingData.pairingName ? (
                <span style={{ fontWeight: 600, marginLeft: '10px' }}>
                  (Paired with: {existingData.pairingName})
                </span>
              ) : (
                <Tooltip
                  title={
                    existingData.status !== 1
                      ? 'You can add a roommate once your payment is confirmed.'
                      : 'Add your roommate'
                  }
                >
                  <span
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    {pairingLoading ? (
                      <span className='spinner-border spinner-border-sm'></span>
                    ) : (
                      <i
                        className='bi bi-person-fill-add'
                        style={{
                          color:
                            existingData.status !== 1 ? '#afa48e' : '#7a6844',
                          cursor:
                            existingData.status !== 1
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                        onClick={() => {
                          if (existingData.status === 1) {
                            onPairingCheck(existingData)
                          }
                        }}
                      ></i>
                    )}
                  </span>
                </Tooltip>
              ))}
            {existingData.status === 1 && !existingData.spouseInfo && (
              <div>
                (Accomodation Code:{' '}
                <span style={{ fontWeight: '600' }}>
                  {existingData.confirmCode}
                </span>
                )
              </div>
            )}
          </div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Fee (USD)</label>
          <div>{existingData.feeUsd?.toFixed(2)}</div>
        </div>
        <div className='col-md-6'>
          <label className='fw-semibold text-muted'>Meal Package</label>
          <div>
            {
              {
                1: 'No Preference',
                2: 'Vegan',
                3: 'Gluten Free',
                4: 'Vegan & Gluten Free',
              }[existingData.mealPackage]
            }
          </div>
        </div>

        <div className='col-md-12'>
          <label className='fw-semibold text-muted'>Remarks</label>
          <div>{existingData.remark || '-'}</div>
        </div>
        <div className='col-md-12'>
          <div className='d-flex justify-content-between align-items-end'>
            <div>
              <label className='fw-semibold text-muted d-block'>
                Lord's Day Morning Blending Meeting
              </label>
              <div>{existingData.meeting ? 'Attending' : 'Not Attending'}</div>
            </div>
            <button
              className='btn btn-primary'
              onClick={() => navigate('/itero/hotel-reservation/edit')}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      {existingData.spouseInfo && (
        <>
          <hr className='mt-4' />
          <div className='fw-bold text-primary mb-3'>Spouse Information</div>
          <div className='row g-3'>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Name</label>
              <div>{existingData.spouseInfo.name}</div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Email</label>
              <div>{existingData.spouseInfo.email}</div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Passport</label>
              <div>{existingData.spouseInfo.icNo}</div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Age</label>
              <div>{existingData.spouseInfo.age}</div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Phone</label>
              <div>
                ({existingData.spouseInfo.phoneCode}){' '}
                {existingData.spouseInfo.phoneNo}
              </div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Meal Package</label>
              <div>
                {
                  {
                    1: 'No Preference',
                    2: 'Vegan',
                    3: 'Gluten Free',
                    4: 'Vegan & Gluten Free',
                  }[existingData.spouseInfo.mealPackage]
                }
              </div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>Remark</label>
              <div>{existingData.spouseInfo.remark || '-'}</div>
            </div>
            <div className='col-md-6'>
              <label className='fw-semibold text-muted'>
                Lord's Day Blending Meeting
              </label>
              <div>
                {existingData.spouseInfo.meeting
                  ? 'Attending'
                  : 'Not Attending'}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default IntlRegisterView
