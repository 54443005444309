import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SpecificRegistrationInfo = ({ onClose, eventId }) => {
  const [registration, setRegistration] = useState(null)
  const [loading, setLoading] = useState(true)
  const [remark, setRemark] = useState('')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const fetchRegistrationInfo = async () => {
      try {
        const response = await axios.get(
          `/api/v1/specific/register/info/${eventId}`,
          { withCredentials: true }
        )
        setRegistration(response.data)
        setRemark(response.data.remark || '')
      } catch (error) {
        toast.error(
          error.response?.data?.message || 'Failed to load registration info'
        )
      } finally {
        setLoading(false)
      }
    }

    if (eventId) {
      fetchRegistrationInfo()
    }
  }, [eventId])

  const handleUpdateRemark = async () => {
    if (!remark.trim()) {
      toast.error('Remark cannot be empty')
      return
    }

    setSaving(true)
    try {
      const response = await axios.put(
        `/api/v1/specific/register/${eventId}`,
        { remark },
        { withCredentials: true }
      )
      setRegistration(response.data)
      toast.success('Info updated successfully')
      onClose()
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update remark')
    } finally {
      setSaving(false)
    }
  }

  return (
    <div>
      <h5 className='text-center mb-3'>Registration Info</h5>

      {loading ? (
        <p>Loading...</p>
      ) : registration ? (
        <div style={{ fontWeight: '600', fontSize: '16px' }}>
          <div className='mb-2'>Registration: {registration.packageNmEn}</div>
          <div className='mb-2'>Fee: RM {registration.fee?.toFixed(2)}</div>

          <div>
            <label>Remark:</label>
            <input
              type='text'
              className='form-control'
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <p>No registration found.</p>
      )}

      <div className='text-end mt-3'>
        <button
          className='btn btn-warning btn-sm me-3'
          onClick={handleUpdateRemark}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Update'}
        </button>
        <button onClick={onClose} className='btn btn-secondary btn-sm'>
          Close
        </button>
      </div>
    </div>
  )
}

export default SpecificRegistrationInfo
