import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { Modal, Tooltip } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../components/shared/LoadingSpinner'

const IntlIndividualPayment = () => {
  const navigate = useNavigate()
  const [totalFee, setTotalFee] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [expandedMethod, setExpandedMethod] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [fileUploads, setFileUploads] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isQrModalVisible, setIsQrModalVisible] = useState(false)
  const [isUpgrade, setIsUpgrade] = useState(false)
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(true)
  const [paymentRecords, setPaymentRecords] = useState([])

  const paymentMethods = [
    {
      value: '1',
      label: 'Telegraphic Transfer',
      icon: 'bi bi-bank',
      description: (
        <>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Transfer the funds to our bank account via Telegraphic Transfer
              (TT), upload the proof of payment, and submit.
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div>Bank Account Information:</div>
              <ul className='m-0'>
                <li>Name of Bank: Public Bank Berhad</li>
                <li>Account Name: The Christian Association of Subang Jaya</li>
                <li>Account Number: 3242177619</li>
                <li>Branch: Subang Jaya Branch</li>
                <li>Swift Code: PBBEMYKL</li>
              </ul>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
    {
      value: '2',
      label: 'Credit / Debit Card',
      icon: 'bi bi-credit-card',
      description: (
        <div>
          Pay securely using your credit or debit card via our trusted payment
          gateway. We accept Visa, Mastercard.
          <div className='fw-bold' style={{ color: '#1a7414' }}>
            Coming Soon...
          </div>
        </div>
      ),
      requiresUpload: false,
    },
    {
      value: '3',
      label: 'QR eWallet',
      icon: 'bi bi-qr-code',
      description: (
        <>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Scan the QR code with your eWallet app and upload the proof of
              payment, and submit.
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div
                onClick={() => setIsQrModalVisible(true)}
                style={{ cursor: 'pointer', color: '#0d6efd' }}
              >
                Click here to view QR code <i className='bi bi-qr-code'></i>
              </div>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
  ]

  const checkFee = async () => {
    try {
      const res = await axios.get('/api/v1/intl/payment/check-individual-fee', {
        withCredentials: true,
      })
      if (res.data.success) {
        setStatus(res.data.status)
        setTotalFee(res.data.feeUsd || 0)
        setIsUpgrade(res.data.isUpgrade || false)
      } else {
        setStatus(null)
      }
    } catch (err) {
      console.error('Failed to fetch fee:', err)
      setStatus(null)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    checkFee()
    fetchPaymentRecords()
  }, [])

  const handleFileChange = (methodValue, event) => {
    const file = event.target.files[0]
    setFileUploads((prev) => ({ ...prev, [methodValue]: file }))
  }

  const handleUploadSubmit = async (methodValue) => {
    if (!fileUploads[methodValue]) {
      setErrorMessage('Please upload a file before submit.')
      return
    }

    setIsSubmitting(true)
    setErrorMessage('')

    const formData = new FormData()
    formData.append('paySlip', fileUploads[methodValue])
    formData.append('paymentMethod', methodValue)
    formData.append('totalAmount', totalFee)

    try {
      await axios.post('/api/v1/intl/payment/individual/submit', formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setSelectedPaymentMethod(null)
      setExpandedMethod(null)
      setFileUploads((prev) => ({ ...prev, [methodValue]: null }))

      setLoading(true)
      await checkFee()
      await fetchPaymentRecords()
    } catch (error) {
      toast.error('Error uploading payment data.')
      setErrorMessage(
        error.response?.data?.message || 'Upload failed. Try again.'
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const toggleExpand = (value) => {
    setExpandedMethod(expandedMethod === value ? null : value)
  }

  const handleQrModalCancel = () => setIsQrModalVisible(false)

  const fetchPaymentRecords = async () => {
    try {
      const res = await axios.get('/api/v1/intl/payment/individual/records', {
        withCredentials: true,
      })

      if (res.data.success) {
        setPaymentRecords(res.data.data || [])
      }
    } catch (err) {
      console.error('Failed to fetch payment records:', err)
    }
  }

  return (
    <Layout title='Event Registration System (ERS)'>
      <div className='p-3 mb-5'>
        <div
          className='fw-bold mb-3 text-uppercase text-center'
          style={{
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/')}
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
          >
            Lodging & Meal
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Payment
        </div>

        <div className='mb-2 text-center'>
          <div style={{ fontWeight: 600, color: '#951a26', fontSize: '18px' }}>
            <i className='bi bi-exclamation-circle-fill'></i> All payments are
            non-refundable
          </div>
        </div>

        {status === 3 && (
          <div
            style={{
              border: '1px solid #856404',
              backgroundColor: '#fffbf0',
              color: '#856404',
              padding: '10px',
              borderRadius: '8px',
              marginBottom: '1rem',
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            <i
              className='bi bi-hourglass-split'
              style={{ marginRight: '8px' }}
            ></i>
            We have received your payment slip and it is currently being
            processed. Once the payment is confirmed, you will be notified via
            email.
          </div>
        )}

        {loading ? (
          <LoadingSpinner />
        ) : status !== 1 ? (
          <>
            <div
              style={{ color: '#2f4483', fontWeight: '600', fontSize: '17px' }}
            >
              Total Amount for Payments: USD {totalFee.toFixed(2)}
              {isUpgrade && (
                <span
                  className='ms-2'
                  style={{ color: '#c92c2c', fontWeight: '500' }}
                >
                  (Please pay the remaining upgrade fee.)
                </span>
              )}
            </div>

            <div className='mb-4'>
              <div className='mt-2'>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                  <div style={{ fontWeight: 'bold' }}>
                    Please choose a payment method below:
                  </div>
                  {errorMessage && (
                    <div style={{ color: 'red', fontWeight: 'bold' }}>
                      {errorMessage}
                    </div>
                  )}
                </div>

                <div className='d-flex flex-column gap-3'>
                  {paymentMethods.map((method) => (
                    <div
                      key={method.value}
                      className={`p-3 border rounded ${
                        selectedPaymentMethod === method.value
                          ? 'border-primary bg-light'
                          : 'border-secondary'
                      }`}
                      style={{ cursor: 'pointer', transition: 'all 0.2s ease' }}
                    >
                      <div
                        className='d-flex align-items-center justify-content-between'
                        onClick={() => {
                          setSelectedPaymentMethod(method.value)
                          setErrorMessage('')
                          toggleExpand(method.value)
                        }}
                      >
                        <div className='d-flex align-items-center'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            value={method.value}
                            checked={selectedPaymentMethod === method.value}
                            onChange={(e) =>
                              setSelectedPaymentMethod(e.target.value)
                            }
                            style={{ marginRight: '10px' }}
                          />
                          <span style={{ fontWeight: 'bold' }}>
                            {method.label}
                          </span>
                          <i className={`${method.icon} ms-2`}></i>
                        </div>
                        <i
                          className={`bi ${
                            expandedMethod === method.value
                              ? 'bi-chevron-up'
                              : 'bi-chevron-down'
                          }`}
                        ></i>
                      </div>

                      {expandedMethod === method.value && (
                        <div
                          className='mt-2 p-2'
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                          }}
                        >
                          <div className='m-0' style={{ fontWeight: '500' }}>
                            {method.description}
                          </div>
                          {method.requiresUpload && (
                            <div className='mt-2 d-flex align-items-center'>
                              <div
                                className='custom-file'
                                style={{ flex: '1' }}
                              >
                                <input
                                  type='file'
                                  accept='image/*,application/pdf'
                                  id={`file-upload-${method.value}`}
                                  onChange={(e) =>
                                    handleFileChange(method.value, e)
                                  }
                                  className='custom-file-input'
                                  style={{ display: 'none' }}
                                />
                                <label
                                  className='btn btn-light w-100 text-start'
                                  htmlFor={`file-upload-${method.value}`}
                                >
                                  {fileUploads[method.value]?.name ||
                                    'Upload Payment Slip'}
                                </label>
                              </div>
                              <button
                                className='btn btn-primary'
                                onClick={() => handleUploadSubmit(method.value)}
                                disabled={
                                  isSubmitting || !fileUploads[method.value]
                                }
                              >
                                {isSubmitting ? (
                                  <span className='spinner-border spinner-border-sm me-2'></span>
                                ) : (
                                  'Submit'
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {status === null && (
              <div
                className='fw-bold text-center mb-3'
                style={{ fontSize: '17px', color: '#006ba1' }}
              >
                Please complete your lodging and meal registration before making
                payment.
              </div>
            )}
          </>
        )}

        {paymentRecords.length > 0 && (
          <div className='mb-4'>
            <div className='fw-bold mb-2' style={{ color: '#006ba1' }}>
              Payment Records
            </div>
            <table className='table'>
              <thead>
                <tr>
                  <th>Payment Date</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th className='text-end'>Amount (USD)</th>
                </tr>
              </thead>
              <tbody>
                {paymentRecords.map((rec, idx) => (
                  <tr key={rec._id || idx}>
                    <td>
                      {(() => {
                        const d = new Date(rec.createdAt)
                        const pad = (n) => n.toString().padStart(2, '0')
                        return `${pad(d.getUTCDate())}/${pad(
                          d.getUTCMonth() + 1
                        )}/${d.getUTCFullYear()} ${pad(d.getUTCHours())}:${pad(
                          d.getUTCMinutes()
                        )}:${pad(d.getUTCSeconds())}`
                      })()}
                    </td>

                    <td>
                      {{
                        1: 'Telegraphic Transfer',
                        2: 'Credit / Debit Card',
                        3: 'QR eWallet',
                      }[rec.paymentMethod] || 'Unknown'}
                      {rec.paySlipSystem && (
                        <a
                          href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/67cf0e922ea8904cab38ae7b/${rec.paySlipSystem}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: '#0d6efd' }}
                          className='ms-2'
                        >
                          [View Pay Slip]
                        </a>
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: '600',
                        color:
                          rec.paymentStatus === 1
                            ? 'green'
                            : rec.paymentStatus === 2
                            ? 'red'
                            : rec.paymentStatus === 3
                            ? '#856404'
                            : 'inherit',
                      }}
                    >
                      {rec.paymentStatus === 1
                        ? 'Paid & Confirmed'
                        : rec.paymentStatus === 2
                        ? 'Pending Payment'
                        : rec.paymentStatus === 3
                        ? 'Processing'
                        : ''}
                    </td>

                    <td className='text-end'>
                      {rec.amount?.toFixed(2) || '0.00'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <Modal
          open={isQrModalVisible}
          onCancel={handleQrModalCancel}
          footer={
            <div className='text-end'>
              <button className='close-btn' onClick={handleQrModalCancel}>
                Close
              </button>
            </div>
          }
          title={<div className='text-center'>QR Code for Payment</div>}
          centered
        >
          <div className='d-flex justify-content-center'>
            <img
              src='/mbb_qr.jpg'
              alt='QR Code for eWallet Payment'
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlIndividualPayment
