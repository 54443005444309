import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingSpinner from './../../../components/shared/LoadingSpinner'
import TitleModal from '../../../components/shared/TitleModal'

const MeetingCallIn = ({ eventId, onClose }) => {
  const [meetingInfo, setMeetingInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [meetingNotStarted, setMeetingNotStarted] = useState(false)
  const [eventStartDate, setEventStartDate] = useState('')

  useEffect(() => {
    if (eventId) {
      fetchMeetingInfo(eventId)
    }
  }, [eventId])

  const fetchMeetingInfo = async (eventId) => {
    setLoading(true)
    try {
      const response = await axios.get(
        `/api/v1/user/dashboard/meeting/call/${eventId}`,
        { withCredentials: true }
      )
      setMeetingInfo(response.data)
      setMeetingNotStarted(false)
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMeetingNotStarted(true)

        const extractedDate =
          error.response.data.message.match(/\d{2}\/\d{2}\/\d{4}/)
        if (extractedDate) {
          setEventStartDate(extractedDate[0])
        } else {
          setEventStartDate('the event start date')
        }
      } else {
        toast.error('Failed to load meeting info.')
      }
      console.error('Error fetching meeting info:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <TitleModal title='Call in (for BM language Only)' />
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : meetingNotStarted ? (
        <div style={{ fontSize: '17px' }}>
          <i className='bi bi-info-circle-fill' style={{ color: 'red' }}></i>{' '}
          The meeting has not started yet.
          <div>Please come back on {eventStartDate} at 7:15 PM.</div>
        </div>
      ) : meetingInfo ? (
        <div style={{ fontWeight: '500', fontSize: '17px' }}>
          <div>
            <a
              href='tel:+60377244080'
              style={{ textDecoration: 'none', color: '#337ab7' }}
            >
              +60377244080
            </a>
          </div>
          <div className='mb-1'>
            <a
              href='tel:+60327060888'
              style={{ textDecoration: 'none', color: '#337ab7' }}
            >
              +60327060888
            </a>
          </div>
          <div>Meeting ID: {meetingInfo.meetingCallId}</div>
          <div>Passcode: {meetingInfo.meetingCallPw}</div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>No Record</div>
      )}
      <div className='text-end mt-2'>
        <button className='close-btn' onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default MeetingCallIn
