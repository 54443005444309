import React, { useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import AdminLocalityList from './local/LocalityList'
import AdminIntlList from './intl/List'
import AdminIteroList from './itero/List'

const AdminLocalityIndex = () => {
  const [activeTab, setActiveTab] = useState('local')

  return (
    <Layout title={'Church Account'}>
      <div className='p-3'>
        <div className='d-flex justify-content-center mb-4 gap-3'>
          <button
            className={`btn mx-2 ${
              activeTab === 'local' ? 'btn-primary' : 'btn-outline-secondary'
            }`}
            onClick={() => setActiveTab('local')}
          >
            Malaysia
          </button>
          <button
            className={`btn mx-2 ${
              activeTab === 'international'
                ? 'btn-primary'
                : 'btn-outline-secondary'
            }`}
            onClick={() => setActiveTab('international')}
          >
            International
          </button>
          <button
            className={`btn mx-2 ${
              activeTab === 'itero' ? 'btn-primary' : 'btn-outline-secondary'
            }`}
            onClick={() => setActiveTab('itero')}
          >
            ITERO
          </button>
        </div>

        <div>
          {activeTab === 'local' && <AdminLocalityList />}
          {activeTab === 'international' && <AdminIntlList />}
          {activeTab === 'itero' && <AdminIteroList />}
        </div>
      </div>
    </Layout>
  )
}

export default AdminLocalityIndex
