import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LocalityChangeRequest from './LocalityChangeRequest'

const defaultParticipantForm = {
  searchText: '',
  searchResults: [],
  loading: false,
  gender: '',
  name: '',
  chiName: '',
  email: '',
  phoneNo: '',
  age: '',
  language: '',
  remark: '',
  isUserSelected: false,
}

const ChurchRegistrationNonStayForm = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [nonStayPackages, setNonStayPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [form, setForm] = useState(defaultParticipantForm)
  const [isNewRegistration, setIsNewRegistration] = useState(false)
  const [parentSearchResults, setParentSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const fetchStayPackages = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `/api/v1/church/event/register/non-stay-packages/${id}`,
          { withCredentials: true }
        )

        setNonStayPackages(response.data)

        if (response.data.length > 0) {
          const firstPackage = response.data[0]
          setSelectedPackage(firstPackage)
          setForm(defaultParticipantForm)
        }
      } catch (error) {
        console.error('Error fetching non-stay packages:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchStayPackages()
  }, [id])

  const handlePackageChange = (packageId, packages = nonStayPackages) => {
    const selected = packages.find((pkg) => pkg._id === packageId)
    setSelectedPackage(selected)
    setForm({ ...defaultParticipantForm })
    setErrors({})
  }

  const handleSearchChange = (text) => {
    if (text.trim() === '') {
      setForm((prevForm) => ({
        ...prevForm,
        searchText: '',
        isUserSelected: false,
        searchResults: [],
        loading: false,
        isNewRegistration: false,
        gender: '',
        name: '',
        chiName: '',
        email: '',
        phoneNo: '',
        age: '',
        language: '',
        remark: '',
        originalEmail: '',
        originalPhoneNo: '',
      }))
      return
    }

    setForm((prevForm) => ({
      ...prevForm,
      searchText: text,
      loading: text.length > 3,
      isUserSelected: false,
      searchResults: [],
    }))

    if (text.length > 3) {
      axios
        .post('/api/v1/church/event/register/search', {
          searchText: text,
          eventId: id,
        })
        .then(({ data }) => {
          setForm((prevForm) => ({
            ...prevForm,
            searchResults: data,
            loading: false,
            isNewRegistration: data.length === 0,
          }))
        })
        .catch((error) => {
          console.error('Error searching users:', error)
          setForm((prevForm) => ({
            ...prevForm,
            loading: false,
            searchResults: [],
          }))
        })
    }
  }

  const handleUserSelect = (user) => {
    setForm((prevForm) => ({
      ...prevForm,
      userId: user._id,
      searchText: user.name,
      searchResults: [],
      isUserSelected: true,
      originalEmail: user.email || '',
      originalPhoneNo: user.phoneNo || '',
      ...user,
    }))
  }

  const handleParentSearchChange = async (text) => {
    setForm((prevForm) => ({
      ...prevForm,
      parentName: text,
    }))

    if (errors.parentName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        parentName: '',
      }))
    }

    if (text.length <= 3) {
      setParentSearchResults([])
      return
    }

    try {
      const response = await axios.post(
        '/api/v1/church/event/register/parent/search',
        { searchText: text, eventId: id },
        { withCredentials: true }
      )

      setParentSearchResults(response.data)
    } catch (error) {
      console.error('Error searching parents:', error)

      if (error.response && error.response.status === 404) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          parentName: 'Parent not yet registered.',
        }))
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          parentName: 'Error searching parents. Please try again.',
        }))
      }

      setParentSearchResults([])
    }
  }

  const handleParentSelect = (parent) => {
    setForm((prevForm) => ({
      ...prevForm,
      parentName: parent.name,
    }))

    setParentSearchResults([])
  }

  const handleFieldChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }))

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value ? '' : prevErrors[field],
    }))
  }

  const handleRemove = () => {
    setIsNewRegistration(false)
    setForm({
      ...defaultParticipantForm,
      isUserSelected: false,
      searchText: '',
    })
  }

  const isSubmitEnabled = form.gender && form.name

  const validateFields = () => {
    let newErrors = {
      gender: form.gender ? '' : 'Required!',
      name: form.name ? '' : 'Required!',
      phoneNo:
        (!selectedPackage?.children || selectedPackage?.children === 0) &&
        !form.phoneNo
          ? 'Required!'
          : '',
      age: form.age ? '' : 'Required!',
      language: form.language ? '' : 'Required!',
      parentName:
        selectedPackage?.children === 1 && !form.parentName ? 'Required!' : '',
    }

    setErrors(newErrors)
    return Object.values(newErrors).every((msg) => !msg)
  }

  const handleSubmit = async () => {
    setErrors({})
    if (!validateFields()) {
      return
    }

    let newErrors = {}
    const emailChanged = form.email !== form.originalEmail
    const phoneChanged = form.phoneNo !== form.originalPhoneNo
    const genderChanged = form.gender !== form.originalGender
    const nameChanged = form.name !== form.originalName
    const chiNameChanged = form.chiName !== form.originalChiName

    let userUpdateRequired =
      emailChanged ||
      phoneChanged ||
      genderChanged ||
      nameChanged ||
      chiNameChanged
    let updatedUserData = {}

    if (userUpdateRequired) {
      if (genderChanged) updatedUserData.gender = form.gender
      if (nameChanged) updatedUserData.name = form.name
      if (chiNameChanged) updatedUserData.chiName = form.chiName
      if (emailChanged) updatedUserData.email = form.email
      if (phoneChanged) updatedUserData.phoneNo = form.phoneNo
    }

    setIsSubmitting(true)

    if (selectedPackage?.children !== 1 && (emailChanged || phoneChanged)) {
      try {
        const checkResponse = await axios.post(
          '/api/v1/church/event/register/check-unique-user',
          { email: form.email, phoneNo: form.phoneNo, userId: form.userId },
          { withCredentials: true }
        )

        const { emailExists, phoneExists, duplicateUserDetails, sameLocality } =
          checkResponse.data

        if (phoneExists || emailExists) {
          const { name, locality } = duplicateUserDetails

          if (phoneExists) {
            newErrors.uniquePhoneError = (
              <div className='d-flex align-items-center'>
                {form.userId ? (
                  <span>
                    Phone number already exists and belongs to another user.
                    Please change!
                  </span>
                ) : (
                  <>
                    <span>
                      Phone number already exists and belongs to <b>{name}</b>{' '}
                      in <b>{locality}</b>.
                    </span>
                    {!sameLocality && (
                      <button
                        type='button'
                        className='btn btn-sm btn-warning ms-2'
                        onClick={handleChangeLocality}
                      >
                        Click here to request a change of saint's locality.
                      </button>
                    )}
                  </>
                )}
              </div>
            )
          } else {
            newErrors.uniqueEmailError = (
              <div className='d-flex align-items-center'>
                {form.userId ? (
                  <span>
                    Email already exists and belongs to another user. Please
                    change!
                  </span>
                ) : (
                  <>
                    <span>
                      Email already exists and belongs to <b>{name}</b> in{' '}
                      <b>{locality}</b>.
                    </span>
                    {!sameLocality && (
                      <button
                        type='button'
                        className='btn btn-sm btn-warning ms-2'
                        onClick={handleChangeLocality}
                      >
                        Click here to request a change of saint's locality.
                      </button>
                    )}
                  </>
                )}
              </div>
            )
          }

          setErrors(newErrors)
          setIsSubmitting(false)
          return
        }
      } catch (error) {
        console.error('Error checking uniqueness:', error)
        toast.error('Failed to validate email/phone')
        setIsSubmitting(false)
        return
      }
    }

    try {
      const response = await axios.post(
        '/api/v1/church/event/register/non-stay',
        {
          eventId: id,
          participant: {
            ...form,
            userUpdateRequired,
            updatedUserData,
            packageId: selectedPackage.mainId,
            packageNmEn: selectedPackage.packageNmEn,
            packageNmZh: selectedPackage.packageNmZh,
            packageNmBm: selectedPackage.packageNmBm,
            fee: selectedPackage.fee,
            meal: selectedPackage.meal,
          },
        },
        { withCredentials: true }
      )

      if (response.status === 201) {
        toast.success('Submitted Successfully!')
        setForm({ ...defaultParticipantForm })
        setErrors({})
        setIsSubmitting(false)
        if (nonStayPackages.length > 0) {
          handlePackageChange(nonStayPackages[0]._id, nonStayPackages)
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message)
      } else {
        console.error('Error submitting registration:', error)
        toast.error('Failed to register!')
      }
      setIsSubmitting(false)
    }
  }

  const handleChangeLocality = () => {
    setShowModal(true)
  }

  const resetFormAndErrors = () => {
    setForm({ ...defaultParticipantForm })
    setErrors({})
    setShowModal(false)
  }

  const listContainerStyle = {
    maxHeight: '200px',
    overflowY: 'auto',
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginRight: '60px',
  }

  return (
    <div className='mb-3'>
      <div className='row mb-3'>
        <div className='col-md-12'>
          <label className='form-label'>Select Non-Stay In Option</label>
          <select
            className='form-control'
            value={selectedPackage ? selectedPackage._id : ''}
            onChange={(e) => handlePackageChange(e.target.value)}
          >
            {nonStayPackages.map((pkg) => (
              <option key={pkg._id} value={pkg._id}>
                {pkg.packageNmEn} - RM {pkg.fee.toFixed(2)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='mb-2' style={{ fontWeight: '600' }}>
        <span className='require'>*</span> are required to be filled in.
      </div>
      {loading ? (
        <div
          style={{
            textAlign: 'center',
            color: '#5d3104',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <span className='spinner-border spinner-border-sm me-2'></span>
          Loading...
        </div>
      ) : (
        <div>
          <div
            className='p-3 mb-3 rounded bg-white shadow-sm'
            style={{
              border: '1px solid #a89163',
              boxShadow: '0 0 10px rgba(217, 83, 79, 0.5)',
            }}
          >
            <div className='mb-3'>
              <div className='col-md-12'>
                <div className='mb-2' style={{ fontWeight: '600' }}>
                  Enter a name, email, or phone number and choose a participant
                  from the list.
                </div>
                <div className='d-flex align-items-center'>
                  <input
                    type='text'
                    className='form-control'
                    value={form.searchText}
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                  <button
                    type='button'
                    className={`btn ${
                      form.searchText ? 'btn-warning' : 'btn-secondary'
                    }`}
                    onClick={handleRemove}
                    disabled={!form.searchText}
                  >
                    Remove
                  </button>
                </div>

                {form.isNewRegistration && !form.isUserSelected && (
                  <div className='mt-1 text-danger' style={{ fontWeight: 600 }}>
                    User not found - Please fill in the form below
                  </div>
                )}

                {form.searchResults.length > 0 && (
                  <div style={listContainerStyle}>
                    <ul className='list-group'>
                      {form.searchResults.map((user) => (
                        <li
                          key={user._id}
                          className={`list-group-item list-group-item-action ${
                            user.isRegistered ? 'disabled' : ''
                          }`}
                          onClick={() =>
                            !user.isRegistered && handleUserSelect(user)
                          }
                          style={{
                            cursor: user.isRegistered
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        >
                          {user.name}{' '}
                          {user.isRegistered && (
                            <span className='text-primary'>- Registered</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {(errors.uniqueEmailError || errors.uniquePhoneError) && (
              <div
                className='alert alert-danger p-2 mb-2'
                style={{ backgroundColor: '#fcedee' }}
              >
                {errors.uniqueEmailError && (
                  <div>{errors.uniqueEmailError}</div>
                )}
                {errors.uniquePhoneError && (
                  <div>{errors.uniquePhoneError}</div>
                )}
              </div>
            )}

            {selectedPackage?.children === 0 ? (
              <>
                <div className='row mb-3'>
                  <div className='col-md-1'>
                    <label className='form-label'>
                      Gender <span className='require'>*</span>
                    </label>
                    <select
                      className={`form-control ${
                        errors.gender ? 'is-invalid' : ''
                      }`}
                      value={form.gender || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('gender', e.target.value)
                      }
                    >
                      <option value=''>Choose</option>
                      <option value='1'>Brother</option>
                      <option value='2'>Sister</option>
                    </select>
                    {errors.gender && (
                      <div className='invalid-feedback'>{errors.gender}</div>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>
                      English Name <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.name ? 'is-invalid' : ''
                      }`}
                      value={form.name || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('name', e.target.value)
                      }
                    />
                    {errors.name && (
                      <div className='invalid-feedback'>{errors.name}</div>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>Chinese Name</label>
                    <input
                      type='text'
                      className='form-control'
                      value={form.chiName || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('chiName', e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      value={form.email || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('email', e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-2'>
                    <label className='form-label'>
                      Phone Number <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.phoneNo ? 'is-invalid' : ''
                      }`}
                      value={form.phoneNo || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('phoneNo', e.target.value)
                      }
                    />
                    {errors.phoneNo && (
                      <div className='invalid-feedback'>{errors.phoneNo}</div>
                    )}
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-md-1'>
                    <label className='form-label'>
                      Age <span className='require'>*</span>
                    </label>
                    <input
                      type='number'
                      className={`form-control ${
                        errors.age ? 'is-invalid' : ''
                      }`}
                      value={form.age || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) => handleFieldChange('age', e.target.value)}
                    />
                    {errors.age && (
                      <div className='invalid-feedback'>{errors.age}</div>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>
                      Language <span className='require'>*</span>
                    </label>
                    <select
                      className={`form-control ${
                        errors.language ? 'is-invalid' : ''
                      }`}
                      value={form.language || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('language', e.target.value)
                      }
                    >
                      <option value=''>Choose</option>
                      <option value='1'>English</option>
                      <option value='2'>中文</option>
                      <option value='3'>BM</option>
                    </select>
                    {errors.language && (
                      <div className='invalid-feedback'>{errors.language}</div>
                    )}
                  </div>
                  <div className='col-md-6'>
                    <label className='form-label'>Remarks</label>
                    <input
                      type='text'
                      className='form-control'
                      value={form.remark || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('remark', e.target.value)
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='row mb-3'>
                  <div className='col-md-1'>
                    <label className='form-label'>
                      Gender <span className='require'>*</span>
                    </label>
                    <select
                      className={`form-control ${
                        errors.gender ? 'is-invalid' : ''
                      }`}
                      value={form.gender || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('gender', e.target.value)
                      }
                    >
                      <option value=''>Choose</option>
                      <option value='1'>Brother</option>
                      <option value='2'>Sister</option>
                    </select>
                    {errors.gender && (
                      <div className='invalid-feedback'>{errors.gender}</div>
                    )}
                  </div>

                  <div className='col-md-3'>
                    <label className='form-label'>
                      English Name <span className='require'>*</span>
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.name ? 'is-invalid' : ''
                      }`}
                      value={form.name || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('name', e.target.value)
                      }
                    />
                    {errors.name && (
                      <div className='invalid-feedback'>{errors.name}</div>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>Chinese Name</label>
                    <input
                      type='text'
                      className='form-control'
                      value={form.chiName || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('chiName', e.target.value)
                      }
                    />
                  </div>
                  <div className='col-md-5'>
                    <label className='form-label'>
                      Parent / Guardian <span className='require'>*</span>
                    </label>
                    <div className='d-flex align-items-center'>
                      <input
                        type='text'
                        className={`form-control ${
                          errors.parentName ? 'is-invalid' : ''
                        }`}
                        value={form.parentName || ''}
                        disabled={
                          !form.isUserSelected && !form.isNewRegistration
                        }
                        onChange={(e) =>
                          handleParentSearchChange(e.target.value)
                        }
                      />
                    </div>

                    {errors.parentName && (
                      <div className='text-danger'>{errors.parentName}</div>
                    )}
                    {parentSearchResults.length > 0 && (
                      <div style={listContainerStyle}>
                        <ul className='list-group'>
                          {parentSearchResults.map((parent) => (
                            <li
                              key={parent._id}
                              className='list-group-item list-group-item-action'
                              onClick={() => handleParentSelect(parent)}
                              style={{ cursor: 'pointer' }}
                            >
                              {parent.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-md-1'>
                    <label className='form-label'>
                      Age <span className='require'>*</span>
                    </label>
                    <input
                      type='number'
                      className={`form-control ${
                        errors.age ? 'is-invalid' : ''
                      }`}
                      value={form.age || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) => handleFieldChange('age', e.target.value)}
                    />
                    {errors.age && (
                      <div className='invalid-feedback'>{errors.age}</div>
                    )}
                  </div>
                  <div className='col-md-3'>
                    <label className='form-label'>
                      Language <span className='require'>*</span>
                    </label>
                    <select
                      className={`form-control ${
                        errors.language ? 'is-invalid' : ''
                      }`}
                      value={form.language || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('language', e.target.value)
                      }
                    >
                      <option value=''>Choose</option>
                      <option value='1'>English</option>
                      <option value='2'>中文</option>
                      <option value='3'>BM</option>
                    </select>
                    {errors.language && (
                      <div className='invalid-feedback'>{errors.language}</div>
                    )}
                  </div>

                  <div className='col-md-8'>
                    <label className='form-label'>Remarks</label>
                    <input
                      type='text'
                      className='form-control'
                      value={form.remark || ''}
                      disabled={!form.isUserSelected && !form.isNewRegistration}
                      onChange={(e) =>
                        handleFieldChange('remark', e.target.value)
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='d-flex justify-content-end mt-4'>
            <button
              className='btn btn-primary me-3'
              onClick={handleSubmit}
              disabled={!isSubmitEnabled || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className='spinner-border spinner-border-sm me-2'></span>
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </button>

            <button
              className='btn btn-outline-secondary'
              onClick={() => navigate(`/event/${id}`)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <LocalityChangeRequest
          visible={showModal}
          onClose={() => setShowModal(false)}
          userPhone={form.phoneNo}
          userEmail={form.email}
          resetForm={resetFormAndErrors}
        />
      )}
    </div>
  )
}

export default ChurchRegistrationNonStayForm
