import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import PaginationButtons from '../../components/shared/PaginationButtons'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

const AdminDashboard = () => {
  const [events, setEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalBro, setTotalBro] = useState(0)
  const [totalSis, setTotalSis] = useState(0)
  const [totalLocalities, setTotalLocalities] = useState(0)

  const fetchDashboardData = async (page = 1) => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `/api/v1/admin/dashboard/summary?page=${page}`
      )
      const processedEvents = data.events.map((event) => ({
        ...event,
        nameEn: event.nameEn
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
      }))

      setEvents(processedEvents)
      setCurrentPage(data.currentPage)
      setTotalPages(data.totalPages)
      setTotalUsers(data.totalUsers)
      setTotalBro(data.totalBro)
      setTotalSis(data.totalSis)
      setTotalLocalities(data.totalLocalities)
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDashboardData()
  }, [])

  return (
    <Layout title={'Admin Dashboard'}>
      <div className='content flex-grow-1 py-3 px-2'>
        <div className='stats d-flex justify-content-start mb-3'>
          <Link to='/admin/personal-account' className='text-decoration-none'>
            <div className='stat-box bg-success text-white p-3 me-4 rounded text-center'>
              <h2 className='m-0'>
                {totalUsers}{' '}
                <span style={{ fontSize: '17px' }}>
                  (Bro-{totalBro} | Sis-{totalSis})
                </span>
              </h2>
              <p className='m-0'>Personal Account</p>
            </div>
          </Link>
          <Link to='/admin/church-account' className='text-decoration-none'>
            <div className='stat-box bg-primary text-white p-3 rounded text-center'>
              <h2 className='m-0'>{totalLocalities}</h2>
              <p className='m-0'>Local Church Account</p>
            </div>
          </Link>
        </div>

        <div className='event-stat'>
          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th className='text-center'>Participants</th>
                <th className='text-center'>Transport</th>
                {/* status 1-confirmed 2-unpaid 3-paid */}
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='4' className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : events.length > 0 ? (
                events.map((event) => (
                  <tr key={event._id}>
                    <td>
                      {new Date(event.startDt).toLocaleDateString('en-GB')}
                      {event.startDt !== event.endDt &&
                        ` ~ ${new Date(event.endDt).toLocaleDateString(
                          'en-GB'
                        )}`}
                    </td>
                    <td>
                      {event.participants > 0 ? (
                        <Link
                          to={
                            event.regType1 === 1
                              ? `/admin/registration/data/${event._id}`
                              : `/admin/registration/online/data/${event._id}`
                          }
                          className='text-decoration-none'
                          style={{ color: '#000' }}
                        >
                          {event.nameEn}
                        </Link>
                      ) : (
                        <span>{event.nameEn || 0}</span>
                      )}
                    </td>
                    <td className='text-center'>
                      {event.participants > 0 ? (
                        <Link
                          to={
                            event.regType1 === 1
                              ? `/admin/registration/data/${event._id}`
                              : `/admin/registration/online/data/${event._id}`
                          }
                          className='text-decoration-none fw-bold text-primary'
                        >
                          {event.participants}
                        </Link>
                      ) : (
                        <span>{event.participants || 0}</span>
                      )}
                    </td>

                    <td className='text-center'>
                      {event.regType1 === 1 && event.transports > 0 ? (
                        <Link
                          to={`/admin/registration/transport/${event._id}`}
                          className='text-decoration-none fw-bold text-primary'
                        >
                          {event.transports}
                        </Link>
                      ) : event.regType1 === 1 ? (
                        event.transports || 0
                      ) : (
                        ''
                      )}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {event.regType1 === 1 ? (
                        <>
                          <Link
                            to={`/admin/registration/payment/${event._id}`}
                            className='text-decoration-none '
                          >
                            <span className='text-success'>
                              <Tooltip title='Paid & Confirmed'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#1e7e34',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.paid || 0}
                              </Tooltip>
                            </span>
                            <span className='ms-3' style={{ color: '#ba8b00' }}>
                              <Tooltip title='Processing'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: '#ba8b00',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.processing || 0}
                              </Tooltip>
                            </span>
                            <span className='text-danger ms-3'>
                              <Tooltip title='Pending Payment'>
                                <span
                                  style={{
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: 'red',
                                    cursor: 'pointer',
                                  }}
                                ></span>{' '}
                                {event.unpaid || 0}
                              </Tooltip>
                            </span>
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='4' className='text-center'>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={fetchDashboardData}
        />
      </div>
    </Layout>
  )
}

export default AdminDashboard
