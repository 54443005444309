import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import IntlPaymentRecords from './Records'
import { Modal, Tooltip } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'

const IntlPaymentList = () => {
  const navigate = useNavigate()
  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedPayments, setSelectedPayments] = useState([])
  const [totalFee, setTotalFee] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [expandedMethod, setExpandedMethod] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [fileUploads, setFileUploads] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isQrModalVisible, setIsQrModalVisible] = useState(false)

  const paymentMethods = [
    {
      value: '1',
      label: 'Telegraphic Transfer',
      icon: 'bi bi-bank',
      description: (
        <>
          <div className='d-flex align-items-center gap-2'>
            <span style={{ color: '#2f4483' }}>
              Total Amount for Selected Payments: USD {totalFee.toFixed(2)}
            </span>
          </div>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Please select the participants below for this payment. Then
              transfer the funds to our bank account via Telegraphic Transfer
              (TT), upload the proof of payment, and submit.
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div>Bank Account Information:</div>
              <ul className='m-0'>
                <li>Name of Bank: Public Bank Berhad</li>
                <li>Account Name: The Christian Association of Subang Jaya</li>
                <li>Account Number: 3242177619</li>
                <li>Branch: Subang Jaya Branch</li>
                <li>Swift Code: PBBEMYKL</li>
              </ul>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
    {
      value: '2',
      label: 'Credit / Debit Card',
      icon: 'bi bi-credit-card',
      description: (
        <div>
          <div>
            Pay securely using your credit or debit card via our trusted payment
            gateway. We accept Visa, Mastercard.
          </div>
          <div className='fw-bold' style={{ color: '#1a7414' }}>
            Coming Soon...
          </div>
        </div>
      ),
      requiresUpload: false,
    },
    {
      value: '3',
      label: 'QR eWallet',
      icon: 'bi bi-qr-code',
      description: (
        <>
          <div className='d-flex align-items-center gap-2'>
            <span style={{ color: '#2f4483' }}>
              Total Amount for Selected Payments: USD {totalFee.toFixed(2)}
            </span>
          </div>
          <div className='d-flex gap-2 mt-2'>
            <i
              className='bi bi-info-circle-fill'
              style={{ color: '#0d6efd' }}
            ></i>
            <span>
              Please select the participants below for this payment. Then scan
              the QR code with your eWallet app and upload the proof of payment,
              and submit.
            </span>
          </div>
          <div className='d-flex mt-1'>
            <div className='ms-4'>
              <div
                onClick={() => setIsQrModalVisible(true)}
                style={{ cursor: 'pointer', color: '#0d6efd' }}
              >
                Click here to view QR code <i className='bi bi-qr-code'></i>
              </div>
            </div>
          </div>
        </>
      ),
      requiresUpload: true,
    },
  ]

  const handleCheckboxChange = (paymentId, isChecked) => {
    setSelectedPayments((prevSelected) => {
      let updatedSelectedPayments
      if (isChecked) {
        updatedSelectedPayments = [...prevSelected, paymentId]
      } else {
        updatedSelectedPayments = prevSelected.filter((id) => id !== paymentId)
      }
      updateTotalFee(updatedSelectedPayments)
      return updatedSelectedPayments
    })
  }

  const updateTotalFee = (selectedIds) => {
    const total = payments
      .filter((payment) => selectedIds.includes(payment._id))
      .reduce((acc, payment) => acc + (payment.feeUsd || 0), 0)
    setTotalFee(total)
  }

  const fetchPayments = async () => {
    try {
      const response = await fetch('/api/v1/intl/payment/unpaid-list', {
        withCredentials: true,
      })
      if (response.ok) {
        const data = await response.json()
        setPayments(data)
      } else {
        const errorResponse = await response.json()
        console.error('Error fetching payments:', errorResponse.message)
      }
    } catch (error) {
      console.error('Error fetching payment data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPayments()
  }, [])

  useEffect(() => {
    updateTotalFee(selectedPayments)
  }, [selectedPayments, payments])

  const handleFileChange = (methodValue, event) => {
    const file = event.target.files[0]
    setFileUploads((prev) => ({
      ...prev,
      [methodValue]: file,
    }))
  }

  const handleUploadSubmit = async (methodValue) => {
    if (!selectedPayments.length) {
      setErrorMessage('Please select at least one participant before submit.')
      return
    }
    if (!fileUploads[methodValue]) {
      setErrorMessage('Please upload a file before submit.')
      return
    }
    setIsSubmitting(true)
    setErrorMessage('')

    const formData = new FormData()
    formData.append('paySlip', fileUploads[methodValue])
    formData.append('selectedPayments', JSON.stringify(selectedPayments))
    formData.append('paymentMethod', methodValue)
    formData.append('totalAmount', totalFee)

    try {
      const response = await axios.post(
        '/api/v1/intl/payment/submit',
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      fetchPayments()
      setSelectedPayments([])
      setErrorMessage('')
      setSelectedPaymentMethod(null)
      setExpandedMethod(null)
      setFileUploads((prev) => ({ ...prev, [methodValue]: null }))
      toast.success('Payment submitted successfully.')
    } catch (error) {
      toast.error('Error uploading payment data:', error)
      if (error.response) {
        setErrorMessage(
          error.response.data.message || 'Error uploading payment proof.'
        )
      } else {
        setErrorMessage('An error occurred while uploading. Please try again.')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const toggleExpand = (value) => {
    setExpandedMethod(expandedMethod === value ? null : value)
  }

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)
  const handleQrModalCancel = () => setIsQrModalVisible(false)

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-2 mb-5'>
        <div
          className='fw-bold mb-3 text-uppercase'
          style={{
            color: '#5d3104',
            textAlign: 'center',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>
        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/')}
          >
            Lodging & Meal List
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/form')}
          >
            Lodging & Meal Form
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{
              textTransform: 'uppercase',
              fontWeight: 600,
              cursor: 'not-allowed',
            }}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            onClick={() => navigate('/itero/hotel-reservation/transportation')}
          >
            Transportation
          </button>
        </div>
        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Payment
        </div>
        <div className='mb-1 d-flex justify-content-between align-items-center'>
          <div style={{ fontWeight: 600, color: '#951a26', fontSize: '18px' }}>
            <i className='bi bi-exclamation-circle-fill'></i> All payments are
            non-refundable
          </div>
          <button className='btn btn-info btn-sm' onClick={showModal}>
            Payment Records
          </button>
        </div>
        <div className='mb-4'>
          <div
            className='mt-2 p-3'
            style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
            }}
          >
            <div
              className='d-flex justify-content-between align-items-center'
              style={{ marginBottom: '10px' }}
            >
              <div style={{ fontWeight: 'bold' }}>
                Please choose a payment method below.
              </div>
              {errorMessage && (
                <div
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    marginTop: '5px',
                    textAlign: 'right',
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </div>
            <div className='d-flex flex-column gap-3'>
              {paymentMethods.map((method) => (
                <div
                  key={method.value}
                  className={`p-3 border rounded ${
                    selectedPaymentMethod === method.value
                      ? 'border-primary bg-light'
                      : 'border-secondary'
                  }`}
                  style={{
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                  }}
                >
                  <div
                    className='d-flex align-items-center justify-content-between'
                    onClick={() => {
                      setSelectedPaymentMethod(method.value)
                      setErrorMessage('')
                      toggleExpand(method.value)
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <input
                        type='radio'
                        name='paymentMethod'
                        value={method.value}
                        checked={selectedPaymentMethod === method.value}
                        onChange={(e) => {
                          setSelectedPaymentMethod(e.target.value)
                          setErrorMessage('')
                        }}
                        style={{ marginRight: '10px' }}
                      />
                      <span style={{ fontWeight: 'bold' }}>{method.label}</span>
                      <i className={`${method.icon} ms-2`}></i>
                    </div>
                    <i
                      className={`bi ${
                        expandedMethod === method.value
                          ? 'bi-chevron-up'
                          : 'bi-chevron-down'
                      }`}
                    ></i>
                  </div>

                  {/* Expanded Section */}
                  {expandedMethod === method.value && (
                    <div
                      className='mt-2 p-2'
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                      }}
                    >
                      <div className='m-0' style={{ fontWeight: '500' }}>
                        {method.description}
                      </div>
                      {method.requiresUpload && (
                        <div className='mt-2 d-flex align-items-center'>
                          <div className='custom-file' style={{ flex: '1' }}>
                            <input
                              type='file'
                              accept='image/*,application/pdf'
                              id={`file-upload-${method.value}`}
                              onChange={(e) =>
                                handleFileChange(method.value, e)
                              }
                              className='custom-file-input'
                              style={{ display: 'none' }}
                            />
                            <label
                              className='btn btn-light w-100 text-start'
                              htmlFor={`file-upload-${method.value}`}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {fileUploads[method.value]
                                ? fileUploads[method.value].name
                                : 'Upload Payment Slip'}
                            </label>
                          </div>

                          {!fileUploads[method.value] ||
                          selectedPayments.length === 0 ? (
                            <Tooltip title='Upload payment slip and select a participant below.'>
                              <span>
                                <button
                                  className='btn btn-primary'
                                  onClick={() =>
                                    handleUploadSubmit(method.value)
                                  }
                                  disabled
                                >
                                  {isSubmitting ? (
                                    <span className='spinner-border spinner-border-sm me-2'></span>
                                  ) : (
                                    'Submit'
                                  )}
                                </button>
                              </span>
                            </Tooltip>
                          ) : (
                            <button
                              className='btn btn-primary'
                              onClick={() => handleUploadSubmit(method.value)}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <span className='spinner-border spinner-border-sm me-2'></span>
                              ) : (
                                'Submit'
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className='text-center fw-bold'
          style={{ fontSize: '17px', color: '#1a7414' }}
        >
          Participants with Pending Payments
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='text-end'>Fee (USD)</th>
                <th className='text-end'>
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPayments(
                          payments.map((payment) => payment._id)
                        )
                      } else {
                        setSelectedPayments([])
                      }
                    }}
                    checked={
                      selectedPayments.length === payments.length &&
                      payments.length > 0
                    }
                    className='checkbox-large'
                    style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='5' className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : payments.length === 0 ? (
                <tr>
                  <td colSpan='5' className='text-center text-muted'>
                    No Pending Payment
                  </td>
                </tr>
              ) : (
                payments.map((payment) => (
                  <tr key={payment._id}>
                    <td>
                      <div
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          color: '#2f4483',
                        }}
                      >
                        {payment.name}
                      </div>
                      {payment.spouseInfo && payment.spouseInfo.name && (
                        <>
                          (Spouse:{' '}
                          <span
                            style={{
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              color: '#78003c',
                            }}
                          >
                            {payment.spouseInfo.name}
                          </span>
                          )
                        </>
                      )}
                    </td>
                    <td className='text-end'>
                      {payment.feeUsd ? payment.feeUsd.toFixed(2) : '0.00'}
                    </td>
                    <td className='text-end'>
                      <input
                        type='checkbox'
                        checked={selectedPayments.includes(payment._id)}
                        onChange={(e) =>
                          handleCheckboxChange(payment._id, e.target.checked)
                        }
                        style={{ transform: 'scale(1.5)', cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Modal
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={850}
        >
          <IntlPaymentRecords onCancel={handleCancel} />
        </Modal>

        <Modal
          open={isQrModalVisible}
          onCancel={handleQrModalCancel}
          footer={
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className='close-btn' onClick={handleQrModalCancel}>
                Close
              </button>
            </div>
          }
          title={<div style={{ textAlign: 'center' }}>QR Code for Payment</div>}
          centered
        >
          <div className='d-flex justify-content-center'>
            <img
              src='/mbb_qr.jpg'
              alt='QR Code for eWallet Payment'
              style={{ maxWidth: '70%', height: 'auto' }}
            />
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

export default IntlPaymentList
