import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../../components/Layout/Layout'
import axios from 'axios'
import LoadingSpinner from '../../../../components/shared/LoadingSpinner'

const AdminEventList = () => {
  const navigate = useNavigate()

  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')

  const observer = useRef()

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true)
        const response = await axios.get('/api/v1/admin/event/list', {
          params: { page, limit: 30 },
        })
        setItems((prevEvents) => [...prevEvents, ...response.data.events])
        setHasMore(response.data.hasMore)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching events:', error)
        setLoading(false)
      }
    }

    fetchEvents()
  }, [page])

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    setItems([])
    setHasMore(true)
  }

  const handleNew = () => {
    navigate('add')
  }

  const handleDelete = async (eventId) => {
    const isConfirmed = window.confirm(
      'Confirm delete this event? This action cannot be undone!'
    )

    if (isConfirmed) {
      try {
        await axios.delete(`/api/v1/admin/event/${eventId}`)
        setItems((prevItems) =>
          prevItems.filter((item) => item._id !== eventId)
        )
      } catch (error) {
        console.error('Error deleting event:', error)
      }
    }
  }

  const handleToggleStatus = async (eventId, currentStatus) => {
    const isConfirmed = window.confirm(`Confirm Update Status?`)

    if (isConfirmed) {
      try {
        const newStatus = currentStatus === 1 ? 2 : 1
        await axios.put(`/api/v1/admin/event/${eventId}/status`, {
          status: newStatus,
        })
        setItems((prevItems) =>
          prevItems.map((item) =>
            item._id === eventId ? { ...item, status: newStatus } : item
          )
        )
      } catch (error) {
        console.error('Error updating event status:', error)
      }
    }
  }

  const handleToggleRegStatus = async (eventId, currentStatus) => {
    const isConfirmed = window.confirm(`Confirm Update Registration Status?`)

    if (isConfirmed) {
      try {
        const newStatus = currentStatus === 1 ? 2 : 1
        await axios.put(`/api/v1/admin/event/${eventId}/reg-status`, {
          regStatus: newStatus,
        })
        setItems((prevItems) =>
          prevItems.map((item) =>
            item._id === eventId ? { ...item, regStatus: newStatus } : item
          )
        )
      } catch (error) {
        console.error('Error updating registration status:', error)
      }
    }
  }

  const handleToggleRegTypeStatus = async (eventId, currentStatus) => {
    const isConfirmed = window.confirm(
      `Confirm Update Church Registration Status?`
    )

    if (isConfirmed) {
      try {
        const newStatus = currentStatus === 1 ? 2 : 1
        await axios.put(`/api/v1/admin/event/${eventId}/church-reg-status`, {
          regTypeStatus: newStatus,
        })
        setItems((prevItems) =>
          prevItems.map((item) =>
            item._id === eventId ? { ...item, regTypeStatus: newStatus } : item
          )
        )
      } catch (error) {
        console.error('Error updating registration type status:', error)
      }
    }
  }

  return (
    <Layout title={'Event'}>
      <div className='p-3'>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-success btn-sm'
            onClick={handleNew}
          >
            <i className='bi bi-plus-circle'></i> Event
          </button>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Date</th>
                <th scope='col'>Event</th>
                <th className='text-center'>Status</th>
                <th className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  Reg. Status
                </th>
                <th className='text-center'>Reg. Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  key={index}
                  ref={index === items.length - 1 ? lastElementRef : null}
                >
                  <td>
                    {item.startDt === item.endDt
                      ? new Date(item.startDt).toISOString().split('T')[0]
                      : `${
                          new Date(item.startDt).toISOString().split('T')[0]
                        } ~ ${
                          new Date(item.endDt).toISOString().split('T')[0]
                        }`}
                  </td>
                  <td
                    onClick={() => navigate(`/admin/event/edit/${item._id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.nameEn}
                  </td>
                  <td
                    className='text-center'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleStatus(item._id, item.status)}
                  >
                    {item.status === 1 ? (
                      <i className='bi bi-check-lg text-success fw-bold'></i>
                    ) : (
                      <i className='bi bi-x-lg text-danger fw-bold'></i>
                    )}
                  </td>
                  <td
                    className='text-center'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleToggleRegStatus(item._id, item.regStatus)
                    }
                  >
                    {item.regStatus === 1 ? (
                      <i className='bi bi-check-lg text-success fw-bold'></i>
                    ) : (
                      <i className='bi bi-x-lg text-danger fw-bold'></i>
                    )}
                  </td>
                  <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                    {item.regType2 === 1
                      ? 'Personal'
                      : item.regType2 === 2
                      ? 'Church'
                      : item.regType2 === 3
                      ? 'Specific'
                      : ''}

                    {item.regTypeStatus !== undefined &&
                      item.regTypeStatus !== null && (
                        <span
                          className='ms-2'
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleToggleRegTypeStatus(
                              item._id,
                              item.regTypeStatus
                            )
                          }
                        >
                          {item.regTypeStatus === 1 ? (
                            <i className='bi bi-check-lg text-success fw-bold'></i>
                          ) : (
                            <i className='bi bi-x-lg text-danger fw-bold'></i>
                          )}
                        </span>
                      )}
                  </td>
                  <td className='text-end'>
                    <i
                      className='bi bi-pencil-square'
                      style={{ color: '#3c8dbc', cursor: 'pointer' }}
                      onClick={() => navigate(`/admin/event/edit/${item._id}`)}
                    ></i>
                    <i
                      className='bi bi-trash3 ms-3'
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => handleDelete(item._id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>{loading && <LoadingSpinner />}</div>
      </div>
    </Layout>
  )
}

export default AdminEventList
