import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import { toast } from 'react-toastify'

const AdminCoordinatorInfo = ({ eventId, onClose }) => {
  const [coordinatorData, setCoordinatorData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCoordinatorInfo = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/registration/coordinator/${eventId}`
        )
        setCoordinatorData(response.data)
      } catch (error) {
        console.error('Error fetching coordinator info:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchCoordinatorInfo()
  }, [eventId])

  const handleDelete = async (coordinatorId) => {
    const confirmDelete = window.confirm('Confirm Delete?')

    if (!confirmDelete) return

    try {
      await axios.delete(
        `/api/v1/admin/registration/coordinator/${coordinatorId}`
      )
      setCoordinatorData((prevData) =>
        prevData.filter((c) => c._id !== coordinatorId)
      )
      toast.success('Deleted successfully')
    } catch (error) {
      console.error('Error deleting coordinator:', error)
      toast.error('Failed to delete coordinator')
    }
  }

  return (
    <div>
      <h5 className='fw-bold mb-3 text-center'>Coordinator's Info</h5>

      {loading ? (
        <div className='text-center'>
          <Spin size='large' />
          <p>Loading...</p>
        </div>
      ) : coordinatorData.length > 0 ? (
        <>
          <div className='table-responsive'>
            <table className='table'>
              <thead className='table-light'>
                <tr>
                  <th>Locality</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th className='text-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {coordinatorData.map((coordinator, index) => (
                  <tr key={index}>
                    <td>{coordinator.localityName || '-'}</td>
                    <td>{coordinator.name || '-'}</td>
                    <td>{coordinator.phoneNo || '-'}</td>
                    <td>{coordinator.email || '-'}</td>
                    <td className='text-end'>
                      <i
                        className='bi bi-trash3 text-danger'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(coordinator._id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='text-end mt-1'>
            <button className='btn btn-secondary btn-sm' onClick={onClose}>
              Close
            </button>
          </div>
        </>
      ) : (
        <p className='text-center'>No coordinators found for this event.</p>
      )}
    </div>
  )
}

export default AdminCoordinatorInfo
