import React from 'react'

const TitleModal = ({ title }) => {
  return (
    <div
      className='text-center mb-2'
      style={{ fontWeight: '600', fontSize: '18px' }}
    >
      {title}
    </div>
  )
}

export default TitleModal
