import React, { useState } from 'react'
import axios from 'axios'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavigationBar from '../../components/Layout/NavigationBar'

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSending(true)
    setMessage('')
    setIsSuccess(null)
    try {
      const response = await axios.post('/api/v1/auth/reset-password-request', {
        email,
      })
      setMessage(response.data.message)
      setIsSuccess(true)
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred')
      setIsSuccess(error.response?.status === 429 ? null : false)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div>
      <style>{`
  .bg-warning-subtle {
    background-color: #fffbf0 !important;
  }
  .text-warning {
    color: #a87e00 !important;
  }
`}</style>
      <NavigationBar />
      <div className='d-flex align-items-center justify-content-center mt-5'>
        <div className='w-100' style={{ maxWidth: '550px' }}>
          {message && (
            <>
              <div
                className={`mb-2 p-2 border rounded text-center ${
                  isSuccess === true
                    ? 'border-success text-success bg-success-subtle'
                    : isSuccess === false
                    ? 'border-danger text-danger bg-danger-subtle'
                    : 'border-warning text-warning bg-warning-subtle'
                }`}
              >
                {message}
              </div>
              {isSuccess && (
                <div className='mb-2' style={{ fontWeight: '500' }}>
                  <i
                    className='bi bi-info-circle-fill me-2'
                    style={{ color: '#2c6739' }}
                  ></i>
                  Please note that it may take more than a few minutes for the
                  email to be sent. If you do not see it in your inbox, please
                  check your junk or spam folder.
                  <div>
                    If you are still experiencing issues receiving the email,
                    please contact us.
                  </div>
                </div>
              )}
            </>
          )}

          <div className='card shadow-lg'>
            <div
              className='text-white text-center py-2'
              style={{ backgroundColor: '#87734b' }}
            >
              <div className='fs-5'>Reset Password Request</div>
            </div>
            <div className='p-3'>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='email' className='form-label'>
                    Please enter your registered email
                  </label>
                  <input
                    type='email'
                    id='email'
                    className='form-control'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className='text-end'>
                  <Link to='/login' className='btn btn-secondary btn-sm me-4'>
                    Return to Login Page
                  </Link>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                    disabled={isSending}
                  >
                    {isSending ? ' Sending...' : 'Send Reset Link'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordRequest
