import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import LoadingSpinner from '../shared/LoadingSpinner'

export default function UserRoute() {
  const [auth] = useAuth()

  if (!auth.isRehydrated) {
    return <LoadingSpinner message='Verifying access...' />
  }

  if (!auth.user) {
    return <Navigate to='/login' replace />
  }

  // Allow only 'user' or 'admin' access
  if (!['user', 'admin'].includes(auth.access)) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}
