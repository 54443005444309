import React from 'react'
import { useAuth } from '../../context/auth'
import IntlIndividualTransport from '../../pages/Intl/Individual/Transport'
import IntlIndividualPayment from '../../pages/Intl/Individual/Payment'
import IntlTransportList from './../../pages/Intl/Transportation/Index'
import IntlPaymentList from './../../pages/Intl/Payment/Index'

export default function IntlRouteSwitch({ type }) {
  const [auth] = useAuth()

  if (auth.access !== 'intl') {
    return null
  }

  if (type === 'transportation') {
    return auth.user.command === 'individual' ? (
      <IntlIndividualTransport />
    ) : (
      <IntlTransportList />
    )
  }

  if (type === 'payment') {
    return auth.user.command === 'individual' ? (
      <IntlIndividualPayment />
    ) : (
      <IntlPaymentList />
    )
  }

  return null
}
