import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SpecificTransportForm = ({ onClose, eventId }) => {
  const [formData, setFormData] = useState({
    eventId: eventId || '',
    arrivalDate: '',
    arrivalTime: '',
    arrivalInfo: '',
    departDate: '',
    departTime: '',
    departInfo: '',
    remark: '',
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [existingTransport, setExistingTransport] = useState(false)

  useEffect(() => {
    const fetchTransportData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/specific/transport/${eventId}`
        )
        if (response.data) {
          const transportData = response.data
          const formatDate = (isoString) => {
            if (!isoString) return ''
            return new Date(isoString).toISOString().split('T')[0]
          }

          setFormData({
            ...transportData,
            arrivalDate: formatDate(transportData.arrivalDate),
            arrivalTime: transportData.arrivalTime,
            departDate: formatDate(transportData.departDate),
            departTime: transportData.departTime,
          })

          setExistingTransport(true)
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          console.log('No existing transport data found.')
        } else {
          console.error('Error fetching transport data:', err)
        }
      }
    }

    if (eventId) {
      fetchTransportData()
    }
  }, [eventId])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    if (
      (!formData.arrivalDate || !formData.arrivalTime) &&
      (!formData.departDate || !formData.departTime)
    ) {
      setError('Please fill in at least one arrival or departure date & time.')
      setLoading(false)
      return
    }

    try {
      const formattedData = {
        ...formData,
        arrivalTime: formData.arrivalTime,
        departTime: formData.departTime,
      }

      if (existingTransport) {
        await axios.put(`/api/v1/specific/transport/${eventId}`, formattedData)
        toast.success('Transportation Updated Successfully!')
      } else {
        await axios.post('/api/v1/specific/transport', formattedData)
        toast.success('Transportation Added Successfully!')
      }
      onClose()
    } catch (err) {
      setError('Failed to submit transport details. Please try again.')
      console.error('Error:', err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <h5 className='text-center mb-3'>Transportation Form</h5>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Arrival Section */}
        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Arrival</h6>
          <div className='row'>
            <div className='col-md-4'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                name='arrivalDate'
                className='form-control'
                value={formData.arrivalDate}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                name='arrivalTime'
                className='form-control'
                value={formData.arrivalTime}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Flight No./Bus</label>
              <input
                type='text'
                name='arrivalInfo'
                className='form-control'
                value={formData.arrivalInfo}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Departure Section */}
        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Departure</h6>
          <div className='row'>
            <div className='col-md-4'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                name='departDate'
                className='form-control'
                value={formData.departDate}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                name='departTime'
                className='form-control'
                value={formData.departTime}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Flight No./Bus</label>
              <input
                type='text'
                name='departInfo'
                className='form-control'
                value={formData.departInfo}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Remark Section */}
        <div className='row'>
          <div className='col-md-12'>
            <label className='form-label'>Remark</label>
            <input
              type='text'
              name='remark'
              className='form-control'
              value={formData.remark}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Buttons */}
        <div className='d-flex justify-content-end mt-3'>
          <button
            type='submit'
            className={`btn ${
              existingTransport ? 'btn-warning' : 'btn-primary'
            } btn-sm me-3`}
            disabled={loading}
          >
            {loading
              ? 'Processing...'
              : existingTransport
              ? 'Update'
              : 'Submit'}
          </button>
          <button
            type='button'
            onClick={onClose}
            className='btn btn-secondary btn-sm'
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default SpecificTransportForm
