import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'
import Select, { components } from 'react-select'
import TitleModal from '../../../components/shared/TitleModal'
import { toast } from 'react-toastify'

const MenuList = (props) => {
  const { children, selectProps } = props
  const observer = useRef()

  const { loadingLocalities, hasMoreLocality, loadMore } = selectProps
  const lastOptionRef = useCallback(
    (node) => {
      if (loadingLocalities) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreLocality) {
          loadMore()
        }
      })
      if (node) observer.current.observe(node)
    },
    [loadingLocalities, hasMoreLocality, loadMore]
  )
  const childrenArray = React.Children.toArray(children)
  const lastChildIndex = childrenArray.length - 1

  return (
    <components.MenuList {...props}>
      {childrenArray.slice(0, lastChildIndex)}
      <div ref={lastOptionRef}>{childrenArray[lastChildIndex]}</div>
    </components.MenuList>
  )
}

const AdminAddUserRegister = ({ eventPackages, eventId, closeModal }) => {
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    chiName: '',
    email: '',
    phoneNo: '',
    age: '',
    icNo: '',
    locality: null,
    language: '',
    package: null,
  })
  const [submitting, setSubmitting] = useState(false)
  const [localityOptions, setLocalityOptions] = useState([])
  const [localityPage, setLocalityPage] = useState(1)
  const [hasMoreLocality, setHasMoreLocality] = useState(true)
  const [localitySearch, setLocalitySearch] = useState('')
  const [loadingLocalities, setLoadingLocalities] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const fetchLocalities = async (page = 1, search = '') => {
    setLoadingLocalities(true)
    try {
      const response = await axios.get(
        '/api/v1/admin/registration/localities',
        {
          params: { search, page, limit: 30 },
        }
      )
      const { localities, hasMore } = response.data
      const newOptions = localities.map((loc) => ({
        value: loc._id,
        label: loc.name,
        code: loc.code,
      }))
      if (page === 1) {
        setLocalityOptions(newOptions)
      } else {
        setLocalityOptions((prev) => [...prev, ...newOptions])
      }
      setHasMoreLocality(hasMore)
      setLocalityPage(page)
    } catch (error) {
      console.error('Error fetching localities', error)
    } finally {
      setLoadingLocalities(false)
    }
  }

  useEffect(() => {
    fetchLocalities(1, localitySearch)
  }, [localitySearch])
  const loadMore = () => {
    if (!loadingLocalities && hasMoreLocality) {
      fetchLocalities(localityPage + 1, localitySearch)
    }
  }

  const initialFormData = {
    gender: '',
    name: '',
    chiName: '',
    email: '',
    phoneNo: '',
    age: '',
    icNo: '',
    locality: null,
    language: '',
    package: null,
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { gender, name, age, locality, language, package: pkg } = formData
    if (!gender || !name || !age || !locality || !language || !pkg) {
      toast.error('Please fill in required fields')
      return
    }
    const submissionData = {
      ...formData,
      eventId,
      locality: locality ? locality.value : null,
      localityName: locality ? locality.label : '',
      localityCode: locality ? locality.code : '',
      packageId: pkg ? pkg._id : null,
      packageNmEn: pkg ? pkg.packageNmEn : '',
      packageNmZh: pkg ? pkg.packageNmZh : '',
      packageNmBm: pkg ? pkg.packageNmBm : '',
      fee: pkg ? pkg.fee : 0,
      stay: pkg ? pkg.stay : 0,
      meal: pkg ? pkg.meal : 0,
      capacity: pkg ? pkg.capacity : 0,
      roomCode: pkg ? pkg.roomCode : '',
    }

    try {
      setSubmitting(true)
      await axios.post(
        '/api/v1/admin/registration/add-new-user',
        submissionData
      )
      toast.success('Registered successfully!')
      setFormData(initialFormData)
      closeModal()
    } catch (error) {
      console.error('Error saving registration', error)
      toast.error('Error saving registration')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TitleModal title='Add New Registrant' />

      {/* Gender */}
      <div className='mb-2 d-flex align-items-center'>
        <label className='form-label me-3 mb-1'>Gender</label>
        <div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='gender'
              id='genderBrother'
              value='1'
              checked={formData.gender === '1'}
              onChange={handleChange}
              required
            />
            <label className='form-check-label' htmlFor='genderBrother'>
              Brother
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              name='gender'
              id='genderSister'
              value='2'
              checked={formData.gender === '2'}
              onChange={handleChange}
              required
            />
            <label className='form-check-label' htmlFor='genderSister'>
              Sister
            </label>
          </div>
        </div>
      </div>

      {/* Other inputs */}
      <div className='row mb-2'>
        <div className='col-md-6'>
          <label className='form-label'>Name</label>
          <input
            type='text'
            name='name'
            className='form-control'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className='col-md-6'>
          <label className='form-label'>Chinese Name</label>
          <input
            type='text'
            name='chiName'
            className='form-control'
            value={formData.chiName}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-md-6'>
          <label className='form-label'>Email</label>
          <input
            type='email'
            name='email'
            className='form-control'
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-6'>
          <label className='form-label'>Phone Number</label>
          <input
            type='text'
            name='phoneNo'
            className='form-control'
            value={formData.phoneNo}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-md-6'>
          <label className='form-label'>I.C./Passport</label>
          <input
            type='text'
            name='icNo'
            className='form-control'
            value={formData.icNo}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-6'>
          <label className='form-label'>Age</label>
          <input
            type='number'
            name='age'
            className='form-control'
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className='row mb-2'>
        {/* Locality using react-select */}
        <div className='col-md-6'>
          <label className='form-label'>Locality</label>
          <Select
            name='locality'
            value={formData.locality}
            onChange={(selectedOption) =>
              setFormData({ ...formData, locality: selectedOption })
            }
            options={localityOptions}
            onInputChange={(inputValue) => {
              setLocalitySearch(inputValue)
              return inputValue
            }}
            placeholder='Select Locality'
            components={{ MenuList }}
            loadMore={loadMore}
            hasMoreLocality={hasMoreLocality}
            loadingLocalities={loadingLocalities}
          />
        </div>
        <div className='col-md-6'>
          <label className='form-label'>Language</label>
          <select
            name='language'
            className='form-select'
            value={formData.language}
            onChange={handleChange}
            required
          >
            <option value=''>Select Language</option>
            <option value='1'>English</option>
            <option value='2'>中文</option>
            <option value='3'>BM</option>
          </select>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-md-6'>
          <label className='form-label'>Package</label>
          <select
            name='package'
            className='form-select'
            value={formData.package ? formData.package._id : ''}
            onChange={(e) => {
              const selectedPkg = eventPackages.find(
                (pkg) => pkg._id === e.target.value
              )
              setFormData({ ...formData, package: selectedPkg })
            }}
            required
          >
            <option value=''>Select Package</option>
            {eventPackages.map((pkg) => (
              <option key={pkg._id} value={pkg._id}>
                {pkg.packageNmEn}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='text-end'>
        <button
          type='submit'
          disabled={submitting}
          className='btn btn-primary btn-sm me-3'
        >
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
        <button
          type='button'
          className='btn btn-secondary btn-sm'
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default AdminAddUserRegister
