import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Modal } from 'antd'
import axios from 'axios'
import AdminNewAnnounce from './NewAnnounce'
import AdminEditAnnounce from './EditAnnounce'

const AdminAnnounceList = () => {
  const [showAddAnnounceModal, setShowAddAnnounceModal] = useState(false)
  const [showEditAnnounceModal, setShowEditAnnounceModal] = useState(false)
  const [editAnnouncement, setEditAnnouncement] = useState(null)
  const [announcements, setAnnouncements] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const renderFiles = (files, id) =>
    files && files.length > 0
      ? files.map((file, index) => (
          <div key={index}>
            <a
              href={`${process.env.REACT_APP_BACKEND_URL}/files/announcement/${id}/${file.fileName}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
            >
              {file.fileName}
            </a>
          </div>
        ))
      : 'No files'

  const handleOpenModal = () => setShowAddAnnounceModal(true)
  const handleCloseModal = () => setShowAddAnnounceModal(false)

  const handleOpenEditModal = (announcement) => {
    setEditAnnouncement(announcement)
    setShowEditAnnounceModal(true)
  }

  const handleCloseEditModal = () => {
    setEditAnnouncement(null)
    setShowEditAnnounceModal(false)
  }

  const handleAddAnnouncement = (newAnnouncement) => {
    setAnnouncements((prev) => [newAnnouncement, ...prev])
  }

  const handleUpdateAnnouncement = (updatedAnnouncement) => {
    setAnnouncements((prev) =>
      prev.map((announce) =>
        announce._id === updatedAnnouncement._id
          ? updatedAnnouncement
          : announce
      )
    )
  }

  const handleDelete = async (id) => {
    if (window.confirm('Confirm Delete?')) {
      try {
        const response = await axios.delete(`/api/v1/admin/announce/${id}`)
        if (response.data.success) {
          setAnnouncements((prev) =>
            prev.filter((announce) => announce._id !== id)
          )
        }
      } catch (error) {
        console.error('Error deleting announcement:', error)
        alert('Failed to delete. Please try again.')
      }
    }
  }

  const handleStatusChange = async (announce) => {
    if (window.confirm('Confirm change status?')) {
      try {
        const newStatus = announce.status === 1 ? 0 : 1
        const response = await axios.put(
          `/api/v1/admin/announce/status/${announce._id}`,
          {
            status: newStatus,
          }
        )

        if (response.data.success) {
          setAnnouncements((prev) =>
            prev.map((item) =>
              item._id === announce._id ? { ...item, status: newStatus } : item
            )
          )
        } else {
          alert('Failed to change status.')
        }
      } catch (error) {
        console.error('Error changing status:', error)
        alert('Failed to change status.')
      }
    }
  }

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get('/api/v1/admin/announce/list')
        setAnnouncements(response.data)
      } catch (error) {
        console.error('Error fetching announcements:', error)
        alert('Failed to load announcements. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchAnnouncements()
  }, [])

  return (
    <Layout title={'Announcement'}>
      <div className='p-3'>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-success btn-sm'
            onClick={handleOpenModal}
          >
            <i className='bi bi-plus-circle'></i> Announcement
          </button>
        </div>

        {isLoading ? (
          <div className='text-center'>Loading...</div>
        ) : (
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>Date</th>
                  <th scope='col'>Subject</th>
                  <th className='text-center'>Status</th>
                  <th className='text-end'></th>
                </tr>
              </thead>
              <tbody>
                {announcements.map((announce) => (
                  <tr key={announce._id}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {
                        new Date(announce.announceDate)
                          .toISOString()
                          .split('T')[0]
                      }
                    </td>
                    <td>
                      {announce.announceTitleEn}
                      <div>{announce.announceTitleZh}</div>
                      <div>{announce.announceTitleBm}</div>
                      <div>{renderFiles(announce.files, announce._id)}</div>
                    </td>
                    <td
                      className='text-center'
                      style={{
                        cursor: 'pointer',
                        fontWeight: '600',
                        color: announce.status === 1 ? 'green' : '#ba8b00',
                      }}
                      onClick={() => handleStatusChange(announce)}
                    >
                      {announce.status === 1 ? 'Published' : 'Pending'}
                    </td>
                    <td className='text-end' style={{ whiteSpace: 'nowrap' }}>
                      <i
                        className='bi bi-pencil-square'
                        style={{ color: '#3c8dbc', cursor: 'pointer' }}
                        onClick={() => handleOpenEditModal(announce)}
                      ></i>
                      <i
                        className='bi bi-trash3 ms-3'
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(announce._id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <Modal
          closable={false}
          onCancel={handleCloseModal}
          open={showAddAnnounceModal}
          width={'60%'}
          footer={null}
        >
          <AdminNewAnnounce
            closeLogModal={handleCloseModal}
            onAddAnnouncement={handleAddAnnouncement}
          />
        </Modal>

        <Modal
          closable={false}
          onCancel={handleCloseEditModal}
          open={showEditAnnounceModal}
          width={'60%'}
          footer={null}
        >
          {editAnnouncement && (
            <AdminEditAnnounce
              announcement={editAnnouncement}
              closeLogModal={handleCloseEditModal}
              onUpdateAnnouncement={handleUpdateAnnouncement}
            />
          )}
        </Modal>
      </div>
    </Layout>
  )
}

export default AdminAnnounceList
