import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ChurchNewTransport = ({ id, isModalOpen, onCancel, onAddSuccess }) => {
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [participants, setParticipants] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [arrivalDate, setArrivalDate] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [arrivalFlightNoBus, setArrivalFlightNoBus] = useState('')
  const [departureDate, setDepartureDate] = useState('')
  const [departureTime, setDepartureTime] = useState('')
  const [departureFlightNoBus, setDepartureFlightNoBus] = useState('')
  const [remark, setRemark] = useState('')

  useEffect(() => {
    if (isModalOpen) {
      fetchParticipants()
      resetForm()
    }
  }, [isModalOpen])

  const fetchParticipants = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/api/v1/church/transport/users/${id}`, {
        withCredentials: true,
      })
      setParticipants(
        response.data.map((participant) => ({
          value: participant._id,
          label: participant.name.toUpperCase(),
        }))
      )
    } catch (error) {
      console.error('Error fetching participants:', error)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    setSelectedParticipants([])
    setArrivalDate('')
    setArrivalTime('')
    setArrivalFlightNoBus('')
    setDepartureDate('')
    setDepartureTime('')
    setDepartureFlightNoBus('')
    setRemark('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedParticipants.length === 0) {
      toast.error('Please select participant.')
      return
    }
    setSubmitLoading(true)
    try {
      const response = await axios.post(`/api/v1/church/transport/add/${id}`, {
        participants: selectedParticipants.map((p) => ({
          id: p.value,
          name: p.label,
        })),
        arrivalDate,
        arrivalTime,
        arrivalFlightNoBus,
        departureDate,
        departureTime,
        departureFlightNoBus,
        remark,
      })

      if (response.data.success) {
        toast.success('Submit Successfully')
        resetForm()
        onAddSuccess(response.data.data)
        onCancel()
      } else {
        toast.error(response.data.message || 'Failed to add transport records')
      }
    } catch (error) {
      console.error('Error adding transport records:', error)
      toast.error(
        error.response?.data?.message || 'Failed to add transport records'
      )
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <div className='container'>
      <h5 className='text-center mb-3'>Add Transportation</h5>
      <form onSubmit={handleSubmit}>
        <div className='mb-3'>
          <label className='form-label'>
            Key in Name and select the participant from the drop-down list (Can
            select multiple participants with same arrival and departure)
          </label>
          <Select
            isMulti
            isSearchable
            options={participants}
            value={selectedParticipants}
            onChange={setSelectedParticipants}
            isLoading={loading}
          />
        </div>

        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Arrival</h6>
          <div className='d-flex justify-content-evenly'>
            <div className='form-group'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                className='form-control'
                value={arrivalDate}
                onChange={(e) => setArrivalDate(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                className='form-control'
                value={arrivalTime}
                onChange={(e) => setArrivalTime(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Flight No./Bus</label>
              <input
                type='text'
                className='form-control'
                value={arrivalFlightNoBus}
                onChange={(e) => setArrivalFlightNoBus(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Departure Section */}
        <div className='mb-4'>
          <h6 style={{ color: '#3c57a8' }}>Departure</h6>
          <div className='d-flex justify-content-evenly'>
            <div className='form-group'>
              <label className='form-label'>Date</label>
              <input
                type='date'
                className='form-control'
                value={departureDate}
                onChange={(e) => setDepartureDate(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Time</label>
              <input
                type='time'
                className='form-control'
                value={departureTime}
                onChange={(e) => setDepartureTime(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label className='form-label'>Flight No./Bus</label>
              <input
                type='text'
                className='form-control'
                value={departureFlightNoBus}
                onChange={(e) => setDepartureFlightNoBus(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='mb-3'>
          <label className='form-label'>Remark</label>
          <input
            className='form-control'
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          ></input>
        </div>

        <div className='text-end'>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
            disabled={submitLoading}
          >
            {submitLoading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary ms-4 btn-sm'
            onClick={() => onCancel()}
            disabled={submitLoading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChurchNewTransport
