import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SpecificRegistrationForm = ({ onClose, eventId, refreshDashboard }) => {
  const [packages, setPackages] = useState([])
  const [icField, setIcField] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    registrationOption: '',
    language: '',
    age: '',
    icPassport: '',
    remark: '',
  })

  useEffect(() => {
    if (eventId) {
      axios
        .get(`/api/v1/specific/events/${eventId}/packages`)
        .then((res) => {
          setPackages(res.data.packages)
          setIcField(res.data.icField)
        })
        .catch((error) => {
          console.error('Error fetching packages:', error)
        })
    }
  }, [eventId])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await axios.post(
        '/api/v1/specific/register',
        {
          eventId,
          registrationOption: formData.registrationOption,
          language: formData.language,
          age: formData.age,
          icPassport: formData.icPassport,
          remark: formData.remark,
        },
        { withCredentials: true }
      )

      toast.success('Registration Successful!')
      onClose()
      if (refreshDashboard) refreshDashboard()
    } catch (error) {
      console.error(
        'Error submitting registration:',
        error.response?.data?.message || error.message
      )
      toast.error(
        error.response?.data?.message ||
          'Registration failed. Please try again.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <h5 className='text-center mb-3'>Registration Form</h5>
      <form onSubmit={handleSubmit} className='d-flex flex-column gap-3'>
        <div>
          <label className='fw-bold mb-1'>
            Registration Option <span className='text-danger'>*</span>
          </label>
          <select
            name='registrationOption'
            value={formData.registrationOption}
            onChange={handleChange}
            required
            className='form-select'
          >
            <option value=''>Choose</option>
            {packages.length > 0 ? (
              packages.map((pkg) => (
                <option key={pkg._id} value={pkg._id}>
                  {pkg.packageNmEn} - RM{pkg.fee.toFixed(2)}
                </option>
              ))
            ) : (
              <option value='' disabled>
                No options available
              </option>
            )}
          </select>
        </div>

        <div>
          <label className='fw-bold mb-1'>
            Language <span className='text-danger'>*</span>
          </label>
          <select
            name='language'
            value={formData.language}
            onChange={handleChange}
            required
            className='form-select'
          >
            <option value=''>Choose</option>
            <option value='1'>English</option>
            <option value='2'>中文</option>
            <option value='3'>BM</option>
          </select>
        </div>

        {!icField && (
          <div className='row'>
            <div className='col-md-8'>
              <label className='fw-bold mb-1'>
                I.C/Passport <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                name='icPassport'
                value={formData.icPassport}
                onChange={handleChange}
                required
                className='form-control'
              />
            </div>

            <div className='col-md-4'>
              <label className='fw-bold mb-1'>
                Age <span className='text-danger'>*</span>
              </label>
              <input
                type='number'
                name='age'
                value={formData.age}
                onChange={handleChange}
                required
                className='form-control'
              />
            </div>
          </div>
        )}

        <div>
          <label className='fw-bold mb-1'>Remark</label>
          <input
            type='text'
            name='remark'
            value={formData.remark}
            onChange={handleChange}
            className='form-control'
          />
        </div>

        <div className='d-flex justify-content-end mt-3'>
          <button
            type='submit'
            className='btn btn-primary btn-sm me-3'
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            type='button'
            onClick={onClose}
            className='btn btn-secondary btn-sm'
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default SpecificRegistrationForm
