import React, { useState } from 'react'
import { Input } from 'antd'
import axios from 'axios'
import { toast } from 'react-toastify'
import TitleModal from '../../components/shared/TitleModal'

const ContactUs = ({ onCancel }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validateForm = () => {
    let newErrors = {}

    if (!email) {
      newErrors.email = 'Email is required'
    } else if (!isValidEmail(email)) {
      newErrors.email = 'Invalid email format'
    }

    if (!name) newErrors.name = 'Name is required'
    if (!message) newErrors.message = 'Message is required'

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async () => {
    if (!validateForm()) return
    setIsLoading(true)
    try {
      const response = await axios.post('/api/v1/general/support/inquiry', {
        email,
        name,
        message,
      })

      toast.success(response.data.message, { autoClose: 3000 })
      setEmail('')
      setName('')
      setMessage('')
      setErrors({})

      onCancel()
    } catch (error) {
      toast.error(error.response?.data?.error || 'Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <TitleModal title='Contact Us' />
      <div>
        <h6>For any inquiries, please contact us at:</h6>
        <div className='mb-1' style={{ fontSize: '16px' }}>
          Email:{' '}
          <a
            href='mailto:registration@localchurch.my'
            style={{ textDecoration: 'none' }}
          >
            registration@localchurch.my
          </a>
        </div>
        <div style={{ fontSize: '16px' }}>
          Phone: +03-8060 8406 / +6016-896 5635 / +6016-892 2456
        </div>
      </div>

      <hr className='mt-3' />

      <h6 className='mt-3'>Or, you can leave us a message</h6>

      <div className='mb-3'>
        <label>Email:</label>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
      </div>

      <div className='mb-3'>
        <label>Name:</label>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
      </div>

      <div className='mb-3'>
        <label>Message:</label>
        <Input.TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={5}
        />
        {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
      </div>

      <div className='d-flex justify-content-end gap-2 mt-3'>
        <button
          type='submit'
          className='me-3 btn btn-primary btn-sm'
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>

        <button className='close-btn' onClick={onCancel}>
          Close
        </button>
      </div>
    </div>
  )
}

export default ContactUs
