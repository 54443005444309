import Cookies from 'js-cookie';
import { sha256 } from 'js-sha256';
import axios from 'axios';

/**
 * Solves the Proof of Work challenge using the CSRF token as a nonce
 * @param {string} csrfToken - The CSRF token to use as nonce
 * @param {number} difficulty - Number of leading zeros required in hash
 * @returns {Promise<number>} - The counter value that satisfies the PoW
 */
export async function solveProofOfWork(csrfToken, difficulty = 4) {
  return new Promise((resolve) => {
    // Fallback for browsers without Web Worker support
    const prefix = '0'.repeat(difficulty);
    let counter = 0;
    
    // Use setTimeout to avoid blocking the main thread
      const findSolution = () => {
        const startTime = Date.now();
        
        // Only run for a short time before yielding back to the main thread
        while (Date.now() - startTime < 50) {
          const input = `${csrfToken}:${counter}`;
          const hash = sha256(input);
          
          if (hash.startsWith(prefix)) {
            resolve(counter);
            return;
          }
          counter++;
        }
        
        setTimeout(findSolution, 0);
      };
      
      findSolution();
  });
}

/**
 * Makes a secure API request with CSRF and PoW protection
 * @param {string} url - API endpoint
 * @param {Object} options - Axios request config
 * @returns {Promise<AxiosResponse>} - Axios response
 */
export async function secureApiRequest(url, options = {}) {
  // Get CSRF token from cookie
  let csrfToken = Cookies.get('XSRF-TOKEN');
  
  if (!csrfToken) {
    // If no CSRF token exists, make a simple GET request to the server
    // which will set the CSRF token cookie
    await axios.get('/api/v1/auth/token', { withCredentials: true });
    // Then get the newly set token
    csrfToken = Cookies.get('XSRF-TOKEN');
    if (!csrfToken) {
      throw new Error('Failed to get CSRF token');
    }
  }
  
  // Default options
  const defaultOptions = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'x-xsrf-token': csrfToken,
    },
  };
  
  // Merge options
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...(options.headers || {}),
    },
  };
  
  // For mutating requests, add Proof of Work
  const method = options.method || 'get';
  if (['post', 'put', 'delete', 'patch'].includes(method.toLowerCase())) {
    // Solve the Proof of Work challenge
    const counter = await solveProofOfWork(csrfToken);
    
    // Add the PoW counter to the headers
    mergedOptions.headers['X-PoW-Counter'] = counter;
  }

  return axios(url, mergedOptions)
}

/**
 * Wrapper for GET requests
 * @param {string} url - API endpoint
 * @param {Object} options - Additional axios options
 * @returns {Promise<any>} - Response data
 */
export async function secureGet(url, options = {}) {
  return secureApiRequest(url, {
    method: 'get',
    ...options,
  })
}

/**
 * Wrapper for POST requests
 * @param {string} url - API endpoint
 * @param {Object} data - Request body data
 * @param {Object} options - Additional axios options
 * @returns {Promise<any>} - Response data
 */
export async function securePost(url, data, options = {}) {
  return secureApiRequest(url, {
    method: 'post',
    data,
    ...options,
  })
}

/**
 * Wrapper for PUT requests
 * @param {string} url - API endpoint
 * @param {Object} data - Request body data
 * @param {Object} options - Additional axios options
 * @returns {Promise<any>} - Response data
 */
export async function securePut(url, data, options = {}) {
  return secureApiRequest(url, {
    method: 'put',
    data,
    ...options,
  })
}

/**
 * Wrapper for DELETE requests
 * @param {string} url - API endpoint
 * @param {Object} options - Additional axios options
 * @returns {Promise<any>} - Response data
 */
export async function secureDelete(url, options = {}) {
  return secureApiRequest(url, {
    method: 'delete',
    ...options,
  })
}
