import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ChurchCoordinator = ({ setHasCoordinator }) => {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState({})

  const validateForm = () => {
    let newErrors = {}
    if (!name.trim()) {
      newErrors.name = 'Name is required'
    }
    if (!email.trim() && !phone.trim()) {
      newErrors.contact = 'Email or Phone is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateForm()) return

    try {
      await axios.post(
        '/api/v1/church/dashboard/coordinator/register',
        { eventId: id, name, email, phone },
        { withCredentials: true }
      )
      setHasCoordinator(true)
    } catch (error) {
      console.error('Error registering coordinator:', error)
      toast.error('Failed to register. Please try again.')
    }
  }

  return (
    <>
      <h5 className='text-center mb-3'>Person-In-Charge Contact Info</h5>
      <div
        style={{
          maxWidth: '450px',
          margin: 'auto',
          padding: '20px',
          border: '1px solid #d3c4a7',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label className='form-label'>Name</label>
            <input
              type='text'
              className={`form-control ${errors.name ? 'border-danger' : ''}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <p className='text-danger'>{errors.name}</p>}
          </div>

          <div className='mb-3'>
            <label className='form-label'>Email</label>
            <input
              type='email'
              className='form-control'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>Phone Number</label>
            <input
              type='text'
              className='form-control'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          {errors.contact && <p className='text-danger'>{errors.contact}</p>}

          <button type='submit' className='btn btn-success w-100'>
            Submit
          </button>
        </form>
      </div>
    </>
  )
}

export default ChurchCoordinator
