// NavigationBar.js
import { Modal } from 'antd'
import React, { useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ContactUs from '../../pages/General/ContactUs'

const NavigationBar = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Navbar collapseOnSelect expand='lg' variant='dark' className='px-3 mt-2'>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='me-auto'>
          <h5 style={{ color: '#006ba1' }}>Event Registration System (ERS)</h5>
        </Nav>
        <Nav className='d-flex align-items-center gap-3'>
          <span
            className='fw-bold'
            style={{ cursor: 'pointer', color: '#006ba1' }}
            onClick={showModal}
          >
            <i className='bi bi-envelope-fill'></i> Contact Us
          </span>
          {/* <span
            className='fw-bold'
            style={{ cursor: 'pointer', color: '#0056b3' }}
          >
            ENG
          </span>
          <span
            className='fw-bold'
            style={{ cursor: 'pointer', color: '#0056b3' }}
          >
            中文
          </span>
          <span
            className='fw-bold'
            style={{ cursor: 'pointer', color: '#0056b3' }}
          >
            BM
          </span> */}
        </Nav>
      </Navbar.Collapse>
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <ContactUs onCancel={handleCancel} />
      </Modal>
    </Navbar>
  )
}

export default NavigationBar
