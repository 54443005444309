import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import Layout from '../../../components/Layout/Layout'
import LoadingSpinner from '../../../components/shared/LoadingSpinner'

const IntlIndividualTransportEditForm = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isSpouse = searchParams.get('type') === 'spouse'
  const [transportId, setTransportId] = useState('')
  const [participantName, setParticipantName] = useState('')
  const [spouseName, setSpouseName] = useState('')

  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const [arrivalDate, setArrivalDate] = useState('')
  const [arrivalTime, setArrivalTime] = useState('')
  const [arrivalInfo, setArrivalInfo] = useState('')
  const [departDate, setDepartDate] = useState('')
  const [departTime, setDepartTime] = useState('')
  const [departInfo, setDepartInfo] = useState('')
  const [remark, setRemark] = useState('')

  const personName = isSpouse ? spouseName : participantName

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/api/v1/intl/transport/individual/check', {
          withCredentials: true,
        })

        const data = isSpouse
          ? res.data.data?.spouse
          : res.data.data?.participant

        if (!data) {
          toast.error('No transport record found.')
          navigate('/itero/hotel-reservation/transportation')
          return
        }

        const extractTime = (t) => t?.split(' ')[0] || ''
        setTransportId(data._id || '')
        setArrivalDate(data.arrivalDate?.slice(0, 10) || '')
        setArrivalTime(extractTime(data.arrivalTime))
        setArrivalInfo(data.arrivalInfo || '')
        setDepartDate(data.departDate?.slice(0, 10) || '')
        setDepartTime(extractTime(data.departTime))
        setDepartInfo(data.departInfo || '')
        setRemark(data.remark || '')
        setParticipantName(res.data.participantName || '')
        setSpouseName(res.data.spouseName || '')
      } catch (err) {
        toast.error('Failed to load transport data.')
        console.error(err)
        navigate('/itero/hotel-reservation/transportation')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [navigate])

  const handleSubmit = async () => {
    setSubmitting(true)

    try {
      const payload = {
        arrivalDate,
        arrivalTime,
        arrivalFlightNoBus: arrivalInfo,
        departureDate: departDate,
        departureTime: departTime,
        departureFlightNoBus: departInfo,
        remark,
        transportId,
      }

      const res = await axios.put(
        '/api/v1/intl/transport/individual/update',
        payload,
        { withCredentials: true }
      )

      if (res.data.success) {
        toast.success('Transport info updated.')
        navigate('/itero/hotel-reservation/transportation')
      } else {
        toast.error(res.data.message || 'Update failed.')
      }
    } catch (err) {
      toast.error('Error submitting update.')
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-3'>
        <div
          className='fw-bold mb-3 text-uppercase text-center'
          style={{
            color: '#5d3104',
            fontSize: '18px',
            borderBottom: '1px solid #d3c4a7',
            paddingBottom: '10px',
          }}
        >
          2025 September International Training for Elders and Responsible Ones
        </div>

        <div className='d-flex justify-content-end gap-4 mb-2'>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/')}
          >
            Lodging & Meal
          </button>
          <button
            className='btn btn-success btn-sm'
            onClick={() => navigate('/itero/hotel-reservation/payment')}
          >
            Payment
          </button>
          <button
            className='btn btn-success btn-sm'
            disabled
            style={{ cursor: 'not-allowed' }}
          >
            Transportation
          </button>
        </div>

        <div
          className='fw-bold text-center mb-2'
          style={{
            fontSize: '17px',
            color: '#219119',
            textTransform: 'uppercase',
          }}
        >
          Edit Transportation
        </div>

        <div
          className='mb-2 fw-bold'
          style={{
            maxWidth: '70%',
            margin: '0 auto',
            color: isSpouse ? '#c22b5e' : '#2f4483',
          }}
        >
          {personName}
        </div>
        <div
          className='p-3 rounded bg-white shadow-sm border'
          style={{ maxWidth: '70%', margin: '0 auto' }}
        >
          <div
            className='fw-bold text-center mb-2'
            style={{
              fontSize: '18px',
              color: isSpouse ? '#c22b5e' : '#2f4483',
            }}
          ></div>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <h6 className='fw-bold text-success'>Arrival</h6>
              <div className='d-flex justify-content-evenly mb-3'>
                <input
                  type='date'
                  className='form-control me-2'
                  value={arrivalDate}
                  onChange={(e) => setArrivalDate(e.target.value)}
                />
                <input
                  type='time'
                  className='form-control me-2'
                  value={arrivalTime}
                  onChange={(e) => setArrivalTime(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control'
                  placeholder='Flight / Bus Info'
                  value={arrivalInfo}
                  onChange={(e) => setArrivalInfo(e.target.value)}
                />
              </div>

              <h6 className='fw-bold text-success'>Departure</h6>
              <div className='d-flex justify-content-evenly mb-3'>
                <input
                  type='date'
                  className='form-control me-2'
                  value={departDate}
                  onChange={(e) => setDepartDate(e.target.value)}
                />
                <input
                  type='time'
                  className='form-control me-2'
                  value={departTime}
                  onChange={(e) => setDepartTime(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control'
                  placeholder='Flight / Bus Info'
                  value={departInfo}
                  onChange={(e) => setDepartInfo(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Remark</label>
                <textarea
                  className='form-control'
                  rows='2'
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>

              <div className='text-end'>
                <button
                  className='btn btn-warning btn-sm me-4'
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <span className='spinner-border spinner-border-sm'></span>
                  ) : (
                    'Update'
                  )}
                </button>
                <button
                  className='btn btn-secondary btn-sm'
                  onClick={() =>
                    navigate('/itero/hotel-reservation/transportation')
                  }
                  disabled={submitting}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default IntlIndividualTransportEditForm
