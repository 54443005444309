import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import PaginationButtons from '../../../components/shared/PaginationButtons'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AdminTransportRecords = () => {
  const { id } = useParams()
  const [eventName, setEventName] = useState('')
  const [transports, setTransports] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchEventName = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/admin/registration/event/${id}`
        )
        setEventName(data.eventName)
      } catch (error) {
        console.error('Error fetching event name:', error)
      }
    }

    if (id) {
      fetchEventName()
      fetchTransportRecords(1)
    }
  }, [id])

  const fetchTransportRecords = async (page) => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `/api/v1/admin/registration/transport/${id}?page=${page}`
      )
      setTransports(data.transports)
      setCurrentPage(page)
      setTotalPages(data.totalPages)
    } catch (error) {
      console.error('Error fetching transport records:', error)
    }
    setLoading(false)
  }

  const handleDeleteTransport = async (transportId) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return

    try {
      await axios.delete(`/api/v1/admin/registration/transport/${transportId}`)
      setTransports(
        transports.filter((transport) => transport._id !== transportId)
      )
      toast.success('Deleted Successfully')
    } catch (error) {
      console.error('Error deleting transport record:', error)
      toast.error('Failed to delete record. Please try again.')
    }
  }

  const exportToExcel = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/registration/transport/export/${id}`,
        { responseType: 'blob' }
      )

      const formattedEventName = eventName
        ? eventName
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : 'Event'

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Transport_${formattedEventName}.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error exporting transport records:', error)
    }
  }

  return (
    <Layout title={`Event Transport Records - ${eventName || 'Loading...'}`}>
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <Link to={`/admin/registration/data/${id}`}>
            <button type='button' className='btn btn-secondary btn-sm'>
              <i className='bi bi-arrow-left-circle'></i> Return
            </button>
          </Link>

          <h5 className='fw-bold text-center flex-grow-1 m-0'>
            {eventName ? eventName : 'Loading event...'}
          </h5>

          <div className='d-flex'>
            <button
              type='button'
              className='btn btn-info btn-sm'
              onClick={exportToExcel}
            >
              <i className='bi bi-file-earmark-arrow-down'></i> Export
            </button>
          </div>
        </div>

        <hr />

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Arrival</th>
                <th>Departure</th>
                <th>Contact</th>
                <th>Locality</th>
                <th className='text-end'>Updated At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='7' className='text-center'>
                    Loading...
                  </td>
                </tr>
              ) : transports.length > 0 ? (
                transports.flatMap((transport) => [
                  <tr
                    key={transport._id}
                    style={transport.remark ? { borderColor: 'white' } : {}}
                  >
                    <td>
                      <span
                        className='fw-bold'
                        style={{
                          color: transport.gender === 1 ? '#2f4483' : '#78003c',
                        }}
                      >
                        {transport.name.toUpperCase()}
                      </span>
                      {transport.chiName && <div>{transport.chiName}</div>}
                    </td>
                    <td>
                      <i
                        className='bi bi-calendar3-fill me-2'
                        style={{ color: '#17a2b8' }}
                      ></i>
                      {transport.arrivalDate
                        ? new Date(transport.arrivalDate).toLocaleDateString(
                            'en-GB'
                          )
                        : '-'}{' '}
                      {transport.arrivalTime}
                      {transport.arrivalInfo && (
                        <div>
                          <i
                            className='bi bi-send-fill me-2'
                            style={{ color: '#17a2b8' }}
                          ></i>{' '}
                          {transport.arrivalInfo}
                        </div>
                      )}
                    </td>
                    <td>
                      <i
                        className='bi bi-calendar3-fill me-2'
                        style={{ color: '#17a2b8' }}
                      ></i>
                      {transport.departDate
                        ? new Date(transport.departDate).toLocaleDateString(
                            'en-GB'
                          )
                        : '-'}{' '}
                      {transport.departTime}
                      {transport.departInfo && (
                        <div>
                          <i
                            className='bi bi-send-fill me-2'
                            style={{ color: '#17a2b8' }}
                          ></i>{' '}
                          {transport.departInfo}
                        </div>
                      )}
                    </td>
                    <td>
                      {transport.phoneNo && (
                        <div>
                          <i
                            className='bi bi-telephone-fill me-2'
                            style={{ color: '#7a6844' }}
                          ></i>
                          {transport.phoneNo}
                        </div>
                      )}

                      {transport.email && (
                        <div>
                          <i
                            className='bi bi-envelope-fill me-2'
                            style={{ color: '#7a6844' }}
                          ></i>
                          {transport.email}
                        </div>
                      )}
                    </td>
                    <td>{transport.localityName}</td>
                    <td className='text-end'>
                      {transport.updatedAt ? (
                        <>
                          {new Date(transport.updatedAt).toLocaleDateString(
                            'en-GB'
                          )}
                          <div>
                            {new Date(transport.updatedAt).toLocaleTimeString(
                              'en-GB',
                              {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className='text-end'>
                      <i
                        className='bi bi-trash3 text-danger ms-3'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDeleteTransport(transport._id)}
                      ></i>
                    </td>
                  </tr>,
                  transport.remark && (
                    <tr key={`${transport._id}-remark`}>
                      <td colSpan='7'>
                        <i
                          className='bi bi-info-circle-fill me-2'
                          style={{ color: '#5bc0de' }}
                        ></i>
                        <span style={{ fontWeight: 600 }}>
                          {' '}
                          {transport.remark}
                        </span>
                      </td>
                    </tr>
                  ),
                ])
              ) : (
                <tr>
                  <td colSpan='7' className='text-center'>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={fetchTransportRecords}
        />
      </div>
    </Layout>
  )
}

export default AdminTransportRecords
