import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import { Modal } from 'antd'
import { useAuth } from '../../context/auth'
import { toast } from 'react-toastify'
import AnnouncementList from '../General/AnnoucementList'
import SpecificRegistrationForm from './../Specific/Registration'
import SpecificTransportForm from './../Specific/Transport'
import SpecificRegistrationInfo from '../Specific/RegistrationInfo'
import SpecificPayment from '../Specific/Payment'
import useIsMobile from '../../hooks/useIsMobile'
import MeetingGroupLanguage from './Online/GroupLanguage'
import UserDashboardDesktop from './UserDashboardDesktop'
import UserDashboardMobile from './UserDashboardMobile'
import MeetingCallIn from './Online/Call'
import OnlineMeetingInfo from './Online/Info'

const UserDashboard = () => {
  const [auth] = useAuth()
  const isMobile = useIsMobile()

  const [events, setEvents] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isGrpLangModalVisible, setIsGrpLangModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const [formModalVisible, setFormModalVisible] = useState(false)
  const [formType, setFormType] = useState(null)
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [paymentModalVisible, setPaymentModalVisible] = useState(false)

  const [isCallInModalVisible, setIsCallInModalVisible] = useState(false)
  const [isMeetingInfoModalVisible, setIsMeetingInfoModalVisible] =
    useState(false)
  const [loadingMeeting, setLoadingMeeting] = useState(null)

  const fetchEvents = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios.get('/api/v1/user/dashboard/event/list', {
        withCredentials: true,
      })
      setEvents(response.data)
    } catch (error) {
      toast.error('Failed to load events. Please try again.')
    } finally {
      setLoading(false)
    }
  }, [auth])

  useEffect(() => {
    fetchEvents()
  }, [fetchEvents])

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  const showFormModal = (type, eventId) => {
    setFormType(type)
    setSelectedEventId(eventId)
    setFormModalVisible(true)
  }

  const handleFormModalCancel = () => {
    setFormModalVisible(false)
    setFormType(null)
    setSelectedEventId(null)
  }

  const showPaymentModal = (eventId) => {
    setSelectedEventId(eventId)
    setPaymentModalVisible(true)
  }

  const handlePaymentModalCancel = () => {
    setPaymentModalVisible(false)
    setSelectedEventId(null)
  }

  // Zoom Meeting
  const handleJoin = (eventId) => {
    setSelectedEventId(eventId)
    setIsGrpLangModalVisible(true)
  }

  const handleCloseGrpModal = () => {
    setIsGrpLangModalVisible(false)
    setSelectedEventId(null)
  }

  const showCallInModal = (eventId) => {
    setSelectedEventId(eventId)
    setIsCallInModalVisible(true)
  }

  const handleCallInModalCancel = () => {
    setIsCallInModalVisible(false)
    setSelectedEventId(null)
  }

  const showMeetingInfoModal = (eventId) => {
    setSelectedEventId(eventId)
    setIsMeetingInfoModalVisible(true)
  }

  const handleMeetingInfoModalCancel = () => {
    setIsMeetingInfoModalVisible(false)
    setSelectedEventId(null)
  }

  const handleStartMeeting = async (eventId) => {
    setLoadingMeeting(eventId)
    try {
      const response = await axios.get(
        `/api/v1/user/dashboard/meeting/url/${eventId}`,
        { withCredentials: true }
      )

      if (response.data.meetingUrl) {
        window.open(response.data.meetingUrl, '_blank')
      } else {
        toast.error('Meeting URL not found.')
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Failed to start the meeting.')
      }
    } finally {
      setLoadingMeeting(null)
    }
  }

  return (
    <Layout title={'Event Registration System (ERS)'}>
      {isMobile ? (
        <UserDashboardMobile
          showModal={showModal}
          loading={loading}
          events={events}
          handleJoin={handleJoin}
          showFormModal={showFormModal}
          showPaymentModal={showPaymentModal}
          showCallInModal={showCallInModal}
          showMeetingInfoModal={showMeetingInfoModal}
          handleStartMeeting={handleStartMeeting}
          loadingMeeting={loadingMeeting}
        />
      ) : (
        <UserDashboardDesktop
          showModal={showModal}
          loading={loading}
          events={events}
          handleJoin={handleJoin}
          showFormModal={showFormModal}
          showPaymentModal={showPaymentModal}
          showCallInModal={showCallInModal}
          showMeetingInfoModal={showMeetingInfoModal}
          handleStartMeeting={handleStartMeeting}
          loadingMeeting={loadingMeeting}
        />
      )}

      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={isMobile ? '100%' : '70%'}
      >
        <AnnouncementList handleClose={handleCancel} />
      </Modal>

      <Modal
        open={formModalVisible}
        onCancel={handleFormModalCancel}
        footer={null}
        width={isMobile ? '100%' : '60%'}
        destroyOnClose
      >
        {formType === 'registration' ? (
          <SpecificRegistrationForm
            onClose={handleFormModalCancel}
            eventId={selectedEventId}
            refreshDashboard={fetchEvents}
          />
        ) : formType === 'transportation' ? (
          <SpecificTransportForm
            eventId={selectedEventId}
            onClose={handleFormModalCancel}
          />
        ) : formType === 'registrationInfo' ? (
          <SpecificRegistrationInfo
            onClose={handleFormModalCancel}
            eventId={selectedEventId}
            refreshDashboard={fetchEvents}
          />
        ) : null}
      </Modal>

      <Modal
        open={paymentModalVisible}
        onCancel={handlePaymentModalCancel}
        footer={null}
        width={isMobile ? '100%' : '50%'}
        destroyOnClose
      >
        <SpecificPayment
          eventId={selectedEventId}
          refreshDashboard={fetchEvents}
          onClose={handlePaymentModalCancel}
        />
      </Modal>

      <Modal
        open={isGrpLangModalVisible}
        onCancel={handleCloseGrpModal}
        footer={null}
      >
        <MeetingGroupLanguage
          onClose={handleCloseGrpModal}
          eventId={selectedEventId}
          refreshDashboard={fetchEvents}
        />
      </Modal>

      <Modal
        open={isCallInModalVisible}
        onCancel={handleCallInModalCancel}
        footer={null}
        destroyOnClose
      >
        <MeetingCallIn
          eventId={selectedEventId}
          onClose={handleCallInModalCancel}
        />
      </Modal>

      <Modal
        open={isMeetingInfoModalVisible}
        onCancel={handleMeetingInfoModalCancel}
        width={isMobile ? '100%' : '60%'}
        footer={null}
        destroyOnClose
      >
        <OnlineMeetingInfo
          eventId={selectedEventId}
          onClose={handleMeetingInfoModalCancel}
        />
      </Modal>
    </Layout>
  )
}

export default UserDashboard
