import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useAuth } from './auth'
import { toast } from 'react-toastify'

const EventContext = createContext()

export const EventProvider = ({ children }) => {
  const { id: eventId } = useParams()
  const [auth] = useAuth()
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState(null)
  const [hasCoordinator, setHasCoordinator] = useState(null)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `/api/v1/church/dashboard/event/details/${eventId}`,
          { withCredentials: true }
        )

        if (response.data.success) {
          setEvent(response.data.event)
          setHasCoordinator(response.data.hasCoordinator)
        } else {
          toast.error('Failed to fetch event details.')
        }
      } catch (error) {
        console.error('Error fetching event details:', error)
        toast.error('Error fetching event details.')
      } finally {
        setLoading(false)
      }
    }

    if (!event) {
      fetchEventDetails()
    }
  }, [eventId, auth.user.name, event])

  return (
    <EventContext.Provider
      value={{ event, hasCoordinator, setHasCoordinator, loading }}
    >
      {children}
    </EventContext.Provider>
  )
}

export const useEvent = () => useContext(EventContext)
