import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminNewBank = ({ show, onClose, onAddBank }) => {
  const [formData, setFormData] = useState({
    accName: '',
    accNo: '',
    bankName: '',
    status: 1,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await axios.post(
        '/api/v1/admin/payment/bank/add',
        formData
      )
      if (response.data?.newBank) {
        const newBank = response.data.newBank
        toast.success('Added Successfully!')
        onAddBank(newBank)

        // Reset the form
        setFormData({
          accName: '',
          accNo: '',
          bankName: '',
          status: 1,
        })

        onClose()
      } else {
        toast.error('Failed to add the bank.')
      }
    } catch (error) {
      console.error('Error adding bank:', error)
      toast.error('Failed to add the bank. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>Add New Bank</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='accName'>
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type='text'
              name='accName'
              value={formData.accName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId='accNo' className='mt-3'>
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type='number'
              name='accNo'
              value={formData.accNo}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId='bankName' className='mt-3'>
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type='text'
              name='bankName'
              value={formData.bankName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='swiftCode' className='mt-3'>
            <Form.Label>Swift Code</Form.Label>
            <Form.Control
              type='text'
              name='swiftCode'
              value={formData.swiftCode}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='branch' className='mt-3'>
            <Form.Label>Branch</Form.Label>
            <Form.Control
              type='text'
              name='branch'
              value={formData.branch}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <div className='mt-4 text-end'>
            <Button
              variant='primary'
              type='submit'
              disabled={isSubmitting}
              className='me-4 btn-sm'
            >
              {isSubmitting ? 'Saving...' : 'Submit'}
            </Button>
            <Button variant='secondary' onClick={onClose} className='btn-sm'>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AdminNewBank
