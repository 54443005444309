import React from 'react'

const LoadingSpinner = ({ text = 'Loading...' }) => (
  <div
    style={{
      textAlign: 'center',
      color: '#5d3104',
      fontSize: '16px',
      fontWeight: 'bold',
    }}
  >
    <span className='spinner-border spinner-border-sm me-2'></span>
    {text}
  </div>
)

export default LoadingSpinner
