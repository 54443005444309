import React from 'react'
import { useAuth } from '../../../context/auth'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import '../../../../src/styles/admin.css'
import axios from 'axios'

const AdminHeader = () => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await axios.post('/api/v1/auth/logout', {}, { withCredentials: true })
      setAuth({
        user: null,
        access: null,
        isRehydrated: true,
      })
      navigate('/login', { state: { loggedOut: true } })
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return (
    <Navbar collapseOnSelect expand='lg' variant='dark' className='px-2 py-1'>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='me-auto'>
          {auth?.user && (
            <Link
              className='navbar-brand'
              style={{ color: '#000', textDecoration: 'none' }}
              to={'/admin/dashboard'}
            >
              Event Registration System (ERS)
            </Link>
          )}
        </Nav>
        <Nav className='me-3'>
          <Link
            to='/'
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: '#337ab7',
            }}
          >
            User Page
          </Link>
        </Nav>
        <Nav className='me-3' style={{ fontWeight: 'bold' }}>
          {auth?.user?.name}
        </Nav>
        <Nav>
          {auth?.user ? (
            <Nav.Link onClick={handleLogout} style={{ color: '#000' }}>
              <i className='bi bi-box-arrow-right'></i>
            </Nav.Link>
          ) : (
            <Nav.Link href='/login'>Login</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default AdminHeader
