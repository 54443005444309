import React from 'react'
import { useAuth } from '../../../context/auth'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import axios from 'axios'
import useIsMobile from '../../../hooks/useIsMobile'
import '../../../../src/styles/custom.css'

const UserHeader = ({ changeLanguage, t }) => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate()
  const isMobile = useIsMobile() // Detects mobile or desktop

  const handleLogout = async () => {
    try {
      await axios.post('/api/v1/auth/logout', {}, { withCredentials: true })
      setAuth({
        user: null,
        access: null,
        isRehydrated: true,
      })
      navigate('/login', { state: { loggedOut: true } })
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return (
    <>
      {isMobile ? (
        <Navbar className='px-2 py-1 d-flex justify-content-end align-items-center'>
          <div className='d-flex align-items-center gap-3'>
            {/* <span
              onClick={() => changeLanguage('en')}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                color: '#337ab7',
              }}
            >
              ENG
            </span>
            <span
              onClick={() => changeLanguage('zh')}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                color: '#337ab7',
              }}
            >
              中文
            </span>
            <span
              onClick={() => changeLanguage('bm')}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                color: '#337ab7',
              }}
            >
              BM
            </span>
 */}
            <span style={{ fontWeight: 'bold' }}>{auth?.user?.name}</span>

            {auth?.user ? (
              <span
                onClick={handleLogout}
                style={{ cursor: 'pointer', color: '#337ab7' }}
              >
                <i
                  className='bi bi-box-arrow-right'
                  style={{ fontSize: '20px' }}
                ></i>
              </span>
            ) : (
              <Nav.Link href='/login' style={{ color: '#337ab7' }}>
                Login
              </Nav.Link>
            )}
          </div>
        </Navbar>
      ) : (
        <Navbar
          collapseOnSelect
          expand='lg'
          variant='dark'
          className='px-2 py-1'
        >
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              {auth?.user && (
                <Link
                  className='navbar-brand'
                  style={{ color: '#777', textDecoration: 'none' }}
                  to={'/'}
                >
                  Event Registration System (ERS)
                </Link>
              )}
            </Nav>
            <Nav className='me-3'>
              {auth?.access === 'admin' && (
                <Nav.Link
                  as={Link}
                  to='/admin/dashboard'
                  style={{ color: '#337ab7' }}
                >
                  Admin Page
                </Nav.Link>
              )}
            </Nav>

            <Nav className='me-3 d-flex align-items-center gap-3'>
              {/* <span
                onClick={() => changeLanguage('en')}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#337ab7',
                }}
              >
                ENG
              </span>
              <span
                onClick={() => changeLanguage('zh')}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#337ab7',
                }}
              >
                中文
              </span>
              <span
                onClick={() => changeLanguage('bm')}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#337ab7',
                }}
              >
                BM
              </span> */}

              <span style={{ fontWeight: 'bold' }}>{auth?.user?.name}</span>
            </Nav>
            <Nav>
              {auth?.user ? (
                <Nav.Link onClick={handleLogout} style={{ color: '#337ab7' }}>
                  <i className='bi bi-box-arrow-right'></i>
                </Nav.Link>
              ) : (
                <Nav.Link href='/login'>Login</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  )
}

export default UserHeader
