import React from 'react'
import TitleModal from '../../components/shared/TitleModal'
import { useAuth } from '../../context/auth'

const IteroUserGuide = ({ onCancel }) => {
  const [auth] = useAuth()
  const isIndividual = auth?.user.command === 'individual'

  return (
    <>
      <div>
        <TitleModal title='User Guidelines' />
      </div>
      <div style={{ textAlign: 'justify' }}>
        {isIndividual ? (
          <ol style={{ paddingLeft: '10px', fontSize: '17px' }}>
            <li>
              Please fill in all the required fields in the Lodging & Meal form.
            </li>
            <li>
              After submitting the form, click 'Payment' to proceed and complete
              your payment.
            </li>
            <li>
              Registration for room reservation will only be confirmed with full
              payment and is non-refundable.
            </li>
            <li>
              If you request a twin-sharing room, you will be provided with a
              pairing code. You must find your own roommate to pair with. Please
              share your pairing code with your roommate so they can use it to
              pair with you accordingly.
            </li>
          </ol>
        ) : (
          <ol style={{ paddingLeft: '10px', fontSize: '17px' }}>
            <li>
              Click on 'Lodging & Meal Form' and enter the number of
              participants you would like to register. Then, proceed to fill in
              all the required information.
            </li>
            <li>
              After filling in the participant details, please click 'Payment'
              to go to the payment page and select the participants that you
              want to make payment for.
            </li>
            <li>
              Registration for room reservation will only be confirmed with full
              payment and is non-refundable.
            </li>
            <li>
              Upon successful payment, and if you request for twin sharing room,
              you will be provided with a pairing code. You must find your own
              roommate to pair with. Please share your pairing code with your
              roommate so that he can use it to pair up with you accordingly.
            </li>
          </ol>
        )}
      </div>
      <div className='text-end'>
        <button className='close-btn' onClick={onCancel}>
          Close
        </button>
      </div>
    </>
  )
}

export default IteroUserGuide
