import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Modal, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import Layout from '../../components/Layout/Layout'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AnnouncementList from '../General/AnnoucementList'

const ChurchDashboard = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()

  const [events, setEvents] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          '/api/v1/church/dashboard/event/list',
          { withCredentials: true }
        )
        setEvents(response.data)
      } catch (error) {
        toast.error('Failed to load events. Please try again.')
      } finally {
        setLoading(false)
      }
    }

    fetchEvents()
  }, [auth])

  const showModal = () => setIsModalVisible(true)
  const handleCancel = () => setIsModalVisible(false)

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <div className='p-3'>
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={showModal}
            style={{
              color: '#631919',
              border: 'none',
              padding: '5px 10px',
              cursor: 'pointer',
            }}
          >
            <i className='bi bi-info-circle-fill'></i> Announcement
          </button>
        </div>
        <h5
          className='fw-bold mt-2 mb-3'
          style={{ color: '#5d3104', textAlign: 'center' }}
        >
          Conference & Training
        </h5>

        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Spin size='large' />
          </div>
        ) : (
          <div>
            {events.length > 0 ? (
              <div>
                {events.map((event) => (
                  <div
                    key={event._id}
                    style={{
                      border: '1px solid #d3c4a7',
                      marginBottom: '20px',
                      borderRadius: '5px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: '17px',
                        padding: '5px',
                        color: '#631919',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '5px 5px 0 0',
                        textTransform: 'capitalize',
                      }}
                    >
                      {event.nameEn.toLowerCase()}
                    </div>

                    <div style={{ margin: '10px' }}>
                      <div>
                        <i
                          className='bi bi-calendar3'
                          style={{ marginRight: '8px', color: '#7a6e3b' }}
                        ></i>
                        {(() => {
                          const startDt = new Date(event.startDt)
                          const endDt = new Date(event.endDt)
                          const locale = 'en-US'

                          const options = {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }

                          // Check if startDt and endDt are the same date
                          if (
                            startDt.getFullYear() === endDt.getFullYear() &&
                            startDt.getMonth() === endDt.getMonth() &&
                            startDt.getDate() === endDt.getDate()
                          ) {
                            return startDt.toLocaleDateString(locale, options)
                          }

                          // Check if same month and year, show condensed format
                          if (
                            startDt.getFullYear() === endDt.getFullYear() &&
                            startDt.getMonth() === endDt.getMonth()
                          ) {
                            return `${startDt.toLocaleDateString(locale, {
                              month: 'long',
                            })} ${startDt.getDate()}-${endDt.getDate()}, ${startDt.getFullYear()}`
                          }

                          // Default case: show full date range
                          return `${startDt.toLocaleDateString(
                            locale,
                            options
                          )} - ${endDt.toLocaleDateString(locale, options)}`
                        })()}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <i
                            className='bi bi-file-earmark-pdf me-2'
                            style={{ color: '#7a6e3b' }}
                          ></i>

                          {event.letter && (
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${event._id}/${event.letter}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                textDecoration: 'none',
                                color: '#005fc4',
                              }}
                              className='me-3'
                            >
                              Announcement
                            </a>
                          )}

                          {event.form && (
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${event._id}/${event.form}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                textDecoration: 'none',
                                color: '#005fc4',
                              }}
                            >
                              Form
                            </a>
                          )}
                        </div>
                        <button
                          type='button'
                          className='btn btn-success btn-sm'
                          onClick={() => navigate(`/event/${event._id}`)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                No Upcoming Conference/Training
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={'70%'}
      >
        <AnnouncementList handleClose={handleCancel} />
      </Modal>
    </Layout>
  )
}

export default ChurchDashboard
