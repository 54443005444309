import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout/Layout'

const PageNotFound = () => {
  return (
    <Layout>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ marginTop: '50px' }}
      >
        <div
          className='text-center p-4 shadow-lg rounded'
          style={{ maxWidth: '400px', backgroundColor: '#fff' }}
        >
          <h1 className='display-1 fw-bold text-danger'>404</h1>
          <h2 className='fw-semibold text-muted'>Oops! Page Not Found</h2>
          <p className='text-muted'>
            The page you are looking for does not exist or has been moved.
          </p>
          <Link to='/' className='btn btn-primary mt-3'>
            Go Back Home
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
