import React, { useEffect, useState } from 'react'

const ChurchPaymentRecords = ({ id, onCancel }) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const slipRecords = records.filter((record) => record.paySlip)
  const nonSlipRecords = records.filter((record) => !record.paySlip)

  useEffect(() => {
    fetchPaymentRecords()
  }, [page])

  const fetchPaymentRecords = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `/api/v1/church/payment/records/${id}?page=${page}&limit=20`,
        { withCredentials: true }
      )
      if (response.ok) {
        const data = await response.json()
        setRecords(data.payments)
        setTotalPages(data.totalPages)
      } else {
        console.error('Error fetching payment records')
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <h5 className='text-center'>Payment Records</h5>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          Loading payment records...
        </div>
      ) : records.length === 0 ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          No payment records found.
        </div>
      ) : (
        <>
          {/* First Table */}
          {slipRecords.length > 0 && (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Payment Slip</th>
                    <th className='text-end'>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {slipRecords.map((record) => (
                    <tr key={record._id}>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/${record.eventId}/${record.paySlipSystem}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: '#0d6efd' }}
                        >
                          {record.paySlip}
                        </a>
                      </td>
                      <td className='text-end'>
                        {(() => {
                          const date = new Date(record.createdAt)
                          const day = String(date.getDate()).padStart(2, '0')
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            '0'
                          )
                          const year = date.getFullYear()
                          const time = date
                            .toISOString()
                            .split('T')[1]
                            .split('.')[0]
                          return `${day}/${month}/${year} ${time}`
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* Second Table */}
          {nonSlipRecords.length > 0 && (
            <>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Reference No.</th>
                    <th>Transaction No.</th>
                    <th className='text-end'>Amount (RM)</th>
                    <th>Status</th>
                    <th className='text-end'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {nonSlipRecords.map((record) => (
                    <tr key={record._id}>
                      <td>{record.refNo || '-'}</td>
                      <td>{record.transactionId || '-'}</td>
                      <td className='text-end'>
                        {record.amount ? record.amount.toFixed(2) : '0.00'}
                      </td>
                      <td
                        style={{
                          color: record.status === '00' ? 'green' : 'orange',
                        }}
                      >
                        {record.status === '00' ? 'Successful' : 'Pending'}
                      </td>
                      <td className='text-end'>
                        {(() => {
                          const date = new Date(record.createdAt)
                          const day = String(date.getDate()).padStart(2, '0')
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            '0'
                          )
                          const year = date.getFullYear()
                          const time = date
                            .toISOString()
                            .split('T')[1]
                            .split('.')[0]
                          return `${day}/${month}/${year} ${time}`
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {/* Pagination */}
          {totalPages > 0 && (
            <div className='d-flex justify-content-center mt-3'>
              <button
                className='btn btn-outline-primary btn-sm me-2'
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`btn btn-sm mx-1 px-2 ${
                    page === index + 1 ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button
                className='btn btn-outline-primary btn-sm ms-2'
                disabled={page === totalPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </div>
          )}

          <div className='text-end mt-3'>
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={onCancel}
            >
              Close
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ChurchPaymentRecords
