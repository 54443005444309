import React, { useEffect, useState } from 'react'

const IntlPaymentRecords = ({ onCancel }) => {
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    fetchPaymentRecords()
  }, [page])

  const fetchPaymentRecords = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `/api/v1/intl/payment/records?page=${page}&limit=20`
      )
      if (response.ok) {
        const data = await response.json()
        setRecords(data.payments)
        setTotalPages(data.totalPages)
      } else {
        console.error('Error fetching payment records')
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  const toggleRow = (id) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    )
  }

  return (
    <div>
      <h5 className='text-center'>Payment Records</h5>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          Loading payment records...
        </div>
      ) : records.length === 0 ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: '200px' }}
        >
          No payment records found.
        </div>
      ) : (
        <>
          <table className='table'>
            <thead>
              <tr>
                <th>Transaction Date</th>
                <th>Transaction ID</th>
                <th>Payment Method</th>
                <th className='text-end'>Amount (USD)</th>
                <th>Status</th>
                <th className='text-center'>Paid For</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <React.Fragment key={record._id}>
                  <tr>
                    <td>
                      {
                        new Date(record.createdAt)
                          .toISOString()
                          .replace('T', ' ')
                          .split('.')[0]
                      }
                    </td>
                    <td>{record.transactionId}</td>
                    <td>
                      {{
                        1: 'Telegraphic Transfer',
                        2: 'Credit/Debit Card',
                        3: 'QR eWallet',
                      }[record.paymentMethod] || ''}
                      {(record.paymentMethod === 1 ||
                        record.paymentMethod === 3) &&
                        record.paySlipSystem && (
                          <a
                            href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/67cf0e922ea8904cab38ae7b/${record.paySlipSystem}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none', color: '#0d6efd' }}
                            className='ms-2'
                          >
                            Pay Slip
                          </a>
                        )}
                    </td>
                    <td className='text-end'>{record.amount.toFixed(2)}</td>
                    <td
                      style={{
                        color:
                          record.paymentStatus === '1' ? 'green' : '#d39e00',
                        fontWeight: 'bold',
                      }}
                    >
                      {record.paymentStatus === '1'
                        ? 'Confirmed'
                        : 'Processing'}
                    </td>
                    <td className='text-center'>
                      <i
                        className={`bi ${
                          expandedRows.includes(record._id)
                            ? 'bi-people-fill'
                            : 'bi-people-fill text-primary'
                        }`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleRow(record._id)}
                      ></i>
                    </td>
                  </tr>
                  {expandedRows.includes(record._id) && (
                    <tr>
                      <td colSpan='6'>
                        <div>
                          <strong>Participants:</strong>
                          <ul className='mb-0'>
                            {record.userPay?.map((p, idx) => (
                              <li key={idx}>
                                {p.name}
                                {p.spouseName && ` (Spouse: ${p.spouseName})`}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {totalPages > 0 && (
            <div className='d-flex justify-content-center mt-3'>
              <button
                className='btn btn-outline-primary btn-sm me-2'
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`btn btn-sm mx-1 px-2 ${
                    page === index + 1 ? 'btn-primary' : 'btn-outline-primary'
                  }`}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </button>
              ))}

              <button
                className='btn btn-outline-primary btn-sm ms-2'
                disabled={page === totalPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </div>
          )}

          <div className='text-end'>
            <button className='close-btn' onClick={onCancel}>
              Close
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default IntlPaymentRecords
