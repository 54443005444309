import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingSpinner from './../../components/shared/LoadingSpinner'

const MyAccount = () => {
  const [user, setUser] = useState(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState(null)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoadingUser(true)
        const { data } = await axios.get('/api/v1/user/personal/profile', {
          withCredentials: true,
        })
        setUser(data)
      } catch (err) {
        setError('Failed to load user data')
      } finally {
        setLoadingUser(false)
      }
    }
    fetchUserData()
  }, [])

  const handlePasswordUpdate = async (e) => {
    e.preventDefault()

    if (password.length < 6) {
      setMessage('Password must be at least 6 characters long!')
      return
    }
    if (password !== confirmPassword) {
      setMessage('Passwords do not match!')
      return
    }

    try {
      setLoadingSubmit(true)
      await axios.put(
        '/api/v1/user/personal/update-password',
        { password },
        { withCredentials: true }
      )

      toast.success('Password updated successfully!')
      setPassword('')
      setConfirmPassword('')
      setMessage(null)
    } catch (error) {
      setMessage('Failed to update password')
      toast.error('Failed to update password')
    } finally {
      setLoadingSubmit(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <Layout title={'Event Registration System (ERS)'}>
      <h5
        className='fw-bold my-3'
        style={{ color: '#5d3104', textAlign: 'center' }}
      >
        My Account
      </h5>

      <div className='container'>
        <div className='card p-3 shadow'>
          <div style={{ fontWeight: '600' }}>
            {loadingUser ? (
              <div className='text-center'>
                <LoadingSpinner />
              </div>
            ) : user ? (
              <>
                <div className='mb-1'>
                  <span className='me-2'>Name:</span>
                  <span className='me-2'>{user.name}</span>
                  {user.chiName}
                </div>
                <div className='mb-1'>
                  <span className='me-2'>Email/Phone: </span>

                  {user.email && (
                    <span className='me-3'>
                      <i
                        className='bi bi-envelope-fill me-2'
                        style={{ color: '#7a6844' }}
                      ></i>
                      {user.email}
                    </span>
                  )}

                  {user.phoneNo && (
                    <span className='me-3'>
                      <i
                        className='bi bi-telephone-fill me-2'
                        style={{ color: '#7a6844' }}
                      ></i>
                      {user.phoneNo}
                    </span>
                  )}
                </div>

                <div className='mb-1'>
                  <span className='me-2'>Locality:</span>
                  <span className='me-2'>{user.locality?.name}</span>
                </div>
              </>
            ) : (
              <p className='text-center text-muted'>
                Failed to load user data.
              </p>
            )}
          </div>

          <hr className='my-3' />

          {/* Update Password Section */}
          <h6 className='fw-bold mb-2' style={{ color: '#5d3104' }}>
            Update Password
          </h6>

          <form onSubmit={handlePasswordUpdate}>
            <div className='mb-2'>
              <label className='form-label'>
                New Password (Minimum 6 Characters){' '}
                <span className='text-danger'>*</span>
              </label>
              <div className='position-relative'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='form-control pe-5'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className='position-absolute top-50 end-0 translate-middle-y me-3'
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className={showPassword ? 'bi bi-unlock' : 'bi bi-lock'}
                  ></i>
                </span>
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>
                Confirm Password <span className='text-danger'>*</span>
              </label>
              <div className='position-relative'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className='form-control pe-5'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                  className='position-absolute top-50 end-0 translate-middle-y me-3'
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className={
                      showConfirmPassword ? 'bi bi-unlock' : 'bi bi-lock'
                    }
                  ></i>
                </span>
              </div>
            </div>

            {message && <div className='text-danger mb-3'>{message}</div>}

            <button
              type='submit'
              className='btn btn-primary btn-sm'
              disabled={loadingSubmit}
            >
              {loadingSubmit ? (
                <>
                  <span className='spinner-border spinner-border-sm me-2'></span>{' '}
                  Updating...
                </>
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default MyAccount
