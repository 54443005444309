import React from 'react'
import LoadingSpinner from '../../components/shared/LoadingSpinner'

const UserDashboardDesktop = ({
  showModal,
  t,
  loading,
  events,
  currentLanguage,
  handleJoin,
  showFormModal,
  showPaymentModal,
  showCallInModal,
  showMeetingInfoModal,
  handleStartMeeting,
  loadingMeeting,
}) => {
  return (
    <>
      <div className='p-3'>
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={showModal}
            style={{
              color: '#631919',
              border: 'none',
              padding: '5px 10px',
              cursor: 'pointer',
            }}
          >
            <i className='bi bi-info-circle-fill'></i> Announcement
          </button>
        </div>
        <h5
          className='fw-bold mt-2 mb-3'
          style={{ color: '#5d3104', textAlign: 'center' }}
        >
          Conference & Training
        </h5>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {events.length > 0 ? (
              <div>
                {events.map((event, index) => (
                  <div
                    key={index}
                    style={{
                      border: '1px solid #d3c4a7',
                      marginBottom: '20px',
                      borderRadius: '5px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: '17px',
                        padding: '8px',
                        color: '#631919',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '5px 5px 0 0',
                        textTransform: 'capitalize',
                      }}
                    >
                      {event.nameEn.toLowerCase()}
                    </div>

                    <div style={{ margin: '10px' }}>
                      <div>
                        <i
                          className='bi bi-calendar3'
                          style={{ marginRight: '8px', color: '#7a6e3b' }}
                        ></i>
                        {(() => {
                          const startDt = new Date(event.startDt)
                          const endDt = new Date(event.endDt)
                          const locale =
                            currentLanguage === 'zh'
                              ? 'zh-CN'
                              : currentLanguage === 'ms'
                              ? 'ms-MY'
                              : 'en-US'

                          const options = {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }

                          // Check if startDt and endDt are the same date
                          if (
                            startDt.getFullYear() === endDt.getFullYear() &&
                            startDt.getMonth() === endDt.getMonth() &&
                            startDt.getDate() === endDt.getDate()
                          ) {
                            return startDt.toLocaleDateString(locale, options)
                          }

                          // Check if same month and year, show condensed format
                          if (
                            startDt.getFullYear() === endDt.getFullYear() &&
                            startDt.getMonth() === endDt.getMonth()
                          ) {
                            return `${startDt.toLocaleDateString(locale, {
                              month: 'long',
                            })} ${startDt.getDate()}-${endDt.getDate()}, ${startDt.getFullYear()}`
                          }

                          // Default case: show full date range
                          return `${startDt.toLocaleDateString(
                            locale,
                            options
                          )} - ${endDt.toLocaleDateString(locale, options)}`
                        })()}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'end',
                        }}
                      >
                        {event.regType1 === 1 ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <i
                              className='bi bi-file-earmark-pdf me-2'
                              style={{ color: '#7a6e3b' }}
                            ></i>
                            {event.letter && (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${event._id}/${event.letter}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  textDecoration: 'none',
                                  color: '#005fc4',
                                }}
                                className='me-3'
                              >
                                Announcement
                              </a>
                            )}

                            {event.form && (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}/files/event/${event._id}/${event.form}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  textDecoration: 'none',
                                  color: '#005fc4',
                                }}
                              >
                                Form
                              </a>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {/* Time Display */}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <i
                                className='bi bi-clock me-2'
                                style={{ color: '#7a6e3b' }}
                              ></i>
                              {event.time}
                            </div>

                            {/* Outline Section */}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '5px',
                              }}
                            >
                              <i
                                className='bi bi-file-earmark-pdf me-2'
                                style={{ color: '#7a6e3b' }}
                              ></i>
                              {event.engOutline ? (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${event._id}/${event.engOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    textDecoration: 'none',
                                    color: '#005fc4',
                                  }}
                                  className='me-3'
                                >
                                  ENG
                                </a>
                              ) : (
                                <span
                                  className='me-3'
                                  style={{ color: '#7a6e3b' }}
                                >
                                  ENG
                                </span>
                              )}

                              {/* Chinese Outline */}
                              {event.chiOutline ? (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${event._id}/${event.chiOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    textDecoration: 'none',
                                    color: '#005fc4',
                                  }}
                                  className='me-3'
                                >
                                  中文
                                </a>
                              ) : (
                                <span
                                  className='me-3'
                                  style={{ color: '#7a6e3b' }}
                                >
                                  中文
                                </span>
                              )}

                              {/* BM Outline */}
                              {event.bmOutline ? (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/files/outline/${event._id}/${event.bmOutline}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    textDecoration: 'none',
                                    color: '#005fc4',
                                  }}
                                >
                                  BM
                                </a>
                              ) : (
                                <span style={{ color: '#7a6e3b' }}>BM</span>
                              )}
                            </div>
                            {/* Meeting Info Section */}
                            {event.isMeetingRegistered && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '5px',
                                }}
                              >
                                <i
                                  className='bi bi-box-arrow-up-right me-2'
                                  style={{ color: '#17a2b8' }}
                                ></i>
                                <span
                                  style={{
                                    color: '#0f6674',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    showMeetingInfoModal(event._id)
                                  }
                                >
                                  View Meeting Info
                                </span>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Status Display (Only for event.regType2 === 2) */}
                        {event.regType2 === 2 && (
                          <div>
                            {event.registrationStatus !== null ? (
                              <span
                                style={{
                                  color:
                                    event.registrationStatus === 2
                                      ? '#dc3545'
                                      : 'green',
                                }}
                              >
                                {event.registrationStatus === 2 ? (
                                  <>
                                    <i className='bi bi-info-circle-fill me-1'></i>
                                    Awaiting Payment
                                  </>
                                ) : (
                                  <>
                                    <i className='bi bi-check-lg me-1'></i>
                                    Registered & Paid
                                  </>
                                )}
                              </span>
                            ) : (
                              <span style={{ color: '#695f33' }}>
                                <i className='bi bi-info-circle-fill me-1'></i>
                                Register through the church
                              </span>
                            )}
                          </div>
                        )}

                        {/* Status Display (Only for event.regType2 === 1) */}
                        {event.regType2 === 1 && (
                          <div>
                            {event.isMeetingRegistered ? (
                              <>
                                <button
                                  className='btn btn-info btn-sm me-3'
                                  onClick={() => showCallInModal(event._id)}
                                >
                                  Call In
                                </button>
                                <button
                                  className='btn btn-success btn-sm'
                                  onClick={() => handleStartMeeting(event._id)}
                                  disabled={loadingMeeting === event._id}
                                >
                                  {loadingMeeting === event._id
                                    ? 'Starting...'
                                    : 'Start'}
                                </button>
                              </>
                            ) : (
                              <span
                                className='text-primary'
                                onClick={() => handleJoin(event._id)}
                                style={{
                                  cursor: 'pointer',
                                  fontWeight: '600',
                                }}
                              >
                                <i className='bi bi-box-arrow-up-right me-2'></i>
                                Click to Join
                              </span>
                            )}
                          </div>
                        )}

                        {/* Registration & Transportation Forms */}
                        {event.regType2 === 3 && (
                          <div>
                            {/* Registration Form / Registration Info */}
                            <span
                              className='me-3'
                              onClick={() =>
                                event.registrationStatus === null
                                  ? showFormModal('registration', event._id)
                                  : showFormModal('registrationInfo', event._id)
                              }
                              style={{
                                pointerEvents: 'auto',
                                cursor: 'pointer',
                                color: '#1c7530',
                              }}
                            >
                              <i className='bi bi-box-arrow-up-right me-1'></i>
                              {event.registrationStatus === null
                                ? 'Registration Form'
                                : 'Registration Info'}
                            </span>

                            {/* Transportation Form & Payment Info */}
                            <span
                              onClick={() =>
                                event.registrationStatus !== null &&
                                showFormModal('transportation', event._id)
                              }
                              style={{
                                pointerEvents:
                                  event.registrationStatus !== null
                                    ? 'auto'
                                    : 'none',
                                color:
                                  event.registrationStatus !== null
                                    ? '#1c7530'
                                    : 'grey',
                                cursor:
                                  event.registrationStatus !== null
                                    ? 'pointer'
                                    : 'not-allowed',
                              }}
                            >
                              <i className='bi bi-box-arrow-up-right me-1'></i>
                              Transportation Form
                            </span>

                            {/* Payment Info (Only if registrationStatus is not null) */}
                            {event.registrationStatus !== null && (
                              <span
                                className='ms-3'
                                style={{
                                  color:
                                    event.registrationStatus === 2
                                      ? '#dc3545'
                                      : 'green',
                                  cursor:
                                    event.registrationStatus === 2
                                      ? 'pointer'
                                      : 'default',
                                }}
                                onClick={
                                  event.registrationStatus === 2
                                    ? () => showPaymentModal(event._id)
                                    : undefined
                                }
                              >
                                {event.registrationStatus === 2 ? (
                                  <>
                                    <i className='bi bi-box-arrow-up-right me-1'></i>
                                    Awaiting Payment
                                  </>
                                ) : (
                                  <>
                                    <i className='bi bi-file-earmark-pdf'></i>
                                    {event.paySlipSystem && (
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}/files/eventpay/${event._id}/${event.paySlipSystem}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='ms-1'
                                        style={{
                                          color: '#005fc4',
                                          textDecoration: 'none',
                                        }}
                                      >
                                        Registered & Paid
                                      </a>
                                    )}
                                  </>
                                )}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                No Upcoming Conference/Training
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default UserDashboardDesktop
