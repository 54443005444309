import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/auth'
// import * as Sentry from "@sentry/browser";
// import { version } from "../package.json";

// Sentry.init({ dsn: "https://10396d75d6aa449384f5d4374ce4b561@sentry.stnl.my/3",
//   release: version,
//   environment: process.env.NODE_ENV || 'development',
//   enabled: process.env.NODE_ENV !== 'development',
// });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
